import React, { memo } from "react";
import { message as Message } from "antd";
import { UnpaidText, PayNowText, ChangeCreditCardText } from "./text";
import * as S from "./styles.notification";

type TSuccessNotification = {
  message: string | React.ReactNode;
  duration?: number;
  key?: string;
  style?: React.CSSProperties;
};

type NotificationType = "info" | "success" | "error" | "warn";

function openNotification(
  type: NotificationType = "info",
  message: string | React.ReactNode = "",
  duration = 3,
  key?: string,
  style?: React.CSSProperties
) {
  let icon = <S.IconInfo />;
  if (type === "success") icon = <S.IconSuccess />;
  if (type === "error") icon = <S.IconError />;
  if (type === "warn") icon = <S.IconWarn />;
  Message.open({
    className: `portal-notification ${type} commonMessage`,
    content:
      typeof message === "string" ? (
        <MessageItem message={message} icon={icon} />
      ) : (
        message
      ),
    style,
    key: key,
    duration: duration,
    type: "info",
    icon: <div />,
  });
}

const Notification = {
  open: Message.open,
  success: ({ message, duration }: TSuccessNotification) =>
    openNotification("success", message, duration),
  error: ({ message, duration, key, style }: TSuccessNotification) =>
    openNotification("error", message, duration, key, style),
  warn: ({ message, duration }: TSuccessNotification) =>
    openNotification("warn", message, duration),
  info: ({ message, duration }: TSuccessNotification) =>
    openNotification("info", message, duration),
  destory: Message.destroy,
};

export default Notification;

interface MessageItemProps {
  message: string;
  icon?: React.ReactNode;
}

const MessageItem = memo(({ message, icon }: MessageItemProps) => (
  <S.Wrapper>
    {icon}
    <S.Message>{message}</S.Message>
  </S.Wrapper>
));

interface IPayFailedNotification {
  goToPayMent: () => void;
  payNow: () => void;
}

export const PayFailedNotification = ({
  goToPayMent,
  payNow,
}: IPayFailedNotification) => {
  return (
    <S.UnpaidWrapper>
      <S.IconError />
      <S.UnpaidContent>
        {UnpaidText}
        <S.ButtonWrapper>
          <span onClick={payNow}>{PayNowText}</span>
          <span onClick={goToPayMent}>{ChangeCreditCardText}</span>
        </S.ButtonWrapper>
      </S.UnpaidContent>
    </S.UnpaidWrapper>
  );
};
