import { tableText } from "langs/formatText";
import React from "react";

import { IRangeCalendarProps, RangeCalendar } from "./DateCalendar";

function formatDate(value: moment.Moment | undefined) {
  return value ? value.format("MMM D, YYYY") : "";
}

export default function RangePanel(props: IRangeCalendarProps) {
  return (
    <>
      {!props.isSince && (
        <div className={"rp-from-to-wrapper"}>
          <div className={"rp-from-to-container"}>
            <div className={"rp-date-label"}>{tableText.FromText}</div>
            <div className={"rp-date-value"}>
              {formatDate(props.value?.[0])}
            </div>
          </div>
          <div className={"rp-from-to-container"}>
            <div className={"rp-date-label"}>{tableText.ToText}</div>
            <div className={"rp-date-value"}>
              {formatDate(props.value?.[1])}
            </div>
          </div>
        </div>
      )}
      <RangeCalendar {...props} />
    </>
  );
}
