import intl from "langs/intl";

export const UsageSettingText = intl.formatMessage({
  defaultMessage: "Usage Setting",
});
export const LocationText = intl.formatMessage({ defaultMessage: "Location" });
export const FilterText = intl.formatMessage({ defaultMessage: "Filter" });
export const CapacityText = intl.formatMessage({ defaultMessage: "Capacity" });
export const UsageText = intl.formatMessage({ defaultMessage: "Usage" });
export const CostText = intl.formatMessage({ defaultMessage: "Cost" });
export const UserText = intl.formatMessage({ defaultMessage: "User" });
export const EmailText = intl.formatMessage({ defaultMessage: "E-mail" });
export const StatusText = intl.formatMessage({ defaultMessage: "Status" });
export const CreateTimeText = intl.formatMessage({
  defaultMessage: "Create Time",
});
export const PaymentTimeText = intl.formatMessage({
  defaultMessage: "Payment Time",
});
export const RegisterTimeText = intl.formatMessage({
  defaultMessage: "Register Time",
});
export const LastEditTimeText = intl.formatMessage({
  defaultMessage: "Last Edit Time",
});
export const SpaceNameText = intl.formatMessage({
  defaultMessage: "Space Name",
});
export const InventoryTypeText = intl.formatMessage({
  defaultMessage: "Inventory Type",
});
export const InventoryNameText = intl.formatMessage({
  defaultMessage: "Inventory Name",
});
export const ScannerIDText = intl.formatMessage({
  defaultMessage: "Scanner ID",
});
export const TotalCostText = intl.formatMessage({
  defaultMessage: "Total Cost",
});
export const TotalStayerText = intl.formatMessage({
  defaultMessage: "Total Stayer",
});
export const OrderIDText = intl.formatMessage({
  defaultMessage: "Order ID",
});
export const OrderSourceText = intl.formatMessage({
  defaultMessage: "Order Source",
});
export const PaymentStatusText = intl.formatMessage({
  defaultMessage: "Payment Status",
});
export const PaymentAmountText = intl.formatMessage({
  defaultMessage: "Payment Amount",
});
export const StartDateTimeText = intl.formatMessage({
  defaultMessage: "Start Date&Time",
});
export const EndDateTimeText = intl.formatMessage({
  defaultMessage: "End Date&Time",
});
export const OneTimeStayerText = intl.formatMessage({
  defaultMessage: "One Time Stayer",
});
export const RepeatStayerText = intl.formatMessage({
  defaultMessage: "Repeat Stayer",
});
export const TotalUsageText = intl.formatMessage({
  defaultMessage: "Total Usage",
});
export const AvgUsageStayText = intl.formatMessage({
  defaultMessage: "Avg. Usage/Stay",
});
export const AvgUsageText = intl.formatMessage({
  defaultMessage: "Avg. Usage",
});
export const SeatTimeSavedText = intl.formatMessage({
  defaultMessage: "Seat-time Saved (person-day)",
});
export const PhoneNumberText = intl.formatMessage({
  defaultMessage: "Phone Number",
});
export const NameText = intl.formatMessage({
  defaultMessage: "Name",
});
export const UserNameText = intl.formatMessage({
  defaultMessage: "User Name",
});
export const FullNameText = intl.formatMessage({
  defaultMessage: "Full Name",
});
export const UserEmailText = intl.formatMessage({
  defaultMessage: "User Email",
});
export const WorkEmailText = intl.formatMessage({
  defaultMessage: "Work Email",
});
export const BusinessUnitText = intl.formatMessage({
  defaultMessage: "Business Unit",
});
export const EmployeeIDText = intl.formatMessage({
  defaultMessage: "Employee ID",
});
export const CheckoutMethodText = intl.formatMessage({
  defaultMessage: "Checkout Method",
});
export const CurrencyText = intl.formatMessage({
  defaultMessage: "Currency",
});
export const RequestedTimeText = intl.formatMessage({
  defaultMessage: "Requested Time",
});
export const ActiveUsersText = intl.formatMessage({
  defaultMessage: "Active Users",
});
export const AllText = intl.formatMessage({
  defaultMessage: "All",
});
export const PendingUsersText = intl.formatMessage({
  defaultMessage: "Pending Users",
});
export const CheckInText = intl.formatMessage({
  defaultMessage: "Check In",
  description: "beginTime",
});
export const CheckOutText = intl.formatMessage({
  defaultMessage: "Check Out",
  description: "endTime",
});
export const hrText = intl.formatMessage({
  defaultMessage: "hr",
  description: "hour",
});
export const minText = intl.formatMessage({
  defaultMessage: "min",
  description: "minute",
});
export const EntityText = intl.formatMessage({ defaultMessage: "Entity" });
export const SummaryText = intl.formatMessage({ defaultMessage: "Summary" });
export const ActiveText = intl.formatMessage({ defaultMessage: "Active" });
export const InactiveText = intl.formatMessage({ defaultMessage: "Inactive" });
export const PendingText = intl.formatMessage({ defaultMessage: "Pending" });
export const VerifiedText = intl.formatMessage({ defaultMessage: "Verified" });
export const PendingConfirmationText = intl.formatMessage({
  defaultMessage: "Pending Confirmation",
});
export const ImportText = intl.formatMessage({
  defaultMessage: "Import",
  description: "upload title: import",
});
export const ResendText = intl.formatMessage({ defaultMessage: "Resend" });
export const UnverifiedText = intl.formatMessage({
  defaultMessage: "Unverified",
});
export const OverallMatricsText = intl.formatMessage({
  defaultMessage: "The overall matrics of this space type.",
});
export const AllSpacesText = intl.formatMessage({
  defaultMessage: "All Spaces",
});
export const SelectCityText = intl.formatMessage({
  defaultMessage: "Select City",
});
export const NoteText = intl.formatMessage({
  defaultMessage: "Note",
});

export const FromText = intl.formatMessage({
  description: "eg: From May 3, 2021 To May 6, 2021",
  defaultMessage: "From",
});
export const ToText = intl.formatMessage({
  description: "eg: From May 3, 2021 To May 6, 2021",
  defaultMessage: "To",
});
export const PageSizeText = (count: number) =>
  intl.formatMessage(
    {
      defaultMessage: "{count} / page",
    },
    { count }
  );
export const PageTotalText = (count: number) =>
  intl.formatMessage(
    {
      description: "eg: Total 1 item / Total 50 items",
      defaultMessage:
        "Total {count} {count, plural, =0 {item} =1 {item} other {items}}",
    },
    { count }
  );

export const LegalEntity = intl.formatMessage({
  defaultMessage: "Legal Entity",
});

export const ActiveEmployees = intl.formatMessage({
  defaultMessage: "Active Employees",
});

export const LastModified = intl.formatMessage({
  defaultMessage: "Last Modified On",
});
