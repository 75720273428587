import { lazy, ExoticComponent } from "react";
import Home from "pages/Home";
import { SiteNav, LeftSiderConfig } from "constants/navigators";
import { Authorities } from "store/accessToken";
import { testMobile } from "utils/os";

const Login = lazy(() => import("pages/Login"));
const NotAllowed403 = lazy(() => import("pages/403"));
const SpaceForm = lazy(() => import("pages/Spaces/SpaceForm"));
const InventoryForm = lazy(() => import("pages/Spaces/InventoryForm"));
const EmployeeForm = lazy(() => import("pages/EmployeeManagement/Form"));
const SelfServe = lazy(() => import("pages/SelfServe"));
const BusinessUnitsForm = lazy(() => import("pages/BusinessUnits/Form"));
const Password = lazy(() => import("pages/Password"));
const VerifyCode = lazy(() => import("pages/VerifyCode"));

type AppComponent = () => JSX.Element;

type AppRoute = {
  path: string;
  key: string;
  component: ExoticComponent | AppComponent;
  need?: Authorities;
  exact?: boolean;
};

export const PublicRoutes: AppRoute[] = [
  { path: "/login", key: "login", component: Login },
  {
    path: `/${SiteNav.SELF_SERVE}`,
    key: `/${SiteNav.SELF_SERVE}`,
    component: SelfServe,
  },
  {
    path: `/${SiteNav.NOT_ALLOWED}`,
    key: `/${SiteNav.NOT_ALLOWED}`,
    component: NotAllowed403,
  },
  {
    path: `/${SiteNav.PASSWORD}`,
    key: SiteNav.PASSWORD,
    component: Password,
  },
  {
    path: `/${SiteNav.VERIFYCODE}`,
    key: SiteNav.VERIFYCODE,
    component: VerifyCode,
  },
];

// both pc & mobile
export const PrivateRoutes: AppRoute[] = [];

export const PrivateMobileRoutes: AppRoute[] = [];

export const PrivatePCRoutes: AppRoute[] = [
  {
    path: `/${SiteNav.DASHBOARD}`,
    key: "dashboard",
    component: Home,
    need: Authorities.ReadDashboard,
  },
  {
    path: `/${SiteNav.SPACES}`,
    key: "private_space",
    component: Home,
    need: Authorities.ReadSpaces,
    exact: true,
  },
  {
    path: `/${SiteNav.USAGE}`,
    key: "usage",
    component: Home,
    need: Authorities.ReadUsage,
  },
  {
    path: `/${SiteNav.BOOKINGS_USAGE}`,
    key: SiteNav.BOOKINGS_USAGE,
    component: Home,
    need: Authorities.ReadBooking,
  },
  {
    path: `/${SiteNav.PAYMENT}/vouchers`,
    key: `/${SiteNav.PAYMENT}/vouchers`,
    component: Home,
    need: Authorities.ReadPayment,
  },
  {
    path: `/${SiteNav.PAYMENT}/view`,
    key: SiteNav.PAYMENT,
    component: Home,
    need: Authorities.ReadPayment,
  },
  {
    path: `/${SiteNav.PAYMENT}/add`,
    key: SiteNav.PAYMENT,
    component: Home,
    need: Authorities.ReadPayment,
  },
  {
    path: `/${SiteNav.PAYMENT}/(edit)?`,
    key: SiteNav.PAYMENT,
    component: Home,
    need: Authorities.ReadPayment,
  },
  {
    path: `/${SiteNav.INVOICE}`,
    key: SiteNav.INVOICE,
    component: Home,
    need: Authorities.ReadInvoice,
  },
  {
    path: `/${SiteNav.RECEIPT}`,
    key: SiteNav.RECEIPT,
    component: Home,
    need: Authorities.ReadReceipt,
  },
  // space form
  {
    path: `/${SiteNav.SPACES}/add`,
    key: `/${SiteNav.SPACES}/add`,
    component: SpaceForm,
    need: Authorities.AddSpace,
  },
  {
    path: `/${SiteNav.SPACES}/edit/:id?`,
    key: `/${SiteNav.SPACES}/edit/:id?`,
    component: SpaceForm,
    need: Authorities.EditSpace,
  },
  {
    path: `/${SiteNav.SPACES}/duplicate/:id?`,
    key: `/${SiteNav.SPACES}/duplicate/:id?`,
    component: SpaceForm,
    need: Authorities.ReadSpaces,
  },
  {
    path: `/${SiteNav.SPACES}/view/:id?`,
    key: `/${SiteNav.SPACES}/view/:id?`,
    component: SpaceForm,
    need: Authorities.AddSpace,
  },
  // inventory form
  {
    path: `/${SiteNav.INVENTORY}/add/:spaceID`,
    key: `/${SiteNav.INVENTORY}/add/:spaceID`,
    component: InventoryForm,
    need: Authorities.AddInventory,
  },
  {
    path: `/${SiteNav.INVENTORY}/edit/:id?`,
    key: `/${SiteNav.INVENTORY}/edit/:id?`,
    component: InventoryForm,
    need: Authorities.EditInventory,
  },
  {
    path: `/${SiteNav.INVENTORY}/duplicate/:id?`,
    key: `/${SiteNav.INVENTORY}/duplicate/:id?`,
    component: InventoryForm,
    need: Authorities.AddInventory,
  },
  {
    path: `/${SiteNav.INVENTORY}/view/:id?`,
    key: `/${SiteNav.INVENTORY}/view/:id?`,
    component: InventoryForm,
    need: Authorities.ReadSpaces,
  },
  // Employee Management
  {
    path: `/${SiteNav.EMPLOYEES}`,
    key: `/${SiteNav.EMPLOYEES}`,
    component: Home,
    need: Authorities.ReadEmployees,
    exact: true,
  },
  {
    path: `/${SiteNav.EMPLOYEES}/add`,
    key: `/${SiteNav.EMPLOYEES}/add`,
    component: EmployeeForm,
    need: Authorities.AddEmployee,
  },
  {
    path: `/${SiteNav.EMPLOYEES}/edit/:id?`,
    key: `/${SiteNav.EMPLOYEES}/edit/:id?`,
    component: EmployeeForm,
    need: Authorities.EditEmployee,
  },
  {
    path: `/${SiteNav.EMPLOYEES}/duplicate/:id?`,
    key: `/${SiteNav.EMPLOYEES}/duplicate/:id?`,
    component: EmployeeForm,
    need: Authorities.AddEmployee,
  },
  {
    path: `/${SiteNav.EMPLOYEES}/view/:id?`,
    key: `/${SiteNav.EMPLOYEES}/view/:id?`,
    component: EmployeeForm,
    need: Authorities.ReadEmployees,
  },
  // Company Profile
  {
    path: `/${SiteNav.COMPANYPROFILE}`,
    key: `/${SiteNav.COMPANYPROFILE}`,
    component: Home,
    need: Authorities.ReadCompanyProfile,
    exact: true,
  },
  {
    path: `/${SiteNav.BUSINESS_UNITS}`,
    key: `/${SiteNav.BUSINESS_UNITS}`,
    component: Home,
    need: Authorities.ReadBusinessUnit,
    exact: true,
  },
  {
    path: `/${SiteNav.BUSINESS_UNITS}/add`,
    key: `/${SiteNav.BUSINESS_UNITS}/add`,
    component: BusinessUnitsForm,
    need: Authorities.AddBusinessUnit,
  },
  {
    path: `/${SiteNav.BUSINESS_UNITS}/edit/:id?`,
    key: `/${SiteNav.BUSINESS_UNITS}/edit/:id?`,
    component: BusinessUnitsForm,
    need: Authorities.EditBusinessUnit,
  },
  {
    path: `/${SiteNav.BUSINESS_UNITS}/duplicate/:id?`,
    key: `/${SiteNav.BUSINESS_UNITS}/duplicate/:id?`,
    component: BusinessUnitsForm,
    need: Authorities.AddBusinessUnit,
  },
  {
    path: `/${SiteNav.BUSINESS_UNITS}/view/:id?`,
    key: `/${SiteNav.BUSINESS_UNITS}/view/:id?`,
    component: BusinessUnitsForm,
    need: Authorities.ReadBusinessUnit,
  },
];

// find entries
type EntryConfig = {
  path?: string;
  need?: Authorities[];
  needSome?: Authorities[];
  subItems?: EntryConfig[];
};
const PCEntries: EntryConfig[] = LeftSiderConfig.map((item) => {
  return {
    path: item.to,
    need: item.need,
    needSome: item.needSome,
    subItems: (item.subItems || []).map((sub) => {
      return {
        path: sub.to,
        need: sub.need,
        needSome: sub.needSome,
      };
    }),
  };
});
const MobileEntries: EntryConfig[] = PrivateMobileRoutes.map((item) => {
  return {
    path: item.path,
    need: item.need ? [item.need] : [],
  };
});
export const findFirstValidRoute = (authorities: string[]) => {
  const isMobile = testMobile();
  const entryList = isMobile ? MobileEntries : PCEntries;
  let find = "";

  entryList.forEach((item) => {
    if (find) return;

    // need some
    if (
      item.needSome?.length &&
      !item.needSome.some((p) => {
        return authorities.includes(p);
      })
    ) {
      return;
    }

    // need
    if (
      item.need?.length &&
      !item.need.every((p) => {
        return authorities.includes(p);
      })
    ) {
      return;
    }

    // to
    if (item.path) {
      find = item.path;
    }

    if (!item.path && item.subItems) {
      item.subItems.forEach((child) => {
        if (find) return;

        // need some
        if (
          child.needSome?.length &&
          !child.needSome.some((p) => {
            return authorities.includes(p);
          })
        ) {
          return;
        }

        // need
        if (
          child.need?.length &&
          !child.need.every((p) => {
            return authorities.includes(p);
          })
        ) {
          return;
        }

        find = item.path || "";
      });
    }
  });

  return find || PublicRoutes[1].path;
};
