import intl from "langs/intl";

export const UsersText = intl.formatMessage({ defaultMessage: "Users" });
export const OverviewText = intl.formatMessage({ defaultMessage: "Overview" });
export const WalkInsUsageText = intl.formatMessage({
  defaultMessage: "Walk-in Usage",
});
export const BookingUsageText = intl.formatMessage({
  defaultMessage: "Booking Usage",
});
export const PrivateSpacesText = intl.formatMessage({
  defaultMessage: "Private Locations",
});
export const EmployeesText = intl.formatMessage({
  defaultMessage: "Employees",
});
export const EmployeeManagementText = intl.formatMessage({
  defaultMessage: "Employee Management",
});
export const CompanyProfileText = intl.formatMessage({
  defaultMessage: "Company Profile",
});
export const PaymentText = intl.formatMessage({
  defaultMessage: "Payments",
});
export const PaymentGeneralInfoText = intl.formatMessage({
  defaultMessage: "Billing Info",
});
export const PaymentVouchersText = intl.formatMessage({
  defaultMessage: "Vouchers",
});
export const InvoiceText = intl.formatMessage({
  defaultMessage: "Invoices",
});
export const ReceiptText = intl.formatMessage({
  defaultMessage: "Receipts",
});

export const PeopleText = intl.formatMessage({
  defaultMessage: "People",
});

export const BusinessUnitsText = intl.formatMessage({
  defaultMessage: "Business Units",
});

export const BillingInfoText = intl.formatMessage({
  defaultMessage: "Billing Info",
});
