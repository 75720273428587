import styled from "styled-components";
import { FlexRow, FlexCenter } from "styles/common";
import { Caption1CSS } from "styles/fonts";
import * as C from "styles/colors";

interface BProps {
  isCur?: boolean;
  disabled?: boolean;
}

export const Button = styled.p`
  color: ${C.Neutral};
  user-select: none;
  cursor: ${(p: BProps) => (p.disabled ? "not-allowed" : "pointer")};
  box-sizing: border-box;
  padding: 4px;
  margin: 0;
  position: relative;
  transition: color 0.2s ease;
  font-family: "Roboto", sans-serif;
  font-weight: ${(p: BProps) => (p.isCur ? 700 : 400)};
  font-size: 14px;
  letter-spacing: 0.01em;

  &:hover {
    color: ${C.PrimaryLighter};
  }

  &::after {
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    right: 4px;
    left: 4px;
    bottom: 0;
    background-color: ${(p: BProps) => (p.isCur ? C.Primary : C.None)};
    border-radius: 2px;
  }
`;

export const Wrapper = styled.div`
  ${FlexRow}
  ${FlexCenter}
`;

export const CountBadge = styled.span`
  margin: 0;
  ${Caption1CSS}
  line-height: 16px;
  color: #fff;
  background-color: ${C.Primary};
  box-sizing: border-box;
  padding: 0 5px;
  border-radius: 8px;
  position: relative;
`;
