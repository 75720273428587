import React from "react";
import intl from "langs/intl";

export const LoadingText = intl.formatMessage({
  defaultMessage: "Loading...",
});
export const ImportingText = intl.formatMessage({
  defaultMessage: "Importing...",
});
export const ImportingDoneText = intl.formatMessage({
  defaultMessage: "Importing process is done.",
});
export const ImportingTimoutText = intl.formatMessage({
  defaultMessage: "Importing process timeout.",
});
export const PleaseInputPasswordText = intl.formatMessage({
  defaultMessage: "Please input your password",
});
export const PleaseInputUsernameText = intl.formatMessage({
  defaultMessage: "Please input your username",
});

// Inventory
export const InventoryAddedText = intl.formatMessage({
  defaultMessage: "Inventory Added",
});
export const InventoryUpdatedText = intl.formatMessage({
  defaultMessage: "Inventory Updated",
});
export const InventoryDuplicatedText = intl.formatMessage({
  defaultMessage: "Inventory Duplicated",
});
export const InventoryBookedText = intl.formatMessage({
  defaultMessage: "Inventory Booked",
});
export const InventoryDeletedText = intl.formatMessage({
  defaultMessage: "Inventory Deleted",
});
export const SubmitInventoryErrText = intl.formatMessage({
  defaultMessage: "Submit inventory data error.",
});
export const LoadInventoryErrText = intl.formatMessage({
  defaultMessage: "Load inventory error!",
});
export const UpdateInventoryErrText = intl.formatMessage({
  defaultMessage: "Update inventory error.",
});
export const DeleteInventoryText = intl.formatMessage({
  defaultMessage: "Are you sure you want to delete this inventory?",
});
export const DeleteTipText = intl.formatMessage({
  defaultMessage: "Once deleted, all data will be removed permanently.",
});
export const QuitTipText = intl.formatMessage({
  defaultMessage: "Once quit, all data will be removed permanently.",
});
export const DiscardChangesText = intl.formatMessage({
  defaultMessage: "Are you sure you want to quit editing?",
});
export const SimpleDiscardChangesText = intl.formatMessage({
  defaultMessage: "Discard Changes?",
});
export const InventoryOpeningHourTipText = intl.formatMessage({
  defaultMessage:
    "Inventory opening hour cannot be longer than space opening hour.",
});
export const DiscardText = intl.formatMessage({
  defaultMessage: "Discard",
});
// Booking
export const BookingErrorText = intl.formatMessage({
  defaultMessage: "Booking Error",
});
export const BookingCancelledText = intl.formatMessage({
  defaultMessage: "Booking Cancelled",
});
export const CancellBookingConfirmText = intl.formatMessage({
  defaultMessage: "Are you sure you want to cancel this booking?",
});
export const NoBookableWorkspaceText = intl.formatMessage({
  defaultMessage: "There is no bookable workspace.",
});
export const YesCancelText = intl.formatMessage({
  defaultMessage: "Yes, Cancel",
});
export const NotNowText = intl.formatMessage({
  defaultMessage: "Not Now",
});
export const ReadFileErrorText = intl.formatMessage({
  defaultMessage: "Read File Error.",
});

// form
export const InputUsernameText = intl.formatMessage({
  defaultMessage: "Please input your username",
});
export const InputPasswordText = intl.formatMessage({
  defaultMessage: "Please input your password",
});
export const ChangesSavedText = intl.formatMessage({
  defaultMessage: "Changes Saved",
});
export const SavedText = intl.formatMessage({
  defaultMessage: "Saved",
});
export const CompanyProfileUpdatedText = intl.formatMessage({
  defaultMessage: "Company profile updated.",
});
export const DeleteErrorText = intl.formatMessage({
  defaultMessage: "Delete error",
});
export const UploadImgErrText = intl.formatMessage({
  defaultMessage: "Upload error",
});
export const UploadRequireText = intl.formatMessage({
  defaultMessage:
    "Upload at least 1 and up to 10 images. File size cannot exceed 800KB.",
});
export const UploadImgOverLimitText = intl.formatMessage({
  defaultMessage: "Sorry, you cannot upload photos larger than 800KB",
});
export const UnfinushedErrText = intl.formatMessage({
  defaultMessage: "There is uncompleted upload.",
});
export const FailToLoadImageText = intl.formatMessage({
  defaultMessage: "Fail to load image.",
});

export const CompanyInfoText = intl.formatMessage({
  defaultMessage: "Company Info",
});

export const UsageSettingText = intl.formatMessage({
  defaultMessage: "Usage Setting",
});
export const CompanyDisplayNameExplanationText = intl.formatMessage({
  defaultMessage:
    "This will show up as the name of the profile in the Switch App.",
});

// space
export const LoadSpaceErrText = intl.formatMessage({
  defaultMessage: "Load Space error!",
});
export const UpdateSpaceErrText = intl.formatMessage({
  defaultMessage: "Update space error.",
});
export const SpaceDeletedText = intl.formatMessage({
  defaultMessage: "Space Deleted",
});
export const DeleteSpaceText = intl.formatMessage({
  defaultMessage: "Are you sure you want to delete this space?",
});
export const SubmitSpaceErrText = intl.formatMessage({
  defaultMessage: "Submit space data error.",
});
export const SpaceAddedText = intl.formatMessage({
  defaultMessage: "Space Added",
});
export const SpaceDuplicatedText = intl.formatMessage({
  defaultMessage: "Space Duplicated",
});
export const SpacePublishLimitText = intl.formatMessage({
  defaultMessage:
    "A space can only be published when there is at least 1 inventory.",
});
export const GotItText = intl.formatMessage({
  defaultMessage: "Got It",
});

// scanner
export const AlreadyConfigedText = intl.formatMessage({
  defaultMessage: "Already Configed",
});
export const SubmitSuccessText = intl.formatMessage({
  defaultMessage: "Submit success",
});
export const BindSuccessText = intl.formatMessage({
  defaultMessage: "Bind success",
});
export const BindErrorText = intl.formatMessage({
  defaultMessage: "Bind error",
});
export const ConfirmConfigText = intl.formatMessage({
  defaultMessage: "Confirm Config",
});
export const NoConfigurableQRCodeText = intl.formatMessage({
  defaultMessage: "No Configurable QR Code",
});
export const RequestQRCodeErrorText = intl.formatMessage({
  defaultMessage: "Request QR Code Error.",
});

// others
export const LoadAmenitiesErrText = intl.formatMessage({
  defaultMessage: "Load amenities error.",
});
export const UnknownErrorText = intl.formatMessage({
  defaultMessage: "Unknown error",
});
export const SeverErrorText = intl.formatMessage({
  defaultMessage: "Sever error",
});
export const StripeSeverErrorText = intl.formatMessage({
  defaultMessage: "Stripe sever error",
});
export const RequestDataFailedText = intl.formatMessage({
  defaultMessage: "Request data failed",
});
export const PleaseUseDesktopWebsiteText = intl.formatMessage({
  defaultMessage: "Please use desktop website.",
});
export const SpecialCharactersText = intl.formatMessage({
  defaultMessage: "Please do not input special characters.",
});
export const CanNotBeEmptyText = intl.formatMessage({
  defaultMessage: "Can not be empty.",
});
export const PleaseSelectTimeText = intl.formatMessage({
  defaultMessage: "Please select time.",
});
export const NoDefaultCountryText = intl.formatMessage({
  defaultMessage: "No default country",
});
export const NoAvailableCountryText = intl.formatMessage({
  defaultMessage: "No available countries",
});
export const MinLengthText = (value: number, unit: string) =>
  intl.formatMessage(
    {
      description: "input err",
      defaultMessage: "Please input more than {value} {unit}.",
    },
    {
      value,
      unit,
    }
  );
export const QRBrightnessTipText = intl.formatMessage(
  {
    defaultMessage:
      "Please use the full brightness of the device {newLine} if there has some trouble with scanning",
  },
  {
    newLine: <br />,
  }
);
export const StartAddingSpaceTipText = intl.formatMessage(
  {
    defaultMessage: "There’s no data yet. {newLine} Start adding location now.",
  },
  {
    newLine: <br />,
  }
);
export const MobileLoginMessage = (link: any) =>
  intl.formatMessage(
    {
      defaultMessage:
        "It looks like you’re viewing this application on a mobile device. To get the best experience, please visit {link} on a laptop computer. ",
    },
    { link }
  );
export const StartAddingInventoryTipText = intl.formatMessage(
  {
    defaultMessage:
      "There’s no data yet. {newLine} Start adding inventory to this space now.",
  },
  {
    newLine: <br />,
  }
);
export const ThereIsNoDataYetText = intl.formatMessage({
  defaultMessage: "There’s no data yet.",
});
export const LostFileText = intl.formatMessage({
  defaultMessage: "Can not find origin file.",
});
export const LostFiledDataText = intl.formatMessage({
  defaultMessage: "Lost failed data.",
});

export const PaidSuccessText = intl.formatMessage({
  defaultMessage: "You have paid successfully.",
});
export const PaidNotSuccessText = intl.formatMessage({
  defaultMessage: "Your payment is not successful.",
});

export const AddBusinessUnitSucess = intl.formatMessage({
  defaultMessage: "Business unit added.",
});

export const EditBusinessUnitSucess = intl.formatMessage({
  defaultMessage: "Business unit edited.",
});

export const DeleteBusinessUnitSucess = intl.formatMessage({
  defaultMessage: "Business unit deleted.",
});

export const PleaseAddCreditCradText = intl.formatMessage({
  defaultMessage: "Please Add Credit Crad.",
});
