import intl from "langs/intl";
import React from "react";

import arrowLeft from "./assets/arrow-left.svg";
import arrowRight from "./assets/arrow-right.svg";
import { Primary, Neutral5 } from "styles/colors";
import { IconClearAll } from "styles/icons";
import { FlexBlank, FlexRowWrapper } from "styles/common";
import Tooltip from "@REInventVenturesPteLtd/sui/lib/components/Tooltip";

interface IDateNavigatorProps {
  title: string;
  onPreviousClick: () => void;
  onNextClick: () => void;
  onTitleClick?: () => void;
  onClickResetToToday?: () => void;
  previousDisabled?: boolean;
  nextDisabled?: boolean;
  hasTodayButton?: boolean;
  isTodayDisabled?: boolean;
  onClearDate?: () => void;
}

export default function DateNavigator({
  title,
  onPreviousClick,
  onNextClick,
  previousDisabled,
  nextDisabled,
  onTitleClick,
  onClickResetToToday,
  hasTodayButton,
  isTodayDisabled,
  onClearDate,
}: IDateNavigatorProps) {
  if (hasTodayButton) {
    return (
      <div className={"dn-container"}>
        <div className="dn-title-container">
          <div
            className={`dn-arrow-button
              ${previousDisabled ? "dn-arrow-button-disabled" : ""}`}
            style={{ marginRight: 8 }}
            onClick={previousDisabled ? undefined : onPreviousClick}
          >
            <img src={arrowLeft} alt="" />
          </div>
          <div
            className={"dn-title"}
            style={{
              position: "relative",
              top: 1,
              cursor: onTitleClick ? "pointer" : "auto",
            }}
            onClick={onTitleClick}
          >
            {title}
          </div>
          <div
            className={`dn-arrow-button
              ${nextDisabled ? "dn-arrow-button-disabled" : ""}`}
            style={{ marginLeft: 8 }}
            onClick={nextDisabled ? undefined : onNextClick}
          >
            <img src={arrowRight} alt="" />
          </div>
        </div>
        <FlexBlank />
        <div
          className={"dn-btn-to-today"}
          onClick={isTodayDisabled ? undefined : onClickResetToToday}
          style={{
            cursor: isTodayDisabled ? "not-allowed" : "pointer",
            color: isTodayDisabled ? Neutral5 : Primary,
            marginRight: !!onClearDate ? 12 : undefined,
          }}
        >
          {intl.formatMessage({ defaultMessage: "Today" })}
        </div>
        {!!onClearDate && (
          <Tooltip text={intl.formatMessage({ defaultMessage: "Clear All" })}>
            <FlexRowWrapper
              style={{
                width: 32,
                height: 32,
                cursor: "pointer",
              }}
              align="center"
              horizontal="center"
              onClick={onClearDate}
            >
              <IconClearAll />
            </FlexRowWrapper>
          </Tooltip>
        )}
      </div>
    );
  }
  return (
    <div className={"dn-container"}>
      <div className={"dn-title"} onClick={onTitleClick}>
        {title}
      </div>
      <div className={"dn-buttons-container"}>
        <div
          className={`dn-arrow-button
            ${previousDisabled ? "dn-arrow-button-disabled" : ""}`}
          onClick={previousDisabled ? undefined : onPreviousClick}
        >
          <img src={arrowLeft} alt="" />
        </div>
        <div
          className={`dn-arrow-button
            ${nextDisabled ? "dn-arrow-button-disabled" : ""}`}
          onClick={nextDisabled ? undefined : onNextClick}
        >
          <img src={arrowRight} alt="" />
        </div>
      </div>
    </div>
  );
}
