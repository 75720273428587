import React, { useState, useCallback, useEffect } from "react";
import * as S from "./styles.item";

import { SortType } from "constants/products";

import useButtonPressed from "hooks/useButtonPressedEffect";

interface SortItemProps {
  text: string;
  callback: (v: SortType) => void;
  defaultSort?: SortType;
}

const SortItem = React.memo(
  ({ defaultSort, callback, text }: SortItemProps) => {
    const [sort, setSortType] = useState(defaultSort || SortType.NONE);
    const { pressed, onMouseDown, onMouseUp } = useButtonPressed();
    const onClick = useCallback(() => {
      setSortType((v) => {
        if (!v) return SortType.ASC;
        if (v === SortType.ASC) return SortType.DESC;
        return SortType.NONE;
      });
    }, []);
    useEffect(() => {
      if (callback) callback(sort);
    }, [callback, sort]);
    return (
      <S.SortItemWrapper
        onClick={onClick}
        isact={!!sort}
        pressed={pressed}
        onMouseDownCapture={onMouseDown}
        onMouseUpCapture={onMouseUp}
        onMouseOutCapture={onMouseUp}
      >
        {text}
        <S.SortActionWrapper>
          <S.IconSortUp isact={sort === SortType.ASC ? "true" : ""} />
          <S.IconSortDOWN isact={sort === SortType.DESC ? "true" : ""} />
        </S.SortActionWrapper>
      </S.SortItemWrapper>
    );
  }
);

export default SortItem;
