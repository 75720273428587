import React from "react";

import arrowLeft from "./assets/arrow-left.svg";
import arrowRight from "./assets/arrow-right.svg";

interface IDateNavigatorProps {
  title: string;
  onPreviousClick: () => void;
  onNextClick: () => void;
  onPreviousYearClick?: () => void;
  onNextYearClick?: () => void;
  onTitleClick?: () => void;
  previousDisabled?: boolean;
  nextDisabled?: boolean;
  previousYearDisabled?: boolean;
  nextYearDisabled?: boolean;
  expand?: "all" | "left" | "right";
}

export default function DateNavigator({
  title,
  onPreviousClick,
  onNextClick,
  onPreviousYearClick,
  onNextYearClick,
  previousDisabled,
  nextDisabled,
  previousYearDisabled,
  nextYearDisabled,
  onTitleClick,
  expand = "all",
}: IDateNavigatorProps) {
  return (
    <div className={"dn-container"}>
      <div className={"dn-buttons-container"}>
        {expand !== "right" && (
          <>
            <div
              className={`dn-arrow-button
            ${previousYearDisabled ? "dn-arrow-button-disabled" : ""}`}
              onClick={previousDisabled ? undefined : onPreviousYearClick}
            >
              <img src={arrowLeft} alt="" />
              <img src={arrowLeft} alt="" />
            </div>
            <div
              className={`dn-arrow-button
            ${previousDisabled ? "dn-arrow-button-disabled" : ""}`}
              onClick={previousYearDisabled ? undefined : onPreviousClick}
            >
              <img src={arrowLeft} alt="" />
            </div>
          </>
        )}
      </div>
      <div className={"dn-title"} onClick={onTitleClick}>
        {title}
      </div>
      <div className={"dn-buttons-container"}>
        {expand !== "left" && (
          <>
            <div
              className={`dn-arrow-button
            ${nextDisabled ? "dn-arrow-button-disabled" : ""}`}
              onClick={nextDisabled ? undefined : onNextClick}
            >
              <img src={arrowRight} alt="" />
            </div>
            <div
              className={`dn-arrow-button
            ${nextYearDisabled ? "dn-arrow-button-disabled" : ""}`}
              onClick={nextYearDisabled ? undefined : onNextYearClick}
            >
              <img src={arrowRight} alt="" />
              <img src={arrowRight} alt="" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
