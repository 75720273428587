import styled from "styled-components";

import { messageUtil } from "components/Sibling/Message";

import { Neutral8, Secondary, GrayF0, Black59 } from "styles/colors";
import { Body2CSS, BodyCSS, Body1CSS } from "styles/fonts";
import intl from "langs/intl";

export const message = messageUtil({
  content: <PortalMessage />,
  animDuration: 1,
});
// export const Tip = message?.init();

interface PortalMessageProps {
  leaving?: boolean;
  entering?: boolean;
  close?: () => void;
}

export function PortalMessage(props: PortalMessageProps) {
  return (
    <MessageWrapper>
      <MessageTitle>
        {intl.formatMessage({
          defaultMessage: "Please Log In With Your Computer",
        })}
      </MessageTitle>
      <p>
        {intl.formatMessage(
          {
            defaultMessage:
              "It looks like you're viewing this page on a mobile device. To get the best experience, please visit <span>business.switch.site</span> on a computer. ",
          },
          { span: (str) => <MessageLink>{str}</MessageLink> }
        )}
      </p>
      <MessageCloseButton onClick={props.close}>
        {intl.formatMessage({ defaultMessage: "Ok, Got It" })}
      </MessageCloseButton>
    </MessageWrapper>
  );
}

export const MessageWrapper = styled.div`
  position: fixed;
  top: 10px;
  left: 16px;
  right: 16px;
  max-width: calc(100vw - 32px);
  ${Body2CSS}
  color: ${Neutral8};
  background-color: #fff;
  border: 1px solid ${GrayF0};
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 15px 30px rgba(0, 0, 0, 0.02),
    0 15px 50px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  padding: 24px;
  z-index: 20;
`;

const MessageCloseButton = styled.div`
  ${BodyCSS}
  color: ${Black59};
  margin-top: 8px;
  margin-left: auto;
  width: max-content;
  cursor: pointer;
  border: 1px solid ${GrayF0};
  font-weight: bold;
  padding: 8px 15px;
`;

const MessageLink = styled.span`
  color: ${Secondary};
`;

const MessageTitle = styled.div`
  ${Body1CSS}
  font-weight: bold;
  color: #000;
  letter-spacing: 0.01em;
  margin-bottom: 8px;
`;
