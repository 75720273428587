import React, { useCallback } from "react";

import useButtonPressed from "hooks/useButtonPressedEffect";
import * as S from "./styles.button";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  setRef?: React.RefObject<HTMLButtonElement>;
  disabled?: boolean;
  active?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
  icon?: JSX.Element;
  text?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  defaultBgColor?: string;
  isActivePrimary?: boolean;
  isTable?: boolean;
}

const SelectButton = React.memo(
  ({
    setRef,
    disabled,
    onClick,
    icon,
    text,
    children,
    ...rest
  }: ButtonProps) => {
    const { pressed, onMouseDown, onMouseUp } = useButtonPressed();
    const _onMouseDownCapture = useCallback(
      (e) => {
        if (rest.onMouseDownCapture) rest.onMouseDownCapture(e);
        onMouseDown();
      },
      [onMouseDown, rest]
    );
    const _onMouseUpCapture = useCallback(
      (e) => {
        if (rest.onMouseUpCapture) rest.onMouseUpCapture(e);
        onMouseUp();
      },
      [onMouseUp, rest]
    );
    const _onMouseOutCapture = useCallback(
      (e) => {
        if (rest.onMouseOutCapture) rest.onMouseOutCapture(e);
        onMouseUp();
      },
      [onMouseUp, rest]
    );
    const _onClick = useCallback(
      (e: React.MouseEvent) => {
        if (!e) return;
        if (disabled) {
          e.stopPropagation();
          return;
        }
        if (onClick) onClick(e);
      },
      [onClick, disabled]
    );
    return (
      <S.ButtonWrapper
        {...rest}
        ref={setRef}
        onClick={_onClick}
        onMouseDownCapture={_onMouseDownCapture}
        onMouseUpCapture={_onMouseUpCapture}
        onMouseOutCapture={_onMouseOutCapture}
        pressed={pressed}
        disabled={disabled}
      >
        {!!icon && icon}
        {text || children}
      </S.ButtonWrapper>
    );
  }
);

export default SelectButton;
