import styled from "styled-components";
import * as C from "styles/colors";

export const border = `1px solid ${C.GrayF0}`;

export const TotalSummaryWrapper = styled.div`
  border-radius: 2px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: ${border};
  border-bottom: none;
  border-right: none;
  overflow: hidden;
  flex: none;
`;

export const SummaryItem = styled.div`
  width: 25%;
  flex: none;
  min-width: 253px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  height: 96px;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 1px;
    width: 1px;
    height: 100%;
    background-color: ${C.GrayF0};
  }
`;

export const TotalCostItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
