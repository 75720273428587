import request from "./request";
import getBaseUrl from "./baseUrl";
import {
  getTokenValue,
  generateRefreshToken,
  setToken,
  getTokenType,
} from "./accessToken";
import { requestListDataInBatches } from "./requestListDataInBatches";

export default request;

const ListRequestMaxLength = 50;

export {
  getBaseUrl,
  getTokenValue,
  generateRefreshToken,
  setToken,
  getTokenType,
  ListRequestMaxLength,
  requestListDataInBatches,
};

export type { IRequestParamsInBatches } from "./requestListDataInBatches";
export type { IRequestParams } from "./request";
