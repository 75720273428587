import styled from "styled-components";
import * as C from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.p`
  color: ${(p: { isCur?: boolean }) => (p.isCur ? C.Black : C.Black8C)};
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 6px;
  margin: 0;
  position: relative;
  transition: color 0.2s ease;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: ${(p: { isCur?: boolean }) => (p.isCur ? 700 : 400)};
  letter-spacing: 0.01em;

  &:hover {
    color: ${C.LighterOrange};
  }

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: 1px;
    height: 12px;
    position: absolute;
    right: -0.5px;
    top: 50%;
    margin-top: -6px;
    background-color: ${C.GrayF0};
  }
`;
