import { navigatorText } from "langs/formatText";
import { Authorities } from "store/accessToken";

export enum SiteNav {
  DASHBOARD = "overview",
  USAGE = "usage",
  USERS = "users",
  COUNTRY = "country",
  BOOKINGS_USAGE = "bookings_usage",
  SPACES = "spaces",
  INVENTORY = "inventory",
  EMPLOYEES = "employees",
  COMPANYPROFILE = "company_profile",
  SELF_SERVE = "self_serve",
  PAYMENT = "payment",
  PAYMENT_GENERAL = "payment_general",
  PAYMENT_VOUCHERS = "payment_vouchers",
  INVOICE = "invoice",
  RECEIPT = "receipt",
  NOT_ALLOWED = "403",
  PEOPLE = "people",
  BUSINESS_UNITS = "business_units",
  BILLING_INFO = "billing_info",
  PASSWORD = "password",
  VERIFYCODE = "verify_code",
}

type ISiderConfig = {
  key: SiteNav;
  title: string;
  to?: string;
  path?: string;
  need?: Authorities[];
  needSome?: Authorities[];
  icon?: string;
  subItems?: Array<{
    key: SiteNav;
    title: string;
    to?: string;
    path?: string;
    need?: Authorities[];
    needSome?: Authorities[];
    icon?: string;
  }>;
};

export const LeftSiderConfig: ISiderConfig[] = [
  {
    key: SiteNav.DASHBOARD,
    title: navigatorText.OverviewText,
    to: "/" + SiteNav.DASHBOARD,
    need: [Authorities.ReadDashboard],
    icon: "IconBook",
  },
  {
    key: SiteNav.SPACES,
    title: navigatorText.PrivateSpacesText,
    to: "/" + SiteNav.SPACES,
    need: [Authorities.ReadSpaces],
    icon: "IconBook",
  },
  {
    key: SiteNav.USAGE,
    title: navigatorText.WalkInsUsageText,
    to: "/" + SiteNav.USAGE,
    need: [Authorities.ReadUsage],
    icon: "IconBook",
  },
  {
    key: SiteNav.BOOKINGS_USAGE,
    title: navigatorText.BookingUsageText,
    to: "/" + SiteNav.BOOKINGS_USAGE,
    need: [Authorities.ReadBooking],
    icon: "IconBook",
  },
  {
    key: SiteNav.PEOPLE,
    title: navigatorText.PeopleText,
    need: [Authorities.ReadDashboard],
    icon: "IconBook",
    subItems: [
      {
        key: SiteNav.EMPLOYEES,
        title: navigatorText.EmployeesText,
        to: "/" + SiteNav.EMPLOYEES,
        need: [Authorities.ReadEmployees],
        icon: "IconBook",
      },
      {
        key: SiteNav.BUSINESS_UNITS,
        title: navigatorText.BusinessUnitsText,
        to: "/" + SiteNav.BUSINESS_UNITS,
        need: [Authorities.ReadEmployees],
        icon: "IconBook",
      },
    ],
  },
  {
    key: SiteNav.COMPANYPROFILE,
    title: navigatorText.CompanyProfileText,
    to: "/" + SiteNav.COMPANYPROFILE,
    need: [Authorities.ReadCompanyProfile],
    icon: "IconBook",
  },
  {
    key: SiteNav.PAYMENT,
    title: navigatorText.PaymentText,
    need: [Authorities.ReadPayment],
    icon: "IconBook",
    subItems: [
      {
        key: SiteNav.PAYMENT_GENERAL,
        title: navigatorText.PaymentGeneralInfoText,
        to: `/${SiteNav.PAYMENT}/view`,
        need: [Authorities.ReadPayment],
        icon: "IconBook",
      },
      {
        key: SiteNav.PAYMENT_VOUCHERS,
        title: navigatorText.PaymentVouchersText,
        to: `/${SiteNav.PAYMENT}/vouchers`,
        need: [Authorities.ReadPayment],
        icon: "IconBook",
      },
    ],
  },
  {
    key: SiteNav.INVOICE,
    title: navigatorText.InvoiceText,
    to: "/" + SiteNav.INVOICE,
    need: [Authorities.ReadInvoice],
    icon: "IconBook",
  },
  {
    key: SiteNav.RECEIPT,
    title: navigatorText.ReceiptText,
    to: "/" + SiteNav.RECEIPT,
    need: [Authorities.ReadReceipt],
    icon: "IconBook",
  },
];
