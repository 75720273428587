import React from "react";
import Explanation from "components/Explanation";
import type { ExplanationProps } from "components/Explanation/Explanation";
import { SuiDropdownZIndex } from "@REInventVenturesPteLtd/sui/lib/styles/zindex";
import { SuiDropdownPortalID } from "@REInventVenturesPteLtd/sui/lib/components/Dropdown";

import { formatColumnDate, getDateRange, TimeUnit } from "utils/date";
import { DateOptionsType } from "constants/date";
import { tableText } from "langs/formatText";

import { Neutral6 } from "styles/colors";

interface SelectionExplanationProps {
  type: DateOptionsType | string;
  explanation?: string;
}

const DefaultExplanationProps: ExplanationProps = {
  direction: "top",
  size: 10,
  iconStyles: { fill: Neutral6, marginLeft: 4 },
  parentNodeId: SuiDropdownPortalID,
  style: { zIndex: SuiDropdownZIndex + 1 },
};

const SelectionExplanation = React.memo(
  ({ type, explanation }: SelectionExplanationProps) => {
    if (explanation) {
      return <Explanation text={explanation} {...DefaultExplanationProps} />;
    }

    const range = getDateRange(new Date());
    switch (type) {
      default:
        return null;
      case DateOptionsType.DAYS_SEVEN:
        const Days7Text = `${tableText.FromText} ${formatColumnDate(
          range.todayEnd - TimeUnit.Day * 7 + TimeUnit.Second * 2,
          true
        )} ${tableText.ToText} ${formatColumnDate(range.todayEnd, true)}`;
        return <Explanation text={Days7Text} {...DefaultExplanationProps} />;
      case DateOptionsType.DAYS_THIRTY:
        const Days30Text = `${tableText.FromText} ${formatColumnDate(
          range.todayEnd - TimeUnit.Day * 30 + TimeUnit.Second * 2,
          true
        )} ${tableText.ToText} ${formatColumnDate(range.todayEnd, true)}`;
        return <Explanation text={Days30Text} {...DefaultExplanationProps} />;
      case DateOptionsType.DAYS_NINTY:
        const Days90Text = `${tableText.FromText} ${formatColumnDate(
          range.todayEnd - TimeUnit.Day * 90 + TimeUnit.Second * 2,
          true
        )} ${tableText.ToText} ${formatColumnDate(range.todayEnd, true)}`;
        return <Explanation text={Days90Text} {...DefaultExplanationProps} />;
      case DateOptionsType.WEEK:
        const wText = `${tableText.FromText} ${formatColumnDate(
          range.weekStart,
          true
        )} ${tableText.ToText} ${formatColumnDate(range.weekEnd, true)}`;
        return <Explanation text={wText} {...DefaultExplanationProps} />;
      case DateOptionsType.MONTH:
        const mText = `${tableText.FromText} ${formatColumnDate(
          range.monthStart,
          true
        )} ${tableText.ToText} ${formatColumnDate(range.monthEnd, true)}`;
        return <Explanation text={mText} {...DefaultExplanationProps} />;
      case DateOptionsType.YEAR:
        const yText = `${tableText.FromText} ${formatColumnDate(
          range.yearStart,
          true
        )} ${tableText.ToText} ${formatColumnDate(
          range.yearEnd - TimeUnit.Minute,
          true
        )}`;
        return <Explanation text={yText} {...DefaultExplanationProps} />;
    }
  }
);

export default SelectionExplanation;
