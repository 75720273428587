import qs from "query-string";

export const isSubPath = (parent: string, child: string) => {
  return (
    parent.startsWith(child) &&
    (parent.length === child.length || parent[child.length] === "/")
  );
};

export const appendQuery = (url: string, params: Record<string, any>) => {
  const parsedUrl = new URL(url);
  const query = qs.parse(parsedUrl.search);
  parsedUrl.search = qs.stringify({ ...query, ...params });
  return parsedUrl.href;
};
