import React, { useCallback } from "react";

export enum EPickerTypes {
  NONE = "None",
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Year = "Year",
  Range = "Range",
  Since = "Since",
}

export function isOneOfEPickerTypes(test: string): test is EPickerTypes {
  return (
    test === EPickerTypes.Day ||
    test === EPickerTypes.Week ||
    test === EPickerTypes.Month ||
    test === EPickerTypes.Year ||
    test === EPickerTypes.Range
  );
}

interface IDatePickerTypeProps {
  value: EPickerTypes;
  onChange: (type: EPickerTypes) => void;
}

const typeOptions = [
  EPickerTypes.Day,
  EPickerTypes.Week,
  EPickerTypes.Month,
  EPickerTypes.Year,
  EPickerTypes.Range,
];

function DatePickerType({ value, onChange }: IDatePickerTypeProps) {
  const handleTypeClick = useCallback(
    (e) => {
      onChange(e.target.getAttribute("data-type"));
    },
    [onChange]
  );
  return (
    <div className={"dpt-button-wrapper"}>
      {typeOptions.map((type) => (
        <button
          className={`dpt-type-button ${
            value === type ? "dpt-type-button-active" : ""
          }`}
          key={type}
          data-type={type}
          onClick={value === type ? undefined : handleTypeClick}
        >
          {type}
        </button>
      ))}
    </div>
  );
}

export default DatePickerType;
