import { useState, useCallback, useEffect } from "react";
import { createContainer } from "unstated-next";

import * as apiAccessToken from "service/request/accessToken";
import { token } from "service/v1/identity";
import tokenKey from "service/request/tokenKey";
import useStateValue from "hooks/useStateValue";

const storedToken = tokenKey ? localStorage.getItem(tokenKey()) : undefined;
const defaultToken = storedToken ? JSON.parse(storedToken) : null;

export enum Authorities {
  RoleAdmin = "ROLE_ADMIN",
  ReadMember = "read:enterprise-member",
  ReadOrder = "read:order",
  ReadCoworkingOrder = "read:coworking-order",
  ReadPodOrder = "read:pod-order",
  // dashboard
  ReadDashboard = "read:enterprise-dashboard",
  // scanner
  ReadScannerMapping = "read:scanner-mapping",
  CreateScannerMapping = "create:scanner-mapping",
  WriteScannerMapping = "write:scanner-mapping",
  DeleteScannerMapping = "delete:scanner-mapping",
  // booking
  ReadUsage = "read:enterprise-usage",
  ReadBooking = "read:enterprise-booking",
  // space
  ReadSpaces = "read:enterprise-space",
  AddSpace = "read:enterprise-space",
  EditSpace = "read:enterprise-space",
  DeleteSpace = "read:enterprise-space",
  AddInventory = "read:enterprise-space",
  EditInventory = "read:enterprise-space",
  // 后端暂时不支持删除
  DeleteInventory = "delete:inventory",
  // EMPLOYEES
  ReadEmployees = "read:enterprise-employee",
  AddEmployee = "add:enterprise-employee",
  EditEmployee = "edit:enterprise-employee",
  ArchiveEmployee = "archive:enterprise-employee",
  // Company Profile
  ReadCompanyProfile = "read:enterprise-companyprofile",
  // payment
  ReadPayment = "read:enterprise-payment",
  EditPayment = "read:enterprise-payment",
  DeletePayment = "read:enterprise-payment",
  AddPayment = "read:enterprise-payment",
  // invoice
  ReadInvoice = "read:enterprise-invoice",
  // receipt
  ReadReceipt = "read:enterprise-receipt",
  // bu admin
  ReadBusinessUnit = "read:business-unit",
  AddBusinessUnit = "add:business-unit",
  EditBusinessUnit = "edit:business-unit",
  DeleteBusinessUnit = "delete:business-unit",
}

// const LocalPermission: Authorities[] = [];

const container = createContainer(() => {
  const [accessToken, setAccessToken] =
    useState<IAccessToken | null>(defaultToken);
  // currently only overview inventory auth
  const [inventoryAuth, setInventoryAuth] = useState<Array<TInventoryType>>([]);
  const [curCountry, setCurCountry] = useStateValue<IUserCountry | null>(null);
  const [userCountries, setUserCountries] = useStateValue<
    Array<IUserCountries>
  >([]);

  const testAuth = useCallback(
    (key: Authorities) => {
      // the development phase can be handled as a temporary solution, and we can find the java to add it together after it is determined
      // if (LocalPermission.findIndex((i) => i === key) > -1) return true;
      if (!(accessToken && accessToken.authorities)) return false;
      if (key === Authorities.ReadOrder) {
        return (
          accessToken.authorities.findIndex(
            (i) => i === Authorities.ReadCoworkingOrder
          ) > -1 ||
          accessToken.authorities.findIndex(
            (i) => i === Authorities.ReadPodOrder
          ) > -1
        );
      }
      return accessToken.authorities.findIndex((i) => i === key) > -1;
    },
    [accessToken]
  );
  const testAuthAll = useCallback(
    (keys: Authorities[]) => {
      return keys.every((key) => testAuth(key));
    },
    [testAuth]
  );
  const testAuthSome = useCallback(
    (keys: Authorities[]) => {
      if (!keys.length) return true;
      return keys.some((key) => testAuth(key));
    },
    [testAuth]
  );
  const testInventoryAuth = useCallback(
    (v: TInventoryType) => {
      return inventoryAuth.findIndex((auth) => auth === v) > -1;
    },
    [inventoryAuth]
  );
  const onSetInventoryAuth = useCallback((v) => setInventoryAuth(v), []);
  const clearToken = useCallback(() => {
    apiAccessToken.clearToken();
    setAccessToken(null);
  }, []);
  const setToken = useCallback((token: IAccessToken) => {
    apiAccessToken.setToken(token);
    setAccessToken(token);
  }, []);
  const refreshTokenGenerator = useCallback(async () => {
    const lastToken = apiAccessToken.getToken();
    try {
      const tokenValue = await token();
      setAccessToken(tokenValue as IAccessToken);
      return tokenValue;
    } catch (e) {
      const curToken = apiAccessToken.getToken();
      if (lastToken === curToken) {
        clearToken();
      }
    }
  }, [setAccessToken, clearToken]);

  const updateCountryByData = useCallback(
    (cityCode) => {
      if (cityCode !== curCountry?.code && !!userCountries) {
        // let target = null;
        for (const i of userCountries) {
          if (!i.subdivisions) break;
          for (const j of i.subdivisions) {
            if (j.code === cityCode) {
              setCurCountry(j);
              // target = j;
              break;
            }
          }
        }
        // if (!target) window.location.href = "/";
      }
    },
    [curCountry?.code, setCurCountry, userCountries]
  );

  useEffect(() => {
    apiAccessToken.configureTokenRefresh(refreshTokenGenerator);
    return () => {
      apiAccessToken.configureTokenRefresh(undefined);
    };
  }, [refreshTokenGenerator]);

  return {
    isLogin: accessToken !== undefined && accessToken !== null,
    isInitialized: accessToken !== undefined,
    accessToken,
    clearToken,
    setToken,
    testAuth,
    testAuthAll,
    testAuthSome,
    testInventoryAuth,
    onSetInventoryAuth,
    curCountry,
    setCurCountry,
    userCountries,
    setUserCountries,
    updateCountryByData,
  };
});

export const AccessTokenProvider = container.Provider;

export const useAccessToken = container.useContainer;

export default container;
