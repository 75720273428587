import {
  IVoucherItem,
  IVoucherListParams,
  IVoucherRedeemResp,
} from "constants/voucher";
import request from "../request";

export const paymentInfo = async () => {
  return await request<TPaymentItem[]>({
    method: "GET",
    path: "/enterprise/v2/billing-info",
    // replaceBaseUrl: "https://yapi.reinventventures.com/mock/86",
    data: {},
    refreshOnUnauthorized: true,
  });
};

export const savePayment = async (data: TPaymentItem[]) => {
  return await request({
    method: "PUT",
    path: "/enterprise/v2/billing-info",
    data: { list: data },
    refreshOnUnauthorized: true,
  });
};

export const getPaymentSecret = async (legalEntityId: number) =>
  request<{ clientSecret: string }>({
    method: "POST",
    path: "/enterprise/v2/payment/setup-intents",
    refreshOnUnauthorized: true,
    data: {
      legalEntityId,
    },
  });

export const getPaymentFailStatus = async () =>
  request<boolean>({
    method: "GET",
    path: "/enterprise/v1/payment/organization/fail-status",
    refreshOnUnauthorized: true,
  });

export const reChargePayment = async () =>
  request<boolean>({
    method: "POST",
    path: "/enterprise/v1/payment/organization/reCharge/fail-payments",
    refreshOnUnauthorized: true,
  });

export const vouchers = async (params: IVoucherListParams) => {
  return await request<{ total: number; items: IVoucherItem[] }>({
    method: "GET",
    path: "/enterprise/v1/voucher/list",
    data: params,
  });
};
export const deleteLegalEntity = async (legalEntityId?: number) => {
  return await request({
    method: "DELETE",
    path: `/enterprise/v1/legal-entity/${legalEntityId}`,
    refreshOnUnauthorized: true,
  });
};

export const redeemVoucher = async (args: { code: string }) => {
  return await request<IVoucherRedeemResp>({
    method: "POST",
    path: "/enterprise/v1/voucher/redeem",
    data: { promoCode: args.code },
  });
};
export const deleteLegalEntityCheck = async (legalEntityId?: number) => {
  return await request({
    method: "DELETE",
    path: `/enterprise/v1/legal-entity/${legalEntityId}/check`,
    refreshOnUnauthorized: true,
  });
};

export const preSetupIntents = async () =>
  request<{ clientSecret: string; companyIdentify: string }>({
    method: "POST",
    path: "/enterprise/v2/payment/pre-setup-intents",
    refreshOnUnauthorized: true,
  });
