import intl from "langs/intl";

export const SpacesText = intl.formatMessage({ defaultMessage: "Spaces" });
export const SpaceText = intl.formatMessage({ defaultMessage: "Space" });
export const LocationsText = intl.formatMessage({
  defaultMessage: "Locations",
});
export const LocationText = intl.formatMessage({ defaultMessage: "Location" });
export const InventoryText = intl.formatMessage({
  defaultMessage: "Inventory",
});
export const CoworkingText = intl.formatMessage({
  defaultMessage: "Coworking",
});
export const DeskText = intl.formatMessage({ defaultMessage: "Desk" });
export const BoothText = intl.formatMessage({ defaultMessage: "Booth" });
export const RoomText = intl.formatMessage({ defaultMessage: "Room" });
export const MeetingRoomText = intl.formatMessage({
  defaultMessage: "Meeting Room",
});
export const PrivateOfficeText = intl.formatMessage({
  defaultMessage: "Private Office",
});
export const EventSpaceText = intl.formatMessage({
  defaultMessage: "Event Space",
});
export const HotDeskText = intl.formatMessage({
  defaultMessage: "Hot desk",
});
export const DedicatedDeskText = intl.formatMessage({
  defaultMessage: "Dedicated desk",
});
export const WalkInText = intl.formatMessage({ defaultMessage: "Walk-in" });
export const BookingText = intl.formatMessage({ defaultMessage: "Booking" });
export const SeatBoothText = (count: number) =>
  intl.formatMessage(
    {
      defaultMessage: "{count} {count, plural, =1 {seat} other {seats}} booth",
    },
    { count }
  );
export const PublishedText = intl.formatMessage({
  defaultMessage: "Published",
});
export const UnpublishedText = intl.formatMessage({
  defaultMessage: "Unpublished",
});
export const OpenText = intl.formatMessage({
  defaultMessage: "Open",
});
export const CapacityTypeText = (gte: number, lte: number) =>
  intl.formatMessage(
    {
      description: "eg: 1 seat / 2 - 4 seats / 5 - 9 seats / 20+ seats",
      defaultMessage:
        "{gte}{lte, plural, =0 { seat} =-1 {+ seats} other { - # seats}}",
    },
    { gte, lte }
  );
export const SwitchAppText = intl.formatMessage({
  defaultMessage: "Switch App",
});
export const SwitchText = intl.formatMessage({
  defaultMessage: "Switch",
});
export const SwitchPortalText = intl.formatMessage({
  defaultMessage: "Switch Portal",
});
export const PortalText = intl.formatMessage({
  defaultMessage: "Portal",
});
export const ScannerText = intl.formatMessage({ defaultMessage: "Scanner " });
export const StatusText = intl.formatMessage({ defaultMessage: "Status" });
export const WorkAnywhereText = intl.formatMessage({
  defaultMessage: "work anywhere",
});
export const EnterprisePortalText = intl.formatMessage({
  defaultMessage: "Switch for Business Portal",
});
export const UpcomingText = intl.formatMessage({ defaultMessage: "Upcoming" });
export const OngoingText = intl.formatMessage({ defaultMessage: "Ongoing" });
export const CompletedText = intl.formatMessage({
  defaultMessage: "Completed",
});
export const CancelledText = intl.formatMessage({
  defaultMessage: "Cancelled",
});
export const AwaitingPaymentText = intl.formatMessage({
  defaultMessage: "Awaiting payment",
});
export const ProcessingPaymentText = intl.formatMessage({
  defaultMessage: "Processing payment",
});
export const RefundedText = intl.formatMessage({ defaultMessage: "Refunded" });
export const PartiallyRefundedText = intl.formatMessage({
  defaultMessage: "Partially refunded",
});
export const BookedText = intl.formatMessage({
  defaultMessage: "Booked",
  description: "order status is booked",
});
export const ExpiredText = intl.formatMessage({
  defaultMessage: "Expired",
});
export const PaidText = intl.formatMessage({
  defaultMessage: "Paid",
});
export const QRcodeText = intl.formatMessage({
  defaultMessage: "QRcode",
});
export const RemoteText = intl.formatMessage({
  defaultMessage: "Remote",
});
export const AutoText = intl.formatMessage({
  defaultMessage: "Auto",
});
export const InstantBookingText = intl.formatMessage({
  defaultMessage: "Instant Booking",
});
export const ConfirmBookingText = intl.formatMessage({
  defaultMessage: "Confirm Booking",
});
export const InstantBookingOptionText = intl.formatMessage({
  defaultMessage: "Instant Confirm",
});
export const ConfirmBookingOptinText = intl.formatMessage({
  defaultMessage: "Non-instant Confirm",
});
export const PrivacyPolicyText = intl.formatMessage({
  defaultMessage: "Privacy Policy",
});
export const TermsOfServiceText = intl.formatMessage({
  defaultMessage: "Terms of Service",
});
export const ContactUsText = intl.formatMessage({
  defaultMessage: "Contact Us",
});
export const LinkedInText = intl.formatMessage({
  defaultMessage: "LinkedIn",
  description: "https://www.linkedin.com/",
});
export const CopyrightText = intl.formatMessage({
  defaultMessage: "Copyright © 2021 Switch. All Rights Reserved.",
});
