import request from "../request";

export const getSummary = async (
  _url: string,
  start: string,
  end: string,
  productTypes?: ProductType,
  city?: string
) => {
  return await request<TSumaryItem>({
    method: "GET",
    path: "/enterprise/v1/dashboard/summary",
    data: { start, end, productTypes, city },
    refreshOnUnauthorized: true,
  });
};

export const getDashboard = async (
  _url: string,
  page: number,
  size: number,
  productTypes: ProductType,
  start: string,
  end: string,
  city?: string
) => {
  return await request<TDashboardDetailResp>({
    method: "GET",
    path: "/enterprise/v1/dashboard/details",
    data: { page, size, productTypes, start, end, city },
    refreshOnUnauthorized: true,
  });
};
