import React from "react";
import * as S from "./styles.units";

import { UnitType, UnitTypeText } from "constants/products";

const DefaultOptions: UnitType[] = [UnitType.MINUTE, UnitType.HOUR];

export const useUnitSwitch = (v?: UnitType) => {
  const [unit, setUnit] = React.useState<UnitType | "">(v || "");
  const onUpdate = React.useCallback((v) => setUnit(v), []);
  return {
    unit,
    onUpdate,
  };
};

interface UnitSwitchProps {
  options?: string[];
  callback?: (v: string) => void;
  defaultValue?: UnitType | "";
  styles?: React.CSSProperties;
}

const UnitSwitch = React.memo(
  ({ options, callback, defaultValue, styles }: UnitSwitchProps) => {
    const ds = React.useMemo(() => options || DefaultOptions, [options]);
    const [active, setActive] = React.useState<UnitType | "">(
      defaultValue || ""
    );
    const onSetCur = React.useCallback(
      (v) => {
        if (callback) callback(v);
        setActive(v);
      },
      [callback]
    );
    return (
      <S.Wrapper style={styles}>
        {ds.map((v) => (
          <S.Button
            key={v}
            isCur={active === v}
            onClick={onSetCur.bind(null, v)}
          >
            {UnitTypeText[v]}
          </S.Button>
        ))}
      </S.Wrapper>
    );
  }
);

export default UnitSwitch;
