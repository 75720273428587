import React, { useState, useMemo, useEffect, useCallback } from "react";

import { useDetailLoader } from "./useDetailLoader";
import { useSummaryLoader } from "./useSummaryLoader";

import { useAccessToken } from "store/accessToken";

import { HeaderH4, HeaderH3, BodyRegularSpan, CaptionText } from "styles/fonts";
import { PrimaryCream } from "styles/colors";
import { ToolBar, FlexBlank } from "styles/common";
import * as S from "./style.dashboard";

import Table, { TablePaddingStyle } from "components/Table";
import RanderPicker from "components/RangePicker";
import UnitSwitch, { useUnitSwitch } from "components/UnitSwitch";
import ProductTypeSwitch, { useTypeSwitch } from "components/TypeSwitch";

import { DashboardColumns } from "constants/list";
import {
  UnitType,
  InventoryType,
  InventoryTypeName,
  UnitTypeText,
} from "constants/products";
import { getTimeStr, getDateRange } from "utils/date";
import { formatCount } from "utils/index";

import useListStore from "hooks/usePaginationsList";
import useStateValue from "hooks/useStateValue";
import { navigatorText, tableText } from "langs/formatText";

export default function Dashobard() {
  return <DataList />;
}

function DataList() {
  const { testInventoryAuth, accessToken } = useAccessToken();
  const defaultProductType = useMemo(() => {
    let result: InventoryType | "" = "";
    const types = [];
    if (testInventoryAuth(InventoryType.BOOTH)) {
      if (!result) result = InventoryType.BOOTH;
      types.push(InventoryType.BOOTH);
    }
    if (testInventoryAuth(InventoryType.DESK)) {
      if (!result) result = InventoryType.DESK;
      types.push(InventoryType.DESK);
    }
    if (testInventoryAuth(InventoryType.MEETING_ROOM)) {
      if (!result) result = InventoryType.MEETING_ROOM;
      types.push(InventoryType.MEETING_ROOM);
    }
    if (testInventoryAuth(InventoryType.PRIVATE_OFFICE)) {
      if (!result) result = InventoryType.PRIVATE_OFFICE;
      types.push(InventoryType.PRIVATE_OFFICE);
    }
    if (testInventoryAuth(InventoryType.EVENT_SPACE)) {
      if (!result) result = InventoryType.EVENT_SPACE;
      types.push(InventoryType.EVENT_SPACE);
    }
    return { type: result, options: types };
  }, [testInventoryAuth]);
  const defaultRange = useMemo(() => {
    const { todayStart, todayEnd } = getDateRange(new Date());
    return { start: getTimeStr(todayStart), end: getTimeStr(todayEnd) };
  }, []);
  const { onUpdate, unit } = useUnitSwitch(UnitType.MINUTE);
  const { type, onUpdateType } = useTypeSwitch<InventoryType | "">(
    defaultProductType.type
  );
  const [start, setStart] = useState<string>(defaultRange.start); // 2020-09-08T00:00:00Z
  const [end, setEnd] = useState<string>(defaultRange.end);
  const [pageSize, setPageSize] = useStateValue(50);
  const requestKey = useMemo(
    () => `${type}${start}${end}${accessToken?.accessToken}${pageSize}`,
    [accessToken, end, start, type, pageSize]
  );
  const { onSetPage, curPage, counts, setCounts } = useListStore(requestKey);
  const { data, isValidating, total } = useDetailLoader(
    curPage,
    pageSize,
    type,
    start,
    end,
    `${requestKey}${curPage}`
  );
  useEffect(() => {
    if (total !== counts && total !== 0) setCounts(total);
  }, [total, counts, setCounts]);
  const { data: summaryData } = useSummaryLoader(start, end, type, requestKey);
  const columns = useMemo(() => {
    const c: IColumns[] = [...DashboardColumns];
    c.forEach((v) => {
      if (v.hasUnit) v.headerDes = UnitTypeText[unit];
      if (v.hasCurrency && summaryData) v.headerDes = summaryData.currency;
    });
    return c;
  }, [summaryData, unit]);
  const onConfirmRange = useCallback((s: number, e: number) => {
    if (!s || !e) return;
    setStart(getTimeStr(s));
    setEnd(getTimeStr(e));
  }, []);
  return (
    <>
      <ToolBar>
        <HeaderH4>{navigatorText.OverviewText}</HeaderH4>
        <FlexBlank />
        <UnitSwitch callback={onUpdate} defaultValue={unit} />
      </ToolBar>
      <ToolBar style={{ paddingBottom: 8 }}>
        <RanderPicker
          onConfirmRange={onConfirmRange}
          defaultBtnBgColor={PrimaryCream}
        />
      </ToolBar>
      <TotalSummary start={start} end={end} unit={unit} />
      <ToolBar style={{ zIndex: 1, backgroundColor: "#FFF" }}>
        {!!defaultProductType.options.length && type && (
          <ProductTypeSwitch
            callback={onUpdateType}
            defaultValue={type}
            disabled={isValidating}
            options={defaultProductType.options}
            names={InventoryTypeName}
            buttonStyle={{ marginRight: 24 }}
          />
        )}
      </ToolBar>
      <Table
        columns={columns}
        ds={data}
        loading={!data.length && isValidating}
        curPage={curPage}
        onSetPage={onSetPage}
        summaryData={summaryData}
        total={counts}
        unitType={unit}
        rowKey="location"
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        wrapperStyle={TablePaddingStyle}
      />
    </>
  );
}

type TSummaryColumn = {
  des: string;
  key: string;
  render: (
    v: string | number,
    type?: UnitType | ""
  ) => string | number | JSX.Element;
  formatDes?: (type: UnitType | "", rowData?: TSumaryItem) => string;
};

const SummaryUnitText = {
  "": "",
  [UnitType.MINUTE]: tableText.minText,
  [UnitType.HOUR]: tableText.hrText,
};

const SummaryColumns: TSummaryColumn[] = [
  {
    des: tableText.TotalCostText,
    key: "cost",
    formatDes: (type, v) =>
      v?.currency
        ? `${tableText.TotalCostText} (${v.currency.toUpperCase()})`
        : tableText.TotalCostText,
    render: (v) => {
      let result = v ? `${formatCount(v, 2)}` : "0";
      if (!result) result = "0";
      const data = result.split(".");
      return (
        <S.TotalCostItem>
          <HeaderH3>{data[0]}</HeaderH3>
          {!!data[1] && (
            <BodyRegularSpan style={{ top: -1, position: "relative" }}>
              .{data[1]}
            </BodyRegularSpan>
          )}
        </S.TotalCostItem>
      );
    },
  },
  {
    des: tableText.TotalStayerText,
    key: "stayers",
    render: (v) => <HeaderH3>{`${formatCount(v, 0)}` || "-"}</HeaderH3>,
  },
  {
    des: tableText.OneTimeStayerText,
    key: "oneTimeStayers",
    render: (v) => (
      <HeaderH3>{v ? `${formatCount(v, 0)}` || "0" : "0"}</HeaderH3>
    ),
  },
  {
    des: tableText.RepeatStayerText,
    key: "repeatStayers",
    render: (v) => (
      <HeaderH3>{v ? `${formatCount(v, 0)}` || "0" : "0"}</HeaderH3>
    ),
  },
  {
    des: tableText.TotalUsageText,
    key: "totalUsageMinutes",
    formatDes: (type) =>
      `${tableText.TotalUsageText} (${SummaryUnitText[type]})`,
    render: (v, type) => {
      if (!v) return <HeaderH3>{"0"}</HeaderH3>;
      if (type === UnitType.HOUR) {
        return (
          <HeaderH3>{`${formatCount(parseFloat(`${v}`) / 60, 2)}`}</HeaderH3>
        );
      }
      return <HeaderH3>{`${formatCount(v, 2)}` || "0"}</HeaderH3>;
    },
  },
  {
    des: tableText.AvgUsageText,
    key: "avgUsageMinutes",
    formatDes: (type) =>
      `${tableText.AvgUsageStayText} (${SummaryUnitText[type]})`,
    render: (v, type) => {
      if (!v) return <HeaderH3>{"0"}</HeaderH3>;
      if (type === UnitType.HOUR)
        return (
          <HeaderH3>
            {`${formatCount(parseFloat(`${v}`) / 60, 2)}` || "0"}
          </HeaderH3>
        );
      return <HeaderH3>{`${formatCount(v, 2)}` || "0"}</HeaderH3>;
    },
  },
  {
    des: tableText.SeatTimeSavedText,
    key: "totalUsageMinutes",
    render: (v) => {
      if (!v) return <HeaderH3>{"0"}</HeaderH3>;
      const value = parseFloat(`${v}`) / 60 / 8;
      return <HeaderH3>{`${formatCount(value, 2)}` || "0"}</HeaderH3>;
    },
  },
];

interface TotalSummaryProps {
  start: string;
  end: string;
  unit: UnitType | "";
}

const TotalSummary = React.memo(({ start, end, unit }: TotalSummaryProps) => {
  const { data } = useSummaryLoader(
    start,
    end,
    undefined,
    `${start}${end}totalsummary`
  );
  return (
    <S.TotalSummaryWrapper>
      {SummaryColumns.slice(0, 4).map((c) => {
        return (
          <S.SummaryItem key={c.key + c.des} style={{ borderBottom: S.border }}>
            {data ? (
              c.render((data as any)[c.key] || "", unit || "")
            ) : (
              <HeaderH3>0</HeaderH3>
            )}
            <CaptionText style={{ marginTop: 4 }}>
              {c.formatDes ? c.formatDes(unit, data) : c.des}
            </CaptionText>
          </S.SummaryItem>
        );
      })}
      {SummaryColumns.slice(4, SummaryColumns.length).map((c) => {
        return (
          <S.SummaryItem key={c.key + c.des} style={{ borderBottom: S.border }}>
            {data ? (
              c.render((data as any)[c.key] || "", unit || "")
            ) : (
              <HeaderH3>0</HeaderH3>
            )}
            <CaptionText style={{ marginTop: 4 }}>
              {c.formatDes ? c.formatDes(unit, data) : c.des}
            </CaptionText>
          </S.SummaryItem>
        );
      })}
    </S.TotalSummaryWrapper>
  );
});
