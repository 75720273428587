// export const PhoneReg = new RegExp("^+[1-9]d{1,14}$");

export const SpicalCharacterReg = new RegExp("^[^!！^]+$"); // ！/!/^
export const NormalSpecialCharacterReg = new RegExp(
  "^[^*|\":<>[\\]{}`\\';@&$]+$"
);
// eslint-disable-next-line no-control-regex
export const EmailReg = new RegExp("[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+"); // [^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+
export const isFloatReg = new RegExp("[+-]?([0-9]*[.])?[0-9]+");
export const PromoCodeReg = /^[0-9a-zA-Z]+$/;
