import React, { useCallback } from "react";

import * as S from "./styles.table";
import { LightCream, Neutral4 } from "styles/colors";
import Explanation from "components/Explanation";

interface TableHeaderProps {
  columns: IColumns[];
  width: number | string;
  hasScrollBar?: boolean;
  summaryData?: TSumaryItem;
  unitType?: UnitType | "";
  isScrollRightBottom?: boolean;
  hasData?: boolean;
  isHorizontalScroll: boolean;
}

export const HeaderComponent = ({
  columns,
  width,
  summaryData,
  unitType,
  isScrollRightBottom,
  hasData,
  isHorizontalScroll,
}: TableHeaderProps) => {
  const getStyle = useCallback(
    (v: IColumns) => {
      const isSticky = v.style?.position === "sticky" && isHorizontalScroll;
      return {
        ...(v.style || {}),
        width: v.width || "auto",
        minWidth: v.minWidth || "initial",
        height: v.titleComponent || isSticky ? "100%" : "auto",
        backgroundColor: isSticky ? LightCream : undefined,
        borderBottom:
          isSticky && !isScrollRightBottom && hasData
            ? `1px solid ${Neutral4}`
            : undefined,
        boxShadow:
          isSticky && !isScrollRightBottom && hasData
            ? S.StickyHeaderShadow
            : undefined,
      };
    },
    [isHorizontalScroll, isScrollRightBottom, hasData]
  );
  const geySummaryValue = useCallback(
    (v: IColumns) => {
      if (v.summaryTitle) return v.summaryTitle;
      if (!summaryData) return "-";
      let result = (summaryData as any)[v.summaryKey || v.key];
      if (v.format) {
        result = v.format(result, unitType);
      }
      return result || "0";
    },
    [summaryData, unitType]
  );
  return (
    <>
      <S.Header
        style={{
          width,
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          borderTop: "none",
          borderLeft: "none",
        }}
        sticky={true}
      >
        {columns.map((v) => (
          <S.TableHeaderItem
            key={v.key}
            style={getStyle(v)}
            flex={v.flex || (v.width ? "none" : 1)}
            align={v.align || "left"}
          >
            {!v.titleComponent && v.title}
            {!!v.titleComponent && (
              <v.titleComponent
                text={v.title}
                callback={v.titleAction}
                defaultSort={v.defaultSort}
              />
            )}
            {!!v.explanation && (
              <Explanation
                text={v.explanation}
                style={v.explanationStyle}
                wrapperStyle={{ marginLeft: 4 }}
              />
            )}
            {!!v.headerDes && <S.HeaderDes>{v.headerDes}</S.HeaderDes>}
          </S.TableHeaderItem>
        ))}
      </S.Header>
      {!!summaryData && (
        <S.Header
          style={{ width, borderTop: "none", top: S.HeaderHeight - 1 }}
          sticky={true}
        >
          {columns.map((v) => (
            <S.TableHeaderItem
              key={v.key}
              style={{ ...getStyle(v), userSelect: "text" }}
              flex={v.flex || (v.width ? "none" : 1)}
              align={v.align || "left"}
              bold={!v.summaryTitle}
            >
              {geySummaryValue(v)}
            </S.TableHeaderItem>
          ))}
        </S.Header>
      )}
    </>
  );
};

export const Header = React.memo(HeaderComponent);
