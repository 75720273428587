export const Orange = "#F55523";
export const DarkestOrange = "#943500";
export const DarkerOrange = "#BF4713";
export const LightOrange = "#F46E4A";
export const LighterOrange = "#FF9A80";
export const LightestOrange = "#FFD5C4";
export const LightestOrange2 = "#FFF2EC";
export const Cream = "#FCF9F5";
export const LightCream = "#FEFCFA";
export const Green = "#00C853";

export const Blue = "#0B28BD";
export const DarkestBlue = "#05124C";
export const DarkerBlue = "#06317C";
export const LighterBlue = "#7990F2";
export const LightBlue = "#3961E2";
export const LightestBlue = "#C2D2F2";

export const Black = "#000";
export const Black14 = "#141414";
export const Black1F = "#1F1F1F";
export const Black2C = "#2C2C2C";
export const Black43 = "#434343";
export const Black59 = "#595959";
export const Black8C = "#8C8C8C";
export const BlackAlpha87 = "rgba(0, 0, 0, 0.87)";
export const BlackAlpha08 = "rgba(0, 0, 0, 0.08)";
export const BlackAlpha45 = "rgba(0, 0, 0, 0.45)";
export const BlackAlpha54 = "rgba(0, 0, 0, 0.54)";

export const Gray = "#BFBFBF";
export const GrayF0 = "#F0F0F0";
export const GrayF5 = "#F5F5F5";
export const GrayD9 = "#D9D9D9";
export const GrayFA = "#FAFAFA";
export const White = "#FFF";

export const Error = "#FF3B30";
export const Error1 = "#FFF0EF";
export const Success = "#1DD1A1";
export const Success1 = "#EDFCF8";
export const Warning = "#FFCC00";
export const Warning1 = "#FFFBEB";
export const Link = "#007AFF";
export const Link1 = "#F0F6FF";

export const None = "transparent";

export const PrimaryColor4 = "#FFD5C3";

export const Primary = Orange;
export const PrimaryDarkest = DarkestOrange;
export const PrimaryDarker = DarkerOrange;
export const PrimaryLight = LightOrange;
export const PrimaryLighter = LighterOrange;
export const PrimaryLightest = LightestOrange;
export const PrimaryLightest2 = LightestOrange2;
export const PrimaryCream = Cream;
export const PrimaryCream2 = LightCream;

export const Secondary = Blue;
export const SecondaryDarkest = DarkestBlue;
export const SecondaryDarker = DarkerBlue;
export const SecondaryLight = LightBlue;
export const SecondaryLighter = LighterBlue;
export const SecondaryLighter2 = "#E7EDFF";
export const SecondaryLightest = LightestBlue;

export const Neutral = Black;
export const Neutral13 = Black;
export const Neutral12 = Black14;
export const Neutral11 = Black1F;
export const Neutral10 = Black2C;
export const Neutral9 = Black43;
export const Neutral8 = Black59;
export const Neutral7 = Black8C;
export const Neutral6 = Gray;
export const Neutral5 = GrayD9;
export const Neutral4 = GrayF0;
export const Neutral3 = GrayF5;
export const Neutral2 = GrayFA;
export const Neutral1 = White;

export const TextHeading = "#0D0D0D";
export const TextBody = "#404040";
