import React from "react";
import { Dropdown as ADropdown, DropDownProps as ADropdownProps } from "antd";
import useControl from "hooks/useControl";
import * as S from "./index.style";

export type DropdownProps = Omit<ADropdownProps, "overlay"> & {
  children: React.ReactNode;
  content: (() => React.ReactNode) | React.ReactNode;
  overlayStyle?: React.CSSProperties;
  noShadow?: boolean;
};
export default function Dropdown(props: DropdownProps) {
  const { content, children, visible, onVisibleChange, ...rest } = props;
  const [value, serValue] = useControl<boolean>({
    value: visible,
    defaultValue: visible || false,
    onChange: onVisibleChange,
  });

  return (
    <ADropdown
      visible={value}
      onVisibleChange={(v) => serValue(v)}
      overlayStyle={props.overlayStyle}
      overlay={
        <S.DropdownOverlay noShadow={props.noShadow}>
          {content}
        </S.DropdownOverlay>
      }
      {...rest}
    >
      {children}
    </ADropdown>
  );
}
