import intl from "langs/intl";

export const NewEmployeeText = intl.formatMessage({
  defaultMessage: "New Employee",
  description: "create new employee",
});
export const InactiveEmployeeText = intl.formatMessage({
  defaultMessage: "Inactive Users",
  description: "title",
});
export const InactiveUsersText = intl.formatMessage({
  defaultMessage: "Inactive users",
});
export const AddManuallyText = intl.formatMessage({
  defaultMessage: "Add Manually",
});
export const ImportFromCSVText = intl.formatMessage({
  defaultMessage: "Import From CSV",
});
export const NoneEmployeeText = intl.formatMessage({
  defaultMessage: "No employees have been added.",
});
export const DownloadCSVTemplateText = intl.formatMessage({
  defaultMessage: "Download CSV Template",
});
export const EmployeeSearchPlaceholderText = intl.formatMessage({
  defaultMessage: "Name, Email, Employee ID",
});
export const ResendInvitationToAllText = intl.formatMessage({
  defaultMessage: "Resend Invitation To All",
});
export const ResendInvitationText = intl.formatMessage({
  defaultMessage: "Resend Invitation",
});
export const ResendInvitationEmailText = intl.formatMessage({
  defaultMessage: "Resend Invitation Email",
});
export const ResendInvitationTipText = intl.formatMessage({
  defaultMessage:
    "We’ll send new invitations to all your pending employess by email.",
});
export const ResendInvitationSuccessText = intl.formatMessage({
  defaultMessage: "Invitation email sent.",
});
export const DeactivateEmployeeText = intl.formatMessage({
  defaultMessage: "Deactivate employee?",
});
export const DownloadTemplateText = intl.formatMessage({
  defaultMessage: "Download template",
});
export const NoResultFoundText = intl.formatMessage({
  defaultMessage: "No result found.",
});
export const PleaseModifyfilterText = intl.formatMessage({
  defaultMessage: "Please modify the filter or the search for more results",
});
export const DeactivateEmployeeTip = intl.formatMessage({
  defaultMessage: "Employee will be inactive from using this business account.",
});
export const EmployeeWorkEmailTip = intl.formatMessage({
  defaultMessage:
    "Once added, a verification email will automatically be sent to the employee. ",
});
