import { dateText } from "langs/formatText";

export enum DateOptionsType {
  TODAY = "today",
  YESTERDAY = "yesterday",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
  OTHER = "other",
  DAYS_SEVEN = "days_7",
  DAYS_THIRTY = "days_30",
  DAYS_NINTY = "days_90",
}

export const DateOptions = [
  { label: dateText.TodayText, value: DateOptionsType.TODAY },
  { label: `7 ${dateText.DaysText}`, value: DateOptionsType.DAYS_SEVEN },
  { label: `30 ${dateText.DaysText}`, value: DateOptionsType.DAYS_THIRTY },
  { label: dateText.OtherText, value: DateOptionsType.OTHER },
];

export const SinceDateOptions = [
  { label: dateText.TodayText, value: DateOptionsType.TODAY },
  { label: `7 ${dateText.DaysText}`, value: DateOptionsType.DAYS_SEVEN },
  { label: `30 ${dateText.DaysText}`, value: DateOptionsType.DAYS_THIRTY },
];

export const RangeDateOptions = [
  { label: dateText.TodayText, value: DateOptionsType.TODAY },
  { label: `7 ${dateText.DaysText}`, value: DateOptionsType.DAYS_SEVEN },
  { label: `30 ${dateText.DaysText}`, value: DateOptionsType.DAYS_THIRTY },
  { label: `90 ${dateText.DaysText}`, value: DateOptionsType.DAYS_NINTY },
];
