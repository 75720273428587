import styled, { css } from "styled-components";
import { FlexRow, FlexCenter } from "styles/common";
import { Neutral6, Neutral8, Neutral4, Neutral3, Primary } from "styles/colors";
import type { ButtonProps } from "../styles.button";

import { ReactComponent as DownloadSVG } from "images/icon-download.svg";

function getBGColor(props: ButtonProps, hover?: boolean) {
  return props.defaultBgColor || "#FFF";
}

function getFontColor(props: ButtonProps, hover?: boolean) {
  if (props.disabled) return Neutral6;
  if (props.active && props.isActivePrimary && !props.isTable) return Primary;
  if (hover && props.isActivePrimary && !props.isTable) return Primary;
  return Neutral8;
}

function getBorder(props: ButtonProps, hover?: boolean) {
  if (props.disabled) return `1px solid ${Neutral4}`;
  if (props.active && props.isActivePrimary && !props.isTable)
    return `1px solid ${Primary}`;
  if (hover && props.isActivePrimary && !props.isTable)
    return `1px solid ${Primary}`;
  if (hover && !props.isTable) return `1px solid ${Neutral3}`;
  return `1px solid ${Neutral4}`;
}

const ButtonEffect = css<ButtonProps>`
  box-sizing: border-box;
  border-radius: 2px;
  user-select: none;
  outline: none;
  flex: none;
  color: ${(p) => getFontColor(p)};
  background-color: ${(p) => getBGColor(p)};
  border: ${(p) => getBorder(p)};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  transition: all 0.2s ease;

  svg {
    fill: ${(p) => getFontColor(p)};
    color: ${(p) => getFontColor(p)};
  }

  &:hover {
    color: ${(p) => getFontColor(p, true)};
    background-color: ${(p) => getBGColor(p, true)};
    border: ${(p) => getBorder(p, true)};

    svg {
      fill: ${(p) => getFontColor(p, true)};
      color: ${(p) => getFontColor(p, true)};
    }
  }
`;

export const ButtonWrapper = styled.button<ButtonProps>`
  padding: 8px;
  ${FlexRow}
  ${FlexCenter}
  ${ButtonEffect}
  min-width: 72px;
  border: ${(p) => getBorder(p)};

  &:focus {
    outline: none;
  }
`;

export const IconDownload = styled(DownloadSVG)`
  width: 16px;
  height: 16px;
  fill: ${Neutral8};
  transition: fill 0.2s ease;
`;
