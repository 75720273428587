import styled from "styled-components";
import {
  Neutral5,
  Neutral8,
  Primary,
  PrimaryDarker,
  Neutral2,
  Neutral3,
  Neutral4,
  None,
} from "styles/colors";
import { Subtitle } from "styles/fonts";

import { ReactComponent as DownloadSVG } from "images/icon-download.svg";

interface ButtonProps {
  disabled?: boolean;
  pressed?: string;
  secondary?: boolean;
  defaultBgColor?: string;
}

function getBGColor(props: ButtonProps, hover?: boolean) {
  if (props.secondary) {
    if (props.disabled) return props.defaultBgColor || "#fff";
    if (props.pressed) return Neutral4;
    if (hover) return Neutral3;
    return props.defaultBgColor || "#fff";
  }
  if (props.disabled) return Neutral2;
  if (props.pressed || hover) return PrimaryDarker;
  return Primary;
}

function getFontColor(props: ButtonProps) {
  if (props.secondary) {
    if (props.disabled) return Neutral5;
    return Neutral8;
  }
  if (props.disabled) return Neutral5;
  return "#fff";
}

export const ButtonWrapper = styled.button`
  box-sizing: border-box;
  padding: 0 16.5px;
  width: 100%;
  min-width: 72px;
  height: 48px;
  line-height: 16px;
  border-radius: 2px;
  outline: none;
  display: flex;
  flex: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: ${(p: ButtonProps) => getFontColor(p)};
  background-color: ${(p: ButtonProps) => getBGColor(p)};
  border: 1px solid ${(p: ButtonProps) => (p.secondary ? Neutral4 : None)};
  cursor: ${(p: ButtonProps) => (p.disabled ? "not-allowed" : "pointer")};
  transition: all 0.2s ease;
  ${Subtitle}

  svg {
    fill: ${(p: ButtonProps) => getFontColor(p)};
  }

  &:hover {
    color: ${(p: ButtonProps) => getFontColor(p)};
    background-color: ${(p: ButtonProps) => getBGColor(p, true)};
  }

  &:focus {
    outline: none;
  }
`;

export const IconDownload = styled(DownloadSVG)`
  width: 16px;
  height: 16px;
  fill: ${Neutral8};
  transition: fill 0.2s ease;
`;
