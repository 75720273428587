import { dateText, productText, formText } from "langs/formatText";

export enum ProductType {
  ALL = "",
  POD = "POD",
  COWORKING = "COWORKING",
  DESK = "DESK",
}

export enum SpaceRouteType {
  OVERVIEW = "Overview",
  INVENTORY = "Inventory",
}

export enum UnitType {
  MINUTE = "Minute",
  HOUR = "Hour",
}

export const UnitTypeText: { [key: string]: string } = {
  [UnitType.MINUTE]: dateText.MinuteText,
  [UnitType.HOUR]: dateText.HourText,
};

export enum SortType {
  NONE = "",
  ASC = "asc",
  DESC = "desc",
}

export enum InventoryType {
  DESK = "DESK",
  BOOTH = "POD",
  MEETING_ROOM = "MEETING_ROOM",
  PRIVATE_OFFICE = "PRIVATE_OFFICE",
  EVENT_SPACE = "EVENT_SPACE",
}

export enum DeskSubType {
  HOTDESK = "HOT_DESK",
  DEDICATED_DESK = "DEDICATED_DESK",
}

export enum PodSubType {
  PAX_1 = "1PAX",
  PAX_4 = "4PAX",
}

export const InventoryTypesArr = [
  InventoryType.BOOTH,
  InventoryType.DESK,
  InventoryType.MEETING_ROOM,
  InventoryType.PRIVATE_OFFICE,
  InventoryType.EVENT_SPACE,
];

export const InventoryTypeName = {
  [InventoryType.DESK]: productText.DeskText,
  [InventoryType.BOOTH]: productText.BoothText,
  [InventoryType.MEETING_ROOM]: productText.MeetingRoomText,
  [InventoryType.PRIVATE_OFFICE]: productText.PrivateOfficeText,
  [InventoryType.EVENT_SPACE]: productText.EventSpaceText,
};
export const ProductTypeName = {
  [InventoryType.DESK]: productText.CoworkingText,
  COWORKING: productText.CoworkingText,
  [InventoryType.BOOTH]: productText.BoothText,
  [InventoryType.MEETING_ROOM]: productText.MeetingRoomText,
  [InventoryType.PRIVATE_OFFICE]: productText.PrivateOfficeText,
  [InventoryType.EVENT_SPACE]: productText.EventSpaceText,
};

export const InventorySubTypeName = {
  [DeskSubType.HOTDESK]: productText.HotDeskText,
  [DeskSubType.DEDICATED_DESK]: productText.DedicatedDeskText,
  [PodSubType.PAX_1]: productText.SeatBoothText(1),
  [PodSubType.PAX_4]: productText.SeatBoothText(4),
};

export enum SpacePublishType {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export const SpacePublishText = {
  [SpacePublishType.PUBLISHED]: productText.PublishedText,
  [SpacePublishType.UNPUBLISHED]: productText.UnpublishedText,
};

export enum SpaceStatusType {
  OPEN = "OPEN",
  CLOSE = "CLOSED",
}

export const SpaceStatusText = {
  [SpaceStatusType.OPEN]: productText.OpenText,
  [SpaceStatusType.CLOSE]: formText.SpaceClosedText,
};

export enum InventoryPriceType {
  WALK_IN = "WALK_IN",
  BOOKING = "BOOKING",
}

export enum InventoryPricingUnit {
  PT1M = "PT1M",
}

export enum CapacityType {
  ONE_SEAT = "ONE_SEAT",
  TWO_TO_FOUR_SEAT = "TWO_TO_FOUR_SEAT",
  FIVE_TO_NINE_SEAT = "FIVE_TO_NINE_SEAT",
  TEN_TO_FIFTEEN_SEAT = "TEN_TO_FIFTEEN_SEAT",
  SIXTEEN_TO_TWENTY_SEAT = "SIXTEEN_TO_TWENTY_SEAT",
  OVER_TWENTY_SEAT = "OVER_TWENTY_SEAT",
}

export const CapacityTypeText = {
  [CapacityType.ONE_SEAT]: productText.CapacityTypeText(1, 0),
  [CapacityType.TWO_TO_FOUR_SEAT]: productText.CapacityTypeText(2, 4),
  [CapacityType.FIVE_TO_NINE_SEAT]: productText.CapacityTypeText(5, 9),
  [CapacityType.TEN_TO_FIFTEEN_SEAT]: productText.CapacityTypeText(10, 15),
  [CapacityType.SIXTEEN_TO_TWENTY_SEAT]: productText.CapacityTypeText(16, 20),
  [CapacityType.OVER_TWENTY_SEAT]: productText.CapacityTypeText(20, -1),
};

export const CapacityTypeValue: {
  [keys: string]: { gte?: number; lte?: number };
} = {
  [CapacityType.ONE_SEAT]: { gte: 1, lte: 1 },
  [CapacityType.TWO_TO_FOUR_SEAT]: { gte: 2, lte: 4 },
  [CapacityType.FIVE_TO_NINE_SEAT]: { gte: 5, lte: 9 },
  [CapacityType.TEN_TO_FIFTEEN_SEAT]: { gte: 10, lte: 15 },
  [CapacityType.SIXTEEN_TO_TWENTY_SEAT]: { gte: 16, lte: 20 },
  [CapacityType.OVER_TWENTY_SEAT]: { gte: 20 },
};

export const MaxDesLength = 700;
export const MaxContent = 100;
export const MaxNameLength = 50;

export enum OrderSource {
  APP = "SWITCH_APP",
  PORTAL = "SPACE_PROVIDER_PORTAL",
}

export const BookingOrderSourceLabel = {
  [OrderSource.APP]: productText.SwitchAppText,
  [OrderSource.PORTAL]: productText.SwitchPortalText,
};

export enum BookingType {
  INSTANT_BOOKING = "INSTANT_BOOKING",
  CONFIRM_BOOKING = "CONFIRM_BOOKING",
}

export const BookingTypeLabel = {
  [BookingType.INSTANT_BOOKING]: productText.InstantBookingText,
  [BookingType.CONFIRM_BOOKING]: productText.ConfirmBookingText,
};

export const BookingTypeOptionLabel = {
  [BookingType.INSTANT_BOOKING]: productText.InstantBookingOptionText,
  [BookingType.CONFIRM_BOOKING]: productText.ConfirmBookingOptinText,
};

export const BookingTypeOptions = [
  {
    key: BookingType.INSTANT_BOOKING,
    text: BookingTypeOptionLabel[BookingType.INSTANT_BOOKING],
  },
  {
    key: BookingType.CONFIRM_BOOKING,
    text: BookingTypeOptionLabel[BookingType.CONFIRM_BOOKING],
  },
];

export enum BoothType {
  BOOKING = "INSTANT_BOOKING",
  WALKIN = "WALK_IN",
}

export const BoothTypeOptions = [
  {
    key: BoothType.WALKIN,
    text: productText.WalkInText,
  },
  {
    key: BoothType.BOOKING,
    text: productText.InstantBookingText,
  },
];

export enum BoothWifiType {
  RUCKUS = "Ruckus",
  SUNDRAY = "Sundray",
}
