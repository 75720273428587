import { createGlobalStyle, css } from "styled-components";
import { Roboto } from "styles/fonts";
import {
  Primary,
  Success1,
  Error1,
  Link1,
  Warning1,
  Neutral2,
  Neutral4,
  None,
  Error,
} from "styles/colors";
import { LeftSiderWidth } from "components/PortalLayout/style.PortalLayout";
import { testMobile } from "utils/os";

const isMobile = testMobile();

const GlobalStyle = createGlobalStyle`
  h3 {
    margin: 0;
    padding: 0;
  }

  p {
    margin-bottom: 0;
    margin-top: 0;
  }

  textarea,
  input {
    @media only screen and (max-width: 800px) {
      appearance: none;
    }
  }

  .admin-left-slider .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .portal-notification {
    .ant-message-notice-content {
      padding: 11px 18px;
      max-width: 60vw;
    }

    &.success {
      .ant-message-notice-content {
        background-color: ${Success1};
      }
    }

    &.error {
      .ant-message-notice-content {
        background-color: ${Error1};
      }
    }

    &.warn {
      .ant-message-notice-content {
        background-color: ${Warning1};
      }
    }

    &.info {
      .ant-message-notice-content {
        background-color: ${Link1};
      }
    }

    .ant-notification-notice-message {
      margin-bottom: 0;
      padding-right: 0 !important;
    }

    .ant-notification-notice-close {
      top: 8px;
      right: 32px;
    }
  }

  html {
    --antd-wave-shadow-color: ${Primary};

    ${Roboto}
  }

  /* The StripeElement class is applied to the Element container by default. More info: https://stripe.com/docs/stripe-js/reference#element-options */

  .StripeElement,
  .StripeElementIdeal,
  .StripeElementP24,
  .StripeElementEPS,
  .StripeElementFpx {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    display: block;
    margin: 0;
    max-width: 500px;
    padding: 14px 16px;
    box-sizing: border-box;
    height: 48px;
    border-radius: 2px;
    background-color: ${Neutral2};
    border: 1px solid transparent;

    &.portal-form-element {
      background-color: #fff;
      border: 1px solid ${Neutral4};
      height: 32px;
      padding: 7px 8px;
    }
    &.errorBorder {
      border: 1px solid ${Error};
    }
  }

  #cardNumber {
    &:not(.portal-form-element) {
      padding-left: 56px;
    }
  }

 
  .StripeElement--focus,
  .StripeElementIdeal--focus,
  .StripeElementP24--focus,
  .StripeElementEPS--focus,
  .StripeElementFpx--focus {
    border: 1px solid ${None};
    transition: all 150ms ease;

    &.portal-form-element {
      border: 1px solid ${Primary};
    }
  }

  .StripeElement.loading {
    height: 41.6px;
    opacity: 0.6;
  }

  .StripeElementIdeal,
  .StripeElementP24,
  .StripeElementEPS,
  .StripeElementFpx {
    padding: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
    @media only screen and (max-width: 800px) {
      appearance: none;
    }
  }

  [class^="ant-"] {
    ${Roboto}
  }

  /* fix baidu map autocomplete dropdown */
  .tangram-suggestion-main {
    z-index: 10;
  }


  .commonMessage {
    ${
      !isMobile
        ? css`
            margin-left: ${LeftSiderWidth}px;
          `
        : ""
    }
  }
`;

export default GlobalStyle;
