import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

interface LoadingProps {
  loading: boolean;
  style?: React.CSSProperties;
}

const Loading = React.memo(({ loading, style }: LoadingProps) => {
  if (!loading) return null;
  return (
    <Wrapper style={style}>
      <LoadingOutlined style={{ fontSize: 30 }} />
    </Wrapper>
  );
});

export const LoadingIcon = LoadingOutlined;

export default Loading;

export const Wrapper = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;
