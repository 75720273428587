import styled from "styled-components";
import { ReactComponent as IconCalendarSVG } from "images/icon-calendar.svg";
import * as C from "styles/colors";
import { RobotoBold, Roboto, Body2CSS } from "styles/fonts";
import { FlexCenter } from "styles/common";
import { ReactComponent as IconCheckedSVG } from "images/icon-checked-outlined.svg";
import { ReactComponent as ArrowSVG } from "images/icon-arrow-down-btn.svg";

const BoxShadow =
  "0 2px 3px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.05), 6px 10px 10px rgba(0, 0, 0, 0.03)";

export const Wrapper = styled.div`
  height: 32px;
  border-radius: 2px;
  position: relative;
  z-index: 3;
  user-select: none;
`;

interface PickerBtnProps {
  pressed: boolean;
  defaultBgColor?: string;
  defaultBorderColor?: string;
}

export const PickerButton = styled(Wrapper)<PickerBtnProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
  cursor: pointer;
  color: ${C.Neutral13};
  border-color: ${(p) => {
    return p.defaultBorderColor || C.Neutral5;
  }};
  border-width: 1px;
  border-style: solid;
  background-color: ${({ defaultBgColor }) => defaultBgColor || "#fff"};
  transition: all 0.2s ease;
  margin-right: 8px;

  svg {
    fill: ${C.Neutral13};
    color: ${C.Neutral13};
  }

  &:hover {
    border-color: ${C.Neutral6};
    background-color: ${C.Neutral2};
  }
`;

export const IconCalendar = styled(IconCalendarSVG)`
  width: 20px;
  height: 20px;
  fill: ${C.Neutral8};
  margin-right: 8px;
`;

export const Selections = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  top: 36px;
  background-color: #fff;
  box-sizing: border-box;
  user-select: none;
  border-radius: 2px;
  border: 1px solid ${C.Neutral4};
  box-shadow: ${BoxShadow};
  transition: all 0.2s ease;
  opacity: ${(p: { isShow: boolean }) => (p.isShow ? 1 : 0)};
  transform: translateY(${(p: { isShow: boolean }) => (p.isShow ? 0 : "-20%")});
  pointer-events: ${(p: { isShow: boolean }) => (p.isShow ? "auto" : "none")};
`;

export const SelectionsWrapper = styled.div`
  width: 180px;
  padding: 12px 0 0 0;
  box-sizing: border-box;
`;

interface OptionProps {
  isActive?: boolean;
  isCustom?: boolean;
}

export const Option = styled.div<OptionProps>`
  width: 100%;
  height: 36px;
  color: ${(p) => (p.isActive ? C.Primary : C.Neutral13)};
  background-color: ${(p) => (p.isActive ? C.PrimaryLightest2 : "#fff")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(p) => (p.isCustom ? "space-between" : "flex-start")};
  box-sizing: border-box;
  padding: 0 32px 0 16px;
  margin: 0 0 8px 0;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background-color: ${(p) => (p.isActive ? C.PrimaryLightest2 : C.Neutral2)};
  }

  /* &::after {
    content: ">";
    display: ${(p) => (p.isCustom ? "block" : "none")};
    transform: scale(0.6, 1);
  } */
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${C.Neutral4};
  margin: 12px 0;
`;

export const CalendarWrapper = styled.div`
  flex: 1;
  box-sizing: border-box;
  border-left: 1px solid ${C.Neutral4};
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .ant-checkbox .ant-checkbox-inner {
    border-color: ${C.Neutral8};
  }

  .ant-checkbox-checked::after {
    border-color: ${C.Primary};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${C.Primary};
    border-color: ${C.Primary};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${C.Primary};
  }

  .dc-days-container {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .dc-cell-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex: 1 0 12.2857%;
    border-radius: 2px;
    height: 40px;
    color: ${C.Neutral};
    user-select: none;
    ${RobotoBold}
  }

  .dc-weekday-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 2px;
    flex: 1 0 12.2857%;
    height: 40px;
    color: ${C.Neutral7};
    user-select: none;
    ${Roboto}
    font-size: 12px;
    position: relative;
    margin-bottom: 8px;
    margin-left: 4px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: -4px;
      bottom: 0;
      right: 0;
      height: 1px;
      background-color: ${C.Neutral4};
    }

    &:nth-child(7n + 1) {
      margin-left: 0;
      &::after {
        left: 0;
      }
    }
  }

  .dc-date-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex: 1 0 12.2857%;
    height: 40px;
    color: ${C.Neutral};
    user-select: none;
    ${Roboto}
    cursor: pointer;
    border: 1px solid ${C.None};
    margin-bottom: 4px;
    margin-left: 4px;
    font-size: 14px;
    font-weight: 400;
    position: relative;

    &:nth-child(7n + 1) {
      margin-left: 0;
    }

    &.dc-date-item-today {
      color: ${C.Primary};

      &::after {
        content: "";
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${C.Primary};
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: 10px;
        margin-left: -2px;
      }
    }

    &.day:hover {
      border: 1px solid ${C.Neutral6};
    }

    &.range:hover {
      color: ${C.Primary};
      background-color: ${C.PrimaryLightest2};
    }

    &.dc-date-item-disabled {
      color: ${C.Neutral5};
      background-color: #fff;
      cursor: not-allowed;
      &:hover {
        border: 1px solid ${C.None};
      }
    }

    &.dc-date-item-hovered {
      color: ${C.Primary};
      background-color: ${C.PrimaryLightest2};
    }

    &.dc-date-item-selected {
      color: #fff;
      background-color: ${C.Primary};

      &:hover {
        border: 1px solid ${C.Primary};
      }

      &.dc-date-item-today {
        &::after {
          background-color: #fff;
        }
      }

      &.range {
        color: ${C.Primary};
        background-color: ${C.PrimaryLightest2};
        &.dc-date-item-today {
          &::after {
            background-color: ${C.Primary};
          }
        }
      }

      &.day {
        border-radius: 2px;
      }

      &.highlight-start {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      &.highlight-end {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

    &.highlight {
      background-color: ${C.PrimaryLightest};
      border-radius: 2px;
    }

    &.range.highlight-start {
      background-color: ${C.PrimaryLightest};
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    &.range.highlight-end {
      background-color: ${C.PrimaryLightest};
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  .dn-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .dn-title {
    ${RobotoBold}
    letter-spacing: 0.01em;
    font-size: 16px;
    line-height: 1.5;
    color: ${C.Neutral};
    user-select: none;
  }

  .dn-buttons-container {
    display: flex;
    flex-direction: row;
    width: 53px;
    justify-content: space-between;
  }

  .dn-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .dn-btn-to-today {
    ${Body2CSS}
    color: ${C.Primary};
    cursor: pointer;
  }

  .dn-arrow-button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.dn-arrow-button-disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    & > img {
      display: block;
      user-select: none;
    }
  }

  .dpp-picker-container {
    margin-top: 24px;
    min-height: 280px;
  }

  .dpt-button-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  .dpt-type-button {
    outline: none;
    text-align: center;
    flex: 1 0 20%;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
    color: #535353;
    padding: 6px 0;
    border: 1px solid #535353;
    border-right: 0;
    background-color: #fff;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid #535353;
    }

    &:hover,
    &.dpt-type-button-active {
      background-color: ${C.Primary};
      color: #fff;
      border-color: ${C.Primary};
    }
  }

  .dp-panel-wrapper {
    padding: 24px;
    width: 370px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    overflow: hidden;
  }

  .dp-trigger-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .dp-trigger-text {
    color: ${C.Neutral};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    margin-left: 8px;
  }

  .dp-trigger-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      display: block;
    }
  }

  .mp-months-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 26px;
    padding-left: 10px;
    flex: 1;
    max-height: 300px;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: flex-start;
    box-sizing: border-box;
    border-top: 1px solid ${C.Neutral4};
    border-bottom: 1px solid ${C.Neutral4};
  }

  .yp-years-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    border-top: 1px solid ${C.Neutral4};
    padding-top: 18px;
  }

  .mp-month-item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;

    &.mp-month-item-wrapper-disabled {
      cursor: not-allowed;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .ant-checkbox-wrapper {
      ${FlexCenter}
    }

    .ant-checkbox {
      top: 0;
    }
  }

  .mp-month-text-container {
    font-size: 14px;
    line-height: 1.4;
    margin-left: 15px;
    font-family: "Roboto", sans-serif;
    color: ${C.Neutral};
  }

  .rp-from-to-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .rp-from-to-container {
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(50% - 8px);
  }

  .rp-date-label {
    ${Roboto}
    font-size: 12px;
    letter-spacing: 0.01em;
    color: ${C.Neutral8};
    margin-bottom: 6px;
  }

  .rp-date-value {
    background: #fff;
    border: 1px solid #c9ced6;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 0 12px;
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.4;
    ${Roboto}
    color: ${C.Neutral};
  }

  .yp-year-item {
    width: 96px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
    ${Roboto}
    color: ${C.Neutral};
    border-radius: 2px;
    border: 1px solid ${C.None};
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;

    &:hover {
      border: 1px solid ${C.Neutral6};
    }

    &.yp-year-active {
      background-color: ${C.PrimaryLightest};
      color: ${C.Primary};
      &:hover {
        border: 1px solid ${C.Primary};
      }
    }

    &.yp-year-disabled {
      color: ${C.Neutral5};
      cursor: not-allowed;
      &:hover {
        border: 1px solid ${C.None};
      }
    }

    &.cur-year {
      color: ${C.Primary};

      &::after {
        content: "";
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${C.Primary};
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: 10px;
        margin-left: -2px;
      }
    }
  }
`;

export const CalendarSelections = styled.div<{ justifyContent?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  width: 100%;
  height: 52px;
  box-sizing: border-box;
  border-bottom: 1px solid ${C.Neutral4};
  background-color: ${C.PrimaryCream};
`;

const ActiveBorder = (v: boolean) => `2px solid ${v ? C.Primary : C.None}`;

export const CalendarOption = styled.div`
  width: 50px;
  height: 100%;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p: { isAct: boolean }) => (p.isAct ? C.Primary : C.Neutral)};
  box-sizing: border-box;
  /* border-bottom: ${(p: { isAct: boolean }) => ActiveBorder(p.isAct)}; */
  cursor: pointer;
  transition: color 0.2s ease;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -0.5px;
    left: 0;
    right: 0;
    height: 2px;
    background: ${(p: { isAct: boolean }) => (p.isAct ? C.Primary : C.None)};
  }

  &:hover {
    color: ${C.PrimaryLighter};
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

export const CalendarPanel = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const CalendarAction = styled.div`
  width: 100%;
  height: ${(p: { size: number }) => p.size || 72}px;
  flex: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  padding-bottom: 24px;
`;

export const CalendarActionButton = styled.div`
  cursor: pointer;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 16.5px;
  transition: all 0.2s ease;

  &:hover {
    color: ${C.Primary};
  }
`;

export const IconSelected = styled(IconCheckedSVG)`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 12px;
  top: 50%;
  margin-top: -10px;
  pointer-events: none;
  fill: ${C.Primary} !important;
`;

export const IconArrow = styled(ArrowSVG)<{
  disabled?: boolean;
  $open?: boolean;
}>`
  fill: ${(p) => (p.disabled ? C.Neutral5 : C.Neutral)};
  width: 16px;
  height: 16px;
  margin-left: 8px;
  pointer-events: none;
  transform: ${({ $open }) => ($open ? `rotate(-180deg)` : `rotate(0)`)};
  transition: all 0.2s ease;
`;
