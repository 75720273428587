import React, { Suspense, lazy, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import { SiteNav } from "constants/navigators";
import { useAccessToken } from "store/accessToken";
import { useMobile } from "hooks/useMobile";
import { notificationErr } from "hooks/useError";
import PortalLayout, { MinTableWIdth } from "components/PortalLayout";
import { message as MobileLoginToast } from "components/SiteItems/MobileLoginToast";
import Toast from "components/Notification";
import { PayFailedNotification } from "components/Notification/Notification";
import { getPaymentFailStatus, reChargePayment } from "service/v1/payment";
import Dashboard from "pages/Dashboard";
import { messageText } from "langs/formatText";

const unPaidStyle: React.CSSProperties = {
  marginLeft: 208,
};

const Usage = lazy(() => import("pages/Usage"));
// const Users = lazy(() => import("pages/Users"));
const Spaces = lazy(() => import("pages/Spaces"));
const BookingsUsage = lazy(() => import("pages/BookingUsage"));
const EmployeeManagement = lazy(() => import("pages/EmployeeManagement"));
const CompanyProfile = lazy(
  () => import("pages/CompanyProfile/CompanyProfilePage")
);
const Vouchers = lazy(() => import("pages/Vouchers"));
const Payment = lazy(() => import("pages/Payment"));
const Invoice = lazy(() => import("pages/Invoice"));
const Receipt = lazy(() => import("pages/Receipt"));
const BuinessUnits = lazy(() => import("pages/BusinessUnits"));

const Tip = MobileLoginToast?.init();

const PAY_FAILED_MESSAGE_KEY = "PAY_FAILED_MESSAGE_KEY";

export const HomeRoutes = [
  {
    path: `/${SiteNav.DASHBOARD}`,
    key: SiteNav.DASHBOARD,
    component: Dashboard,
  },
  { path: `/${SiteNav.USAGE}`, key: SiteNav.USAGE, component: Usage },
  // { path: `/${SiteNav.USERS}`, key: SiteNav.USERS, component: Users },
  { path: `/${SiteNav.SPACES}`, key: SiteNav.SPACES, component: Spaces },
  {
    path: `/${SiteNav.BOOKINGS_USAGE}`,
    key: SiteNav.BOOKINGS_USAGE,
    component: BookingsUsage,
  },
  {
    path: `/${SiteNav.EMPLOYEES}`,
    key: SiteNav.EMPLOYEES,
    component: EmployeeManagement,
  },
  {
    path: `/${SiteNav.COMPANYPROFILE}`,
    key: SiteNav.COMPANYPROFILE,
    component: CompanyProfile,
  },
  {
    path: `/${SiteNav.PAYMENT}/vouchers`,
    key: `/${SiteNav.PAYMENT}/vouchers`,
    component: Vouchers,
  },
  { path: `/${SiteNav.PAYMENT}`, key: SiteNav.PAYMENT, component: Payment },
  { path: `/${SiteNav.INVOICE}`, key: SiteNav.INVOICE, component: Invoice },
  { path: `/${SiteNav.RECEIPT}`, key: SiteNav.RECEIPT, component: Receipt },
  {
    path: `/${SiteNav.BUSINESS_UNITS}`,
    key: SiteNav.BUSINESS_UNITS,
    component: BuinessUnits,
  },
];

export default function Home() {
  const { curCountry } = useAccessToken();
  const { isMobile } = useMobile();
  let history = useHistory();
  useEffect(() => {
    const goToPayMent = () => {
      history.push("/payment");
    };
    const payNow = async () => {
      try {
        const reChargerRes = await reChargePayment();
        if (reChargerRes) {
          Toast.success({ message: messageText.PaidSuccessText });
          Toast.destory(PAY_FAILED_MESSAGE_KEY);
        } else {
          Toast.warn({ message: messageText.PaidNotSuccessText });
        }
      } catch (e) {
        notificationErr(e);
      }
    };
    const fetchPaymentFailStatus = async () => {
      try {
        const allPaid = await getPaymentFailStatus();
        if (allPaid === false) {
          Toast.error({
            message: (
              <PayFailedNotification
                payNow={payNow}
                goToPayMent={goToPayMent}
              />
            ),
            duration: 0,
            key: PAY_FAILED_MESSAGE_KEY,
            style: unPaidStyle,
          });
        } else {
          Toast.destory(PAY_FAILED_MESSAGE_KEY);
        }
      } catch (e) {
        notificationErr(e);
      }
    };

    fetchPaymentFailStatus();
  }, [history]);
  if (!curCountry) return <div />;
  return (
    <>
      <PortalLayout minContentWidth={MinTableWIdth} isViewHeight={true}>
        <Suspense fallback={<div />}>
          <Switch>
            {HomeRoutes.map((c) => (
              <Route path={c.path} key={c.key} component={c.component} />
            ))}
            <Route path="/" exact={true}>
              <Dashboard />
            </Route>
          </Switch>
        </Suspense>
      </PortalLayout>
      {isMobile && Tip}
    </>
  );
}
