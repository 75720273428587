import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useUpdateEffect } from "react-use";
import moment from "moment";
import { EPickerTypes } from "./DatePicker/DatePickerType";
import DateCalendar, { WeekCalendar } from "./DatePicker/DateCalendar";
import RangePanel from "./DatePicker/RangePanel";
import MonthPanel from "./DatePicker/MonthPanel";
import YearPanel from "./DatePicker/YearPanel";
import ActionButton from "components/Buttons/ActionButton";
import SelectionExplanation from "./SelectionExplanation";

import useButtonPressed from "hooks/useButtonPressedEffect";
import useCurrent from "hooks/useCurrent";
import { useTrigger } from "hooks/useDetectOutComponent";
import { getDateRange, getTimeStr, TimeUnit } from "utils/date";
import {
  SinceDateOptions as DateOptions,
  DateOptionsType,
} from "constants/date";

import * as S from "./styles.picker";
import { BodyRegular } from "styles/fonts";
import { formText, dateText } from "langs/formatText";

const momentStart = moment("2020-01-01 +0800", "YYYY-MM-DD Z");
export const SincePickerDefault: IDateValue = {
  type: EPickerTypes.Since,
  start: moment().clone(),
  end: moment().clone(),
};
export const SinceOpenDefault: IDateValue = {
  type: EPickerTypes.Since,
  start: moment("2021-04-02 +0800", "YYYY-MM-DD Z").clone(),
  end: moment("2021-04-02 +0800", "YYYY-MM-DD Z").clone(),
};

type CalendarOptionType = {
  label: string;
  value: EPickerTypes;
};

type PickerType = {
  label: string;
  value: DateOptionsType;
};

export const CalendarOptions: CalendarOptionType[] = [
  { label: dateText.SinceText, value: EPickerTypes.Since },
  { label: dateText.RangeText, value: EPickerTypes.Range },
];

type TCalendarActionSize = {
  [key in EPickerTypes]: number;
};

const CalendarActionHeight: TCalendarActionSize = {
  [EPickerTypes.NONE]: 0,
  [EPickerTypes.Day]: 72,
  [EPickerTypes.Week]: 72,
  [EPickerTypes.Month]: 92,
  [EPickerTypes.Year]: 98,
  [EPickerTypes.Range]: 64,
  [EPickerTypes.Since]: 72,
};

const CalendarContentPaddingTop: TCalendarActionSize = {
  [EPickerTypes.NONE]: 24,
  [EPickerTypes.Day]: 24,
  [EPickerTypes.Week]: 24,
  [EPickerTypes.Month]: 24,
  [EPickerTypes.Year]: 24,
  [EPickerTypes.Range]: 16,
  [EPickerTypes.Since]: 24,
};

const CustomOption: PickerType = {
  label: dateText.CustomText,
  value: DateOptionsType.OTHER,
};

const OptionsSize = {
  [EPickerTypes.NONE]: { width: 180, height: 230 },
  [EPickerTypes.Day]: { width: 533, height: 490 },
  [EPickerTypes.Week]: { width: 533, height: 490 },
  [EPickerTypes.Month]: { width: 533, height: 510 },
  [EPickerTypes.Year]: { width: 533, height: 505 },
  [EPickerTypes.Range]: { width: 533, height: 560 },
  [EPickerTypes.Since]: { width: 533, height: 490 },
};

export function useSincePicker(defaultDate?: string, timeZone?: string) {
  const defaultRange = useMemo(() => {
    const date = defaultDate ? new Date(defaultDate) : new Date();
    const { todayStart } = getDateRange(date);
    return { start: todayStart, end: 0 };
  }, [defaultDate]);
  const [start, setStart] = useState<number>(defaultRange.start); // 2020-09-08T00:00:00Z
  const [end, setEnd] = useState<number>(defaultRange.end);
  const startStr = useMemo(
    () => (start ? getTimeStr(start, timeZone) : ""),
    [start, timeZone]
  );
  const endStr = useMemo(
    () => (end ? getTimeStr(end, timeZone) : ""),
    [end, timeZone]
  );
  useUpdateEffect(() => {
    const date = defaultDate ? new Date(defaultDate) : new Date();
    const { todayStart } = getDateRange(date);
    setStart(todayStart);
    setEnd(0);
  }, [defaultDate]);
  const onConfirmRange = useCallback((s: number, e?: number) => {
    setStart(s || 0);
    setEnd(e || 0);
  }, []);
  return {
    onConfirmRange,
    start: startStr,
    end: endStr,
  };
}

interface RangePickerProps {
  onConfirmRange: (start: number, end?: number) => void;
  hideIcon?: boolean;
  defaultBtnBgColor?: string;
  sinceDefault?: IDateValue;
}

const RangePicker = React.memo((props: RangePickerProps) => {
  const { sinceDefault } = props;
  const datePickerForceUpdateRef = useRef<{ forceUpdate: () => void }>();
  const {
    open,
    onIn,
    onOut,
    onHide: hideSelections,
    toggleSelections,
  } = useTrigger(false);
  const [timeRange, setTimeRange] = useState<IDateValue | null>(
    sinceDefault || SincePickerDefault
  );
  const onUpdateTimeRange = useCallback((v) => setTimeRange(v), []);
  const [pickerType, setPickerType] = useState<PickerType>(CustomOption);
  const [confirmedPickerType, setConfirmEdPickerType] =
    useState<PickerType>(pickerType);
  const [customType, setCustomTypes] = useState<EPickerTypes>(
    EPickerTypes.Since
  );
  useEffect(() => {
    if (sinceDefault) setTimeRange(sinceDefault);
    setPickerType(CustomOption);
    setConfirmEdPickerType(CustomOption);
    setCustomTypes(EPickerTypes.Since);
  }, [sinceDefault]);
  const updateEPickerTypes = useCallback((v) => setCustomTypes(v), []);
  const updatePicker = useCallback(
    (v) => {
      setPickerType(v);
      if (v !== CustomOption) {
        hideSelections();
        if (timeRange) setTimeRange(null);
        setCustomTypes(EPickerTypes.NONE);
        setConfirmEdPickerType(v);
        const ranges = getDateRange(new Date());
        switch (v.value) {
          default:
            break;
          case DateOptionsType.TODAY:
            props.onConfirmRange(ranges.todayStart, ranges.todayEnd);
            break;
          case DateOptionsType.WEEK:
            props.onConfirmRange(ranges.weekStart, ranges.weekEnd);
            break;
          case DateOptionsType.MONTH:
            props.onConfirmRange(ranges.monthStart, ranges.monthEnd);
            break;
          case DateOptionsType.YEAR:
            props.onConfirmRange(ranges.yearStart, ranges.yearEnd);
            break;
          case DateOptionsType.DAYS_SEVEN:
            props.onConfirmRange(
              ranges.todayEnd - TimeUnit.Day * 7 + TimeUnit.Second,
              ranges.todayEnd
            );
            break;
          case DateOptionsType.DAYS_THIRTY:
            props.onConfirmRange(
              ranges.todayEnd - TimeUnit.Day * 30 + TimeUnit.Second,
              ranges.todayEnd
            );
            break;
          case DateOptionsType.DAYS_NINTY:
            props.onConfirmRange(
              ranges.todayEnd - TimeUnit.Day * 90 + TimeUnit.Second,
              ranges.todayEnd
            );
            break;
        }
      }
      if (v === CustomOption) setCustomTypes(EPickerTypes.Since);
    },
    [hideSelections, props, timeRange]
  );
  const onApplyCustom = useCallback(() => {
    const isSince = customType === EPickerTypes.Since;
    if (timeRange) {
      const s = timeRange.start.clone().valueOf();
      const e = timeRange.end.clone().valueOf();
      props.onConfirmRange(s, isSince ? 0 : getDateRange(new Date(e)).todayEnd);
    }
    if (!timeRange) updatePicker(confirmedPickerType);
    hideSelections();
  }, [
    timeRange,
    updatePicker,
    confirmedPickerType,
    hideSelections,
    props,
    customType,
  ]);
  const onResetCustom = useCallback(() => {
    setTimeRange(
      customType === EPickerTypes.Since
        ? props?.sinceDefault || SincePickerDefault
        : null
    );
  }, [customType, props]);
  const { width } = useMemo(
    () => ({
      width: (OptionsSize as any)[customType]?.width || 180,
      height: (OptionsSize as any)[customType]?.height || 268,
    }),
    [customType]
  );
  const { pressed, onMouseDown, onMouseUp } = useButtonPressed();
  const selectedDate = useMemo(() => {
    if (!timeRange) {
      return dateText.CustomText;
    }
    const { type } = timeRange;
    const start = timeRange.start.clone();
    const end = timeRange.end.clone();
    const DATE_FORMAT = "MMM D, YYYY";

    switch (type) {
      case EPickerTypes.Since:
        return `${dateText.SinceText} ${start.format(DATE_FORMAT)}`;
      case EPickerTypes.Day:
        return start.format(DATE_FORMAT);
      case EPickerTypes.Year:
        return start.format("YYYY");
      case EPickerTypes.Month:
        if (start.isSame(end, "month")) {
          return start.format("MMM YYYY");
        }
        if (start.isSame(end, "year")) {
          return `${start.format("MMM")} - ${end.format("MMM")} ${start.format(
            "YYYY"
          )}`;
        }
        return `${start.format("MMM")} ${start.format("YYYY")} - ${end.format(
          "MMM"
        )} ${end.format("YYYY")}`;

      default:
        if (!start.isSame(end, "year")) {
          return `${start.format(DATE_FORMAT)} - ${end.format(DATE_FORMAT)}`;
        }
        if (!start.isSame(end, "month")) {
          return `${start.format("MMM D")} - ${end.format(DATE_FORMAT)}`;
        }
        if (!start.isSame(end, "day")) {
          return `${start.format("MMM D")}-${end.format("D")}, ${start.format(
            "YYYY"
          )}`;
        }
        return start.format(DATE_FORMAT);
    }
  }, [timeRange]);
  const displayText = useMemo(() => {
    let result = "";
    const isCustom = pickerType?.value === DateOptionsType.OTHER;
    if (isCustom) result = selectedDate;
    if (pickerType && !isCustom) result = pickerType.label;
    if (!pickerType) result = dateText.TodayText;
    return result;
  }, [pickerType, selectedDate]);
  return (
    <S.Wrapper>
      <S.Selections
        isShow={open}
        style={{ width }}
        onMouseEnter={onIn}
        onMouseLeave={onOut}
      >
        <Selections pickerType={pickerType} updatePicker={updatePicker} />
        {!!customType && customType !== EPickerTypes.NONE && (
          <CalendarComponent
            onUpdate={updateEPickerTypes}
            curtype={customType}
            timeRange={timeRange}
            onUpdateValue={onUpdateTimeRange}
            attachPickerPanel={datePickerForceUpdateRef}
            onApply={onApplyCustom}
            onReset={onResetCustom}
          />
        )}
      </S.Selections>
      <PickerButton
        showSelections={toggleSelections}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseEnter={onIn}
        onMouseLeave={onOut}
        displayText={displayText}
        pressed={!!pressed}
        hideIcon={props.hideIcon}
        defaultBgColor={props.defaultBtnBgColor}
      />
    </S.Wrapper>
  );
});

export default RangePicker;

interface PickerButtonProps {
  showSelections: () => void;
  onMouseDown: () => void;
  onMouseUp: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  pressed: boolean;
  displayText: string;
  hideIcon?: boolean;
  defaultBgColor?: string;
}

const PickerButton = React.memo((p: PickerButtonProps) => {
  return (
    <S.PickerButton
      onClick={p.showSelections}
      onMouseDownCapture={p.onMouseDown}
      onMouseUpCapture={p.onMouseUp}
      onMouseOutCapture={p.onMouseUp}
      onMouseEnter={p.onMouseEnter}
      onMouseLeave={p.onMouseLeave}
      pressed={p.pressed}
      defaultBgColor={p.defaultBgColor}
    >
      {!p.hideIcon && <S.IconCalendar />}
      <BodyRegular>{p.displayText}</BodyRegular>
    </S.PickerButton>
  );
});

interface SelectionsProps {
  pickerType: PickerType;
  updatePicker: (v: PickerType) => void;
}

const Selections = React.memo((p: SelectionsProps) => {
  return (
    <S.SelectionsWrapper>
      {DateOptions.map((v) => (
        <S.Option
          key={v.value}
          isActive={p.pickerType?.value === v.value}
          onClick={p.updatePicker.bind(null, v)}
        >
          <BodyRegular>{v.label}</BodyRegular>
          <SelectionExplanation type={v.value} />
        </S.Option>
      ))}
      <S.Divider />
      <S.Option
        isCustom={true}
        isActive={p.pickerType?.value === CustomOption.value}
        onClick={p.updatePicker.bind(null, CustomOption)}
      >
        <BodyRegular>{CustomOption.label}</BodyRegular>
      </S.Option>
    </S.SelectionsWrapper>
  );
});

interface CalendarComponentProps {
  curtype: EPickerTypes;
  onUpdate: (v: EPickerTypes) => void;
  onUpdateValue: (v: IDateValue) => void;
  onApply: () => void;
  onReset: () => void;
  timeRange: IDateValue | null;
  attachPickerPanel: React.MutableRefObject<
    { forceUpdate: () => void } | undefined
  >;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
}

export const CalendarComponent = React.memo(
  ({
    curtype,
    onUpdate,
    onUpdateValue,
    onApply,
    onReset,
    timeRange,
    attachPickerPanel,
    startDate,
    endDate,
  }: CalendarComponentProps) => {
    const onChange = useCallback(
      (value: IDateValue) => {
        onUpdateValue(value);
      },
      [onUpdateValue]
    );
    const [value, setValue] =
      useState<[moment.Moment, moment.Moment | undefined]>();
    const [viewedDate, setViewedDate] = useState(
      () => timeRange?.start || moment()
    );
    const valueRef = useCurrent(timeRange);

    const todayRef = useCurrent(moment());

    const handleDisabledDate = useCallback(
      (date: moment.Moment) => {
        if (startDate && endDate)
          return date.isBefore(startDate, "d") || date.isAfter(endDate, "d");
        if (startDate) return date.isBefore(startDate, "d");
        if (endDate)
          return date.isBefore(momentStart, "d") || date.isAfter(endDate, "d");
        return date.isBefore(momentStart, "d");
      },
      [endDate, startDate]
    );

    const handleDisabledMonth = useCallback(
      (date: moment.Moment) => {
        if (startDate && endDate)
          return (
            date.isBefore(startDate, "month") || date.isAfter(endDate, "month")
          );
        if (startDate) return date.isBefore(startDate, "month");
        if (endDate)
          return (
            date.isBefore(momentStart, "month") ||
            date.isAfter(endDate, "month")
          );
        return date.isBefore(momentStart, "month");
      },
      [endDate, startDate]
    );

    const handleDisabledYear = useCallback(
      (date: moment.Moment) => {
        if (startDate && endDate)
          return (
            date.isBefore(startDate, "year") || date.isAfter(endDate, "year")
          );
        if (startDate) return date.isBefore(startDate, "year");
        if (endDate)
          return (
            date.isBefore(momentStart, "year") || date.isAfter(endDate, "year")
          );
        return date.isBefore(momentStart, "year");
      },
      [endDate, startDate]
    );

    const handleYearChange = useCallback(
      (year: moment.Moment) => {
        onChange({
          type: EPickerTypes.Year,
          start: year.clone().startOf("year"),
          end: year.clone().endOf("year"),
        });
      },
      [onChange]
    );

    const handleDayOrWeekChange = useCallback(
      (date: moment.Moment) => {
        const unit = curtype === EPickerTypes.Week ? "week" : "d";
        onChange({
          type: curtype,
          start: date.clone().startOf(unit),
          end: date.clone().endOf(unit),
        });
      },
      [onChange, curtype]
    );

    const handleMonthChange = useCallback(
      (v: moment.Moment | undefined) => {
        if (v) {
          onChange({
            type: EPickerTypes.Month,
            start: v.clone().startOf("month"),
            end: v.clone().endOf("month"),
          });
        } else {
          setValue(undefined);
        }
      },
      [onChange]
    );

    const handleRangeChange = useCallback(
      (value: [moment.Moment, moment.Moment | undefined]) => {
        if (value[1]) {
          onChange({
            type: curtype,
            start: value[0],
            end: value[1],
          });
        } else {
          setValue(value);
        }
      },
      [onChange, curtype]
    );

    const handlePickerChange = useCallback(
      (pickerType: EPickerTypes) => {
        const v = valueRef.current;
        onUpdate(pickerType);
        if (v?.type === pickerType) {
          setValue([v.start.clone(), v.end.clone()]);
          setViewedDate(v.start.clone());
        } else {
          setValue(undefined);
          setViewedDate(moment());
        }
      },
      [onUpdate, valueRef]
    );

    const setPickerAndValueFromProps = useCallback(() => {
      if (!timeRange) setValue(undefined);
      if (timeRange) {
        onUpdate(timeRange.type);
        setValue([timeRange.start.clone(), timeRange.end.clone()]);
        setViewedDate(timeRange?.start.clone() || moment());
      }
    }, [timeRange, onUpdate, setValue]);

    useEffect(() => {
      setPickerAndValueFromProps();

      attachPickerPanel.current = {
        forceUpdate: setPickerAndValueFromProps,
      };
    }, [attachPickerPanel, setPickerAndValueFromProps]);

    const onResetSelected = useCallback(() => {
      onReset();
    }, [onReset]);

    const picker = useMemo(() => {
      switch (curtype) {
        default:
          const Component =
            curtype === EPickerTypes.Week ? WeekCalendar : DateCalendar;
          return (
            <Component
              disabledMonth={handleDisabledMonth}
              disabledDate={handleDisabledDate}
              value={value?.[0]}
              onChange={handleDayOrWeekChange}
              today={todayRef.current}
              viewedMonth={viewedDate}
              onViewedMonthChange={setViewedDate}
              key={curtype}
            />
          );
        case EPickerTypes.Month:
          return (
            <MonthPanel
              disabledMonth={handleDisabledMonth}
              disabledYear={handleDisabledYear}
              value={value?.[1]}
              onChange={handleMonthChange}
              viewedYear={viewedDate}
              onViewedYearChange={setViewedDate}
            />
          );
        case EPickerTypes.Year:
          return (
            <YearPanel
              value={value?.[0]}
              onChange={handleYearChange}
              startYear={momentStart}
              today={todayRef.current}
            />
          );
        case EPickerTypes.Range:
          return (
            <RangePanel
              disabledMonth={handleDisabledMonth}
              disabledDate={handleDisabledDate}
              value={value}
              onChange={handleRangeChange}
              today={todayRef.current}
              viewedMonth={viewedDate}
              onViewedMonthChange={setViewedDate}
            />
          );
      }
    }, [
      curtype,
      handleDayOrWeekChange,
      handleDisabledDate,
      handleDisabledMonth,
      handleDisabledYear,
      handleMonthChange,
      handleRangeChange,
      handleYearChange,
      todayRef,
      value,
      viewedDate,
    ]);
    return (
      <S.CalendarWrapper>
        <S.CalendarSelections
          justifyContent="flex-start"
          style={{ paddingLeft: 27 }}
        >
          {CalendarOptions.map((v) => (
            <S.CalendarOption
              key={v.value}
              isAct={v.value === curtype}
              onClick={handlePickerChange.bind(null, v.value)}
            >
              <BodyRegular>{v.label}</BodyRegular>
            </S.CalendarOption>
          ))}
        </S.CalendarSelections>
        <S.CalendarPanel
          style={{ paddingTop: CalendarContentPaddingTop[curtype] }}
        >
          {picker}
        </S.CalendarPanel>
        <S.CalendarAction size={CalendarActionHeight[curtype]}>
          <ActionButton action={onResetSelected} style={{ marginRight: 8 }}>
            {formText.ResetText}
          </ActionButton>
          <ActionButton
            action={onApply}
            isPrimary={true}
            style={{ marginRight: 24 }}
          >
            {formText.ApplyText}
          </ActionButton>
        </S.CalendarAction>
      </S.CalendarWrapper>
    );
  }
);
