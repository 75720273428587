export type InjectionClass = Record<string, any>;

export type InjectionConstructor<T = InjectionClass> = {
  new (...args: any[]): T;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type InjectionAbstractConstructor<T = InjectionClass> = Function & {
  prototype: T;
};

export type InjectionProvide =
  | InjectionToken
  | InjectionConstructor
  | InjectionAbstractConstructor;

export type InjectionValue<P extends InjectionProvide> =
  P extends InjectionToken<infer V>
    ? V
    : P extends { prototype: infer C }
    ? C
    : never;

export type InjectionDisposer = <P extends InjectionProvide = InjectionProvide>(
  service: InjectionValue<P>
) => void;

export type InjectionProviderObj = {
  provide: InjectionProvide;
  useValue?: any;
  useClass?: InjectionConstructor | null;
  useExisting?: InjectionProvide | null;
  useFactory?:
    | ((ctx: InjectionContext) => InjectionValue<InjectionProvide>)
    | null;
  dispose?: InjectionDisposer | null;
};

export type InjectionProvider = InjectionProvide | InjectionProviderObj;

export type InjectionContext = {
  useService: GetService;
};

export interface GetService {
  <P extends InjectionProvide>(
    provide: P,
    opts: { optional: true }
  ): InjectionValue<P> | null;
  <P extends InjectionProvide>(
    provide: P,
    opts?: { optional?: false }
  ): InjectionValue<P>;
}

export class InjectionToken<V = any> {
  private _desc: string;
  factory?: ((ctx: InjectionContext) => V) | null;

  constructor(
    desc: string,
    options?: {
      factory: (ctx: InjectionContext) => V;
    }
  ) {
    this._desc = desc;
    this.factory = options?.factory;
  }

  toString(): string {
    return `InjectionToken: ${this._desc}`;
  }
}
