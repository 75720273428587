import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import moment from "moment";

import { EPickerTypes } from "./DatePicker/DatePickerType";
import DateCalendar, { WeekCalendar } from "./DatePicker/DateCalendar";
import RangePanel from "./DatePicker/RangePanel";
import MonthPanel from "./DatePicker/MonthPanel";
import YearPanel from "./DatePicker/YearPanel";
import ActionButton from "components/Buttons/ActionButton";
import SelectionExplanation from "./SelectionExplanation";
import { Dropdown } from "@REInventVenturesPteLtd/sui";

import useButtonPressed from "hooks/useButtonPressedEffect";
import useCurrent from "hooks/useCurrent";
import { getDateRange, getTimeStr, TimeUnit } from "utils/date";
import {
  DateOptionsType,
  RangeDateOptions as DateOptions,
} from "constants/date";

import * as S from "./styles.picker";
import { BodyRegular } from "styles/fonts";
import { formText, dateText } from "langs/formatText";

const momentStart = moment("2020-01-01 +0800", "YYYY-MM-DD Z");

type CalendarOptionType = {
  label: string;
  value: EPickerTypes;
};

type PickerType = {
  label: string;
  value: DateOptionsType | string;
  explanation?: string;
};

type CustomDateOption = {
  value: string;
  label: string;
  explanation?: string;
  start: number;
  end: number;
};

export const CalendarOptions: CalendarOptionType[] = [
  { label: dateText.DayText, value: EPickerTypes.Day },
  { label: dateText.WeekText, value: EPickerTypes.Week },
  { label: dateText.MonthText, value: EPickerTypes.Month },
  { label: dateText.YearText, value: EPickerTypes.Year },
  { label: dateText.RangeText, value: EPickerTypes.Range },
];

type TCalendarActionSize = {
  [key in EPickerTypes]: number;
};

const CalendarActionHeight: Partial<TCalendarActionSize> = {
  [EPickerTypes.NONE]: 0,
  [EPickerTypes.Day]: 72,
  [EPickerTypes.Week]: 72,
  [EPickerTypes.Month]: 92,
  [EPickerTypes.Year]: 98,
  [EPickerTypes.Range]: 64,
};

const CalendarContentPaddingTop: Partial<TCalendarActionSize> = {
  [EPickerTypes.NONE]: 24,
  [EPickerTypes.Day]: 24,
  [EPickerTypes.Week]: 24,
  [EPickerTypes.Month]: 24,
  [EPickerTypes.Year]: 24,
  [EPickerTypes.Range]: 16,
};

const CustomOption: PickerType = {
  label: dateText.CustomText,
  value: DateOptionsType.OTHER,
};

const OptionsSize = {
  [EPickerTypes.NONE]: { width: 180, height: "auto" },
  [EPickerTypes.Day]: { width: 533, height: "auto" },
  [EPickerTypes.Week]: { width: 533, height: "auto" },
  [EPickerTypes.Month]: { width: 533, height: 510 },
  [EPickerTypes.Year]: { width: 533, height: "auto" },
  [EPickerTypes.Range]: { width: 533, height: "auto" },
};

export { EPickerTypes, DateOptionsType };

export function useRangePicker(
  timeZone?: string,
  defaultType?: DateOptionsType | string,
  customDateOptions: CustomDateOption[] = []
) {
  const defaultRange = useMemo(() => {
    const rangeInfo = getDateRange(new Date());

    // custom
    const customRange = customDateOptions.find(
      (item) => item.value === defaultType
    );
    if (customRange)
      return {
        start: customRange.start,
        end: customRange.end,
      };

    switch (defaultType) {
      default:
        return {
          start: rangeInfo.todayStart,
          end: rangeInfo.todayEnd,
        };
      case DateOptionsType.WEEK:
        return {
          start: rangeInfo.weekStart,
          end: rangeInfo.weekEnd,
        };
      case DateOptionsType.MONTH:
        return {
          start: rangeInfo.monthStart,
          end: rangeInfo.monthEnd,
        };
      case DateOptionsType.YEAR:
        return {
          start: rangeInfo.yearStart,
          end: rangeInfo.yearEnd,
        };
    }
  }, [defaultType, customDateOptions]);
  const [start, setStart] = useState<number>(defaultRange.start); // 2020-09-08T00:00:00Z
  const [end, setEnd] = useState<number>(defaultRange.end);
  const startStr = useMemo(
    () => (start ? getTimeStr(start, timeZone) : ""),
    [start, timeZone]
  );
  const endStr = useMemo(
    () => (end ? getTimeStr(end, timeZone) : ""),
    [end, timeZone]
  );
  const onConfirmRange = useCallback((s: number, e: number) => {
    if (!s || !e) return;
    setStart(s);
    setEnd(e);
  }, []);
  return {
    onConfirmRange,
    start: startStr,
    end: endStr,
  };
}

interface RangePickerProps {
  onConfirmRange: (
    start: number,
    end: number,
    type?: DateOptionsType,
    customType?: EPickerTypes
  ) => void;
  hideIcon?: boolean;
  hideArrow?: boolean;
  defaultBtnBgColor?: string;
  disabledDate?: (data: moment.Moment) => boolean;
  disabledTypes?: (DateOptionsType | string)[];
  useTypes?: (DateOptionsType | string)[];
  initialType?: DateOptionsType | string;
  initialCustomType?: EPickerTypes;
  customDateOptions?: CustomDateOption[];
  useCustomTypes?: EPickerTypes[];
  selectionStyle?: React.CSSProperties;
  defaultStart?: number;
  defaultEnd?: number;
  triggerStyles?: React.CSSProperties;
  defaultBtnBorderColor?: string;
}

const RangePicker = React.memo((props: RangePickerProps) => {
  const { disabledTypes = [], useTypes = [], customDateOptions = [] } = props;
  const dateOptions = useMemo(() => {
    let options: PickerType[] = [...DateOptions, ...customDateOptions];
    if (useTypes.length) {
      return options
        .filter((item) => {
          return useTypes.includes(item.value);
        })
        .sort((a, b) => {
          return useTypes.indexOf(a.value) - useTypes.indexOf(b.value);
        });
    }
    if (!disabledTypes.length) return options;
    return options.filter((item) => disabledTypes.indexOf(item.value) === -1);
  }, [customDateOptions, useTypes, disabledTypes]);
  const datePickerForceUpdateRef = useRef<{ forceUpdate: () => void }>();
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen((v) => !v), []);
  const [timeRange, setTimeRange] = useState<IDateValue | null>(null);
  const onUpdateTimeRange = useCallback((v) => setTimeRange(v), []);
  const [pickerType, setPickerType] = useState<PickerType>(() => {
    if (!props.initialType) return DateOptions[0];
    const find = dateOptions.find((item) => item.value === props.initialType);
    return find || DateOptions[0];
  });
  const [confirmedPickerType, setConfirmEdPickerType] =
    useState<PickerType>(pickerType);
  const [customType, setCustomTypes] = useState<EPickerTypes>(() => {
    if (pickerType.value !== DateOptionsType.OTHER) return EPickerTypes.NONE;
    if (props.useCustomTypes && props.useCustomTypes.length)
      return props.useCustomTypes[0];
    return EPickerTypes.NONE;
  });
  const updateEPickerTypes = useCallback((v) => setCustomTypes(v), []);
  const updatePicker = useCallback(
    (v) => {
      setPickerType(v);
      if (v !== CustomOption) {
        setOpen(false);
        if (timeRange) setTimeRange(null);
        setCustomTypes(EPickerTypes.NONE);
        setConfirmEdPickerType(v);

        const customRange = customDateOptions.find(
          (item) => item.value === v.value
        );
        if (customRange) {
          props.onConfirmRange(customRange.start, customRange.end);
          return;
        }

        const ranges = getDateRange(new Date());
        switch (v.value) {
          default:
            break;
          case DateOptionsType.TODAY:
            props.onConfirmRange(ranges.todayStart, ranges.todayEnd);
            break;
          case DateOptionsType.WEEK:
            props.onConfirmRange(ranges.weekStart, ranges.weekEnd);
            break;
          case DateOptionsType.MONTH:
            props.onConfirmRange(ranges.monthStart, ranges.monthEnd);
            break;
          case DateOptionsType.YEAR:
            props.onConfirmRange(ranges.yearStart, ranges.yearEnd);
            break;
          case DateOptionsType.DAYS_SEVEN:
            props.onConfirmRange(
              ranges.todayEnd - TimeUnit.Day * 7 + TimeUnit.Second,
              ranges.todayEnd
            );
            break;
          case DateOptionsType.DAYS_THIRTY:
            props.onConfirmRange(
              ranges.todayEnd - TimeUnit.Day * 30 + TimeUnit.Second,
              ranges.todayEnd
            );
            break;
          case DateOptionsType.DAYS_NINTY:
            props.onConfirmRange(
              ranges.todayEnd - TimeUnit.Day * 90 + TimeUnit.Second,
              ranges.todayEnd
            );
            break;
        }
      }
      if (v === CustomOption)
        setCustomTypes(
          props.useCustomTypes && props.useCustomTypes.length
            ? props.useCustomTypes[0]
            : EPickerTypes.Day
        );
    },
    [customDateOptions, props, timeRange]
  );
  const onApplyCustom = useCallback(() => {
    if (timeRange) {
      const s = timeRange.start.clone().valueOf();
      const e = timeRange.end.clone().valueOf();
      props.onConfirmRange(s, getDateRange(new Date(e)).todayEnd);
    }
    if (!timeRange) updatePicker(confirmedPickerType);
    setOpen(false);
  }, [timeRange, updatePicker, confirmedPickerType, props]);
  const onResetCustom = useCallback(() => {
    setTimeRange(null);
  }, []);
  const { width, height } = useMemo(() => {
    const h = (OptionsSize as any)[customType]?.height || 268;
    const height =
      typeof h === "number" ? Math.max(h, 68 + dateOptions.length * 52) : h;
    return {
      width: (OptionsSize as any)[customType]?.width || 180,
      height: height,
    };
  }, [customType, dateOptions]);
  const { pressed, onMouseDown, onMouseUp } = useButtonPressed();
  const selectedDate = useMemo(() => {
    if (!timeRange) {
      return dateText.CustomText;
    }
    const { type } = timeRange;
    const start = timeRange.start.clone();
    const end = timeRange.end.clone();
    const DATE_FORMAT = "MMM D, YYYY";

    switch (type) {
      case EPickerTypes.Day:
        return start.format(DATE_FORMAT);
      case EPickerTypes.Year:
        return start.format("YYYY");
      case EPickerTypes.Month:
        if (start.isSame(end, "month")) {
          return start.format("MMMM, YYYY");
        }
        if (start.isSame(end, "year")) {
          return `${start.format("MMM")} - ${end.format("MMM")} ${start.format(
            "YYYY"
          )}`;
        }
        return `${start.format("MMM")} ${start.format("YYYY")} - ${end.format(
          "MMM"
        )} ${end.format("YYYY")}`;

      default:
        if (!start.isSame(end, "year")) {
          return `${start.format(DATE_FORMAT)} - ${end.format(DATE_FORMAT)}`;
        }
        if (!start.isSame(end, "month")) {
          return `${start.format("MMM D")} - ${end.format(DATE_FORMAT)}`;
        }
        if (!start.isSame(end, "day")) {
          return `${start.format("MMM D")}-${end.format("D")}, ${start.format(
            "YYYY"
          )}`;
        }
        return start.format(DATE_FORMAT);
    }
  }, [timeRange]);
  const displayText = useMemo(() => {
    let result = "";
    const isCustom = pickerType?.value === DateOptionsType.OTHER;
    if (isCustom) result = selectedDate;
    if (pickerType && !isCustom) result = pickerType.label;
    if (!pickerType) result = dateText.TodayText;
    return result;
  }, [pickerType, selectedDate]);
  const showCustom = !!customType && customType !== EPickerTypes.NONE;

  return (
    <Dropdown
      visible={open}
      onVisibleChange={(v) => setOpen(!!v)}
      overlay={
        <S.Selections
          isShow={open}
          style={{
            width,
            height,
            ...props.selectionStyle,
            position: "relative",
            top: 4,
            left: 0,
            transform: "none",
            transition: open ? "none" : undefined,
          }}
        >
          <Selections
            pickerType={pickerType}
            updatePicker={updatePicker}
            dateOptions={dateOptions}
          />
          {showCustom && (
            <CalendarComponent
              onUpdate={updateEPickerTypes}
              curtype={customType}
              timeRange={timeRange}
              onUpdateValue={onUpdateTimeRange}
              attachPickerPanel={datePickerForceUpdateRef}
              onApply={onApplyCustom}
              onReset={onResetCustom}
              useCustomTypes={props.useCustomTypes}
            />
          )}
        </S.Selections>
      }
    >
      <S.Wrapper>
        <PickerButton
          showSelections={toggleOpen}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          displayText={displayText}
          pressed={open || !!pressed}
          hideIcon={props.hideIcon}
          hideArrow={props.hideArrow}
          defaultBgColor={props.defaultBtnBgColor}
          defaultBorderColor={props.defaultBtnBorderColor}
          open={open}
          style={props.triggerStyles}
        />
      </S.Wrapper>
    </Dropdown>
  );
});

export default RangePicker;

interface PickerButtonProps {
  showSelections: () => void;
  onMouseDown: () => void;
  onMouseUp: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  pressed: boolean;
  open: boolean;
  displayText: string;
  hideIcon?: boolean;
  hideArrow?: boolean;
  defaultBgColor?: string;
  defaultBorderColor?: string;
  style?: React.CSSProperties;
}

const PickerButton = React.memo((p: PickerButtonProps) => {
  return (
    <S.PickerButton
      onClick={p.showSelections}
      onMouseDownCapture={p.onMouseDown}
      onMouseUpCapture={p.onMouseUp}
      onMouseOutCapture={p.onMouseUp}
      onMouseEnter={p.onMouseEnter}
      onMouseLeave={p.onMouseLeave}
      pressed={p.pressed}
      defaultBgColor={p.defaultBgColor}
      defaultBorderColor={p.defaultBorderColor}
      style={{
        padding: p.hideArrow ? undefined : "0 8px 0 12px",
        ...(p.style || {}),
      }}
    >
      {!p.hideIcon && <S.IconCalendar />}
      <BodyRegular>{p.displayText}</BodyRegular>
      {!p.hideArrow && <S.IconArrow $open={p.open} />}
    </S.PickerButton>
  );
});

interface SelectionsProps {
  pickerType: PickerType;
  updatePicker: (v: PickerType) => void;
  dateOptions: PickerType[];
}

const Selections = React.memo((p: SelectionsProps) => {
  return (
    <S.SelectionsWrapper>
      {p.dateOptions.map((v) => (
        <S.Option
          key={v.value}
          isActive={p.pickerType?.value === v.value}
          onClick={p.updatePicker.bind(null, v)}
        >
          {p.pickerType?.value === v.value && <S.IconSelected />}
          <BodyRegular>{v.label}</BodyRegular>
          <SelectionExplanation type={v.value} explanation={v.explanation} />
        </S.Option>
      ))}
      <S.Divider />
      <S.Option
        isCustom={true}
        isActive={p.pickerType?.value === CustomOption.value}
        onClick={p.updatePicker.bind(null, CustomOption)}
      >
        {p.pickerType?.value === CustomOption.value && <S.IconSelected />}
        <BodyRegular>{CustomOption.label}</BodyRegular>
      </S.Option>
    </S.SelectionsWrapper>
  );
});

interface CalendarComponentProps {
  curtype: EPickerTypes;
  onUpdate: (v: EPickerTypes) => void;
  onUpdateValue: (v: IDateValue) => void;
  onApply: () => void;
  onReset: () => void;
  timeRange: IDateValue | null;
  attachPickerPanel: React.MutableRefObject<
    { forceUpdate: () => void } | undefined
  >;
  useCustomTypes?: EPickerTypes[];
}

export const CalendarComponent = React.memo(
  ({
    curtype,
    onUpdate,
    onUpdateValue,
    onApply,
    onReset,
    timeRange,
    attachPickerPanel,
    useCustomTypes = [],
  }: CalendarComponentProps) => {
    const calendarOptions = useMemo(() => {
      if (useCustomTypes.length) {
        return CalendarOptions.filter((item) => {
          return useCustomTypes.includes(item.value);
        }).sort((a, b) => {
          return (
            useCustomTypes.indexOf(a.value) - useCustomTypes.indexOf(b.value)
          );
        });
      }
      return CalendarOptions;
    }, [useCustomTypes]);
    const onChange = useCallback(
      (value: IDateValue) => {
        onUpdateValue(value);
      },
      [onUpdateValue]
    );
    const [value, setValue] =
      useState<[moment.Moment, moment.Moment | undefined]>();
    const [viewedDate, setViewedDate] = useState(
      () => timeRange?.start || moment()
    );
    const valueRef = useCurrent(timeRange);

    const todayRef = useCurrent(moment());

    const handleDisabledDate = useCallback(
      (date: moment.Moment) =>
        date.isBefore(momentStart, "d") || date.isAfter(todayRef.current, "d"),
      [todayRef]
    );

    const handleDisabledMonth = useCallback(
      (date: moment.Moment) =>
        date.isBefore(momentStart, "month") ||
        date.isAfter(todayRef.current, "month"),
      [todayRef]
    );

    const handleDisabledYear = useCallback(
      (date: moment.Moment) =>
        date.isBefore(momentStart, "year") ||
        date.isAfter(todayRef.current, "year"),
      [todayRef]
    );

    const handleYearChange = useCallback(
      (year: moment.Moment) => {
        onChange({
          type: EPickerTypes.Year,
          start: year.clone().startOf("year"),
          end: year.clone().endOf("year"),
        });
      },
      [onChange]
    );

    const handleDayOrWeekChange = useCallback(
      (date: moment.Moment) => {
        const unit = curtype === EPickerTypes.Day ? "d" : "week";
        onChange({
          type: curtype,
          start: date.clone().startOf(unit),
          end: date.clone().endOf(unit),
        });
      },
      [onChange, curtype]
    );

    const handleMonthChange = useCallback(
      (v: moment.Moment | undefined) => {
        if (v) {
          onChange({
            type: EPickerTypes.Month,
            start: v.clone().startOf("month"),
            end: v.clone().endOf("month"),
          });
        } else {
          setValue(undefined);
        }
      },
      [onChange]
    );

    const handleRangeChange = useCallback(
      (value: [moment.Moment, moment.Moment | undefined]) => {
        if (value[1]) {
          onChange({
            type: EPickerTypes.Range,
            start: value[0],
            end: value[1],
          });
        } else {
          setValue(value);
        }
      },
      [onChange]
    );

    const handlePickerChange = useCallback(
      (pickerType: EPickerTypes) => {
        const v = valueRef.current;
        onUpdate(pickerType);
        if (v?.type === pickerType) {
          setValue([v.start.clone(), v.end.clone()]);
          setViewedDate(v.start.clone());
        } else {
          setValue(undefined);
          setViewedDate(moment());
        }
      },
      [onUpdate, valueRef]
    );

    const setPickerAndValueFromProps = useCallback(() => {
      if (!timeRange) setValue(undefined);
      if (timeRange) {
        onUpdate(timeRange.type);
        setValue([timeRange.start.clone(), timeRange.end.clone()]);
        setViewedDate(timeRange?.start.clone() || moment());
      }
    }, [timeRange, onUpdate, setValue]);

    useEffect(() => {
      setPickerAndValueFromProps();

      attachPickerPanel.current = {
        forceUpdate: setPickerAndValueFromProps,
      };
    }, [attachPickerPanel, setPickerAndValueFromProps]);

    const onResetSelected = useCallback(() => {
      onReset();
    }, [onReset]);

    const picker = useMemo(() => {
      switch (curtype) {
        default:
          const Component =
            curtype === EPickerTypes.Week ? WeekCalendar : DateCalendar;
          return (
            <Component
              disabledMonth={handleDisabledMonth}
              disabledDate={handleDisabledDate}
              value={value?.[0]}
              onChange={handleDayOrWeekChange}
              today={todayRef.current}
              viewedMonth={viewedDate}
              onViewedMonthChange={setViewedDate}
              key={curtype}
            />
          );
        case EPickerTypes.Month:
          return (
            <MonthPanel
              disabledMonth={handleDisabledMonth}
              disabledYear={handleDisabledYear}
              value={value?.[1]}
              onChange={handleMonthChange}
              viewedYear={viewedDate}
              onViewedYearChange={setViewedDate}
            />
          );
        case EPickerTypes.Year:
          return (
            <YearPanel
              value={value?.[0]}
              onChange={handleYearChange}
              startYear={momentStart}
              today={todayRef.current}
            />
          );
        case EPickerTypes.Range:
          return (
            <RangePanel
              disabledMonth={handleDisabledMonth}
              disabledDate={handleDisabledDate}
              value={value}
              onChange={handleRangeChange}
              today={todayRef.current}
              viewedMonth={viewedDate}
              onViewedMonthChange={setViewedDate}
            />
          );
      }
    }, [
      curtype,
      handleDayOrWeekChange,
      handleDisabledDate,
      handleDisabledMonth,
      handleDisabledYear,
      handleMonthChange,
      handleRangeChange,
      handleYearChange,
      todayRef,
      value,
      viewedDate,
    ]);
    return (
      <S.CalendarWrapper>
        <S.CalendarSelections>
          {calendarOptions.map((v) => (
            <S.CalendarOption
              key={v.value}
              isAct={v.value === curtype}
              onClick={handlePickerChange.bind(null, v.value)}
            >
              <BodyRegular>{v.label}</BodyRegular>
            </S.CalendarOption>
          ))}
        </S.CalendarSelections>
        <S.CalendarPanel
          style={{ paddingTop: CalendarContentPaddingTop[curtype] }}
        >
          {picker}
        </S.CalendarPanel>
        <S.CalendarAction size={CalendarActionHeight[curtype] || 0}>
          <ActionButton action={onResetSelected} style={{ marginRight: 8 }}>
            {formText.ResetText}
          </ActionButton>
          <ActionButton
            action={onApply}
            isPrimary={true}
            style={{ marginRight: 24 }}
          >
            {formText.ApplyText}
          </ActionButton>
        </S.CalendarAction>
      </S.CalendarWrapper>
    );
  }
);
