import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useMeasure } from "react-use";
import { LoadingOutlined } from "@ant-design/icons";

import * as S from "./styles.table";
import useStateValue from "hooks/useStateValue";

import { Footer } from "./Footer";
import { Header } from "./Header";
import { TableItem } from "./RowItem";
import { messageText } from "langs/formatText";

export const TablePaddingStyle: React.CSSProperties = {
  paddingLeft: 32,
  paddingRight: 32,
  paddingTop: 16,
  boxSizing: "border-box",
};

type rowKeyFunc = (row: any) => string;

interface TableProps {
  columns: IColumns[];
  total?: number;
  pageSize?: number;
  scrollHeight?: number;
  loading?: boolean;
  ds: Array<any>;
  onItemClick?: (data: any) => void;
  curPage?: number;
  onSetPage?: (v: number) => void;
  onPageSizeChange?: (size: number) => void;
  unitType?: UnitType | "";
  summaryData?: TSumaryItem;
  rowKey?: string | rowKeyFunc;
  rowHeight?: number;
  bottomBorderLeft?: number | string;
  bottomBorderRight?: number | string;
  wrapperStyle?: React.CSSProperties;
  listCallback?: (data: any) => void; // for update table
  noDataMsg?: React.ReactNode;
  disableFooter?: boolean;
  isFullView?: boolean;
}

function useScrollDetect() {
  const [isScrollRightBottom, setScrollToRightBottom] = useState(false);
  const onScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (!(e && e.target)) return;
      const { target } = e;
      const { scrollWidth, scrollLeft, clientWidth } = target as HTMLDivElement;
      const curIsScrollToRightBottom = scrollWidth - scrollLeft === clientWidth;
      if (curIsScrollToRightBottom !== isScrollRightBottom)
        setScrollToRightBottom(curIsScrollToRightBottom);
    },
    [isScrollRightBottom]
  );
  return {
    onScroll,
    isScrollRightBottom,
  };
}

export default function Table({
  columns,
  total,
  pageSize,
  scrollHeight,
  loading,
  ds,
  onItemClick,
  curPage,
  onSetPage,
  onPageSizeChange,
  unitType,
  rowKey,
  rowHeight,
  summaryData,
  bottomBorderLeft,
  bottomBorderRight,
  wrapperStyle,
  listCallback,
  noDataMsg,
  disableFooter,
  isFullView,
}: TableProps) {
  const { onScroll, isScrollRightBottom } = useScrollDetect();
  const ItemHeight = useMemo(() => {
    if (rowHeight && rowHeight > 0) return rowHeight;
    return S.ItemHeight;
  }, [rowHeight]);
  const refValue = React.useRef(null);
  const [ref, { width, height }] = useMeasure();
  useEffect(() => {
    if (refValue.current) ref(refValue.current as unknown as HTMLElement);
  }, [ref, refValue, width]);
  const columnWidth = useMemo(
    () =>
      columns.reduce((pre, cur) => {
        let value = 0;
        if (typeof cur.width === "number") value = cur.width;
        if (typeof cur.width !== "number" && cur.minWidth) value = cur.minWidth;
        if (!value) value = 50;
        return pre + value;
      }, 0) +
      S.TablePadding * 2,
    [columns]
  );
  const isHorizontalScroll = useMemo(
    () => columnWidth > width,
    [columnWidth, width]
  );
  const totalWidth = useMemo(() => {
    return isHorizontalScroll ? columnWidth : "auto";
  }, [columnWidth, isHorizontalScroll]);
  const hasScrollBar = useMemo(() => {
    const itemTotalHeight = ds.length * ItemHeight;
    if (itemTotalHeight > height) return true;
    if (scrollHeight && itemTotalHeight > scrollHeight) return true;
    return false;
  }, [ds.length, scrollHeight, height, ItemHeight]);
  const isNone = useMemo(() => !ds.length && !loading, [ds.length, loading]);
  const isLoading = useMemo(() => !ds.length && loading, [ds.length, loading]);
  return (
    <>
      <S.HorizontalTableWrapper style={wrapperStyle} isFullView={isFullView}>
        <S.HorizontalScrollWrapper
          ref={refValue}
          onScroll={onScroll}
          style={{
            maxHeight: scrollHeight ? scrollHeight + ItemHeight : "unset",
            borderBottom: disableFooter ? "none" : undefined,
          }}
        >
          <Header
            columns={columns}
            width={totalWidth}
            hasScrollBar={hasScrollBar}
            unitType={unitType}
            summaryData={summaryData}
            isScrollRightBottom={isScrollRightBottom}
            hasData={!!ds.length}
            isHorizontalScroll={isHorizontalScroll}
          />
          <S.TableContainer
            style={{
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
            }}
            isSticky={isNone || isLoading}
          >
            {isNone && <NoneData msg={noDataMsg} />}
            {isLoading && <Loading />}
            <TableBody
              columns={columns}
              scrollHeight={scrollHeight}
              width={totalWidth}
              ds={ds}
              unitType={unitType}
              rowKey={rowKey}
              itemHeight={ItemHeight}
              bottomBorderLeft={bottomBorderLeft}
              bottomBorderRight={bottomBorderRight}
              onItemClick={onItemClick}
              listCallback={listCallback}
              isScrollRightBottom={isScrollRightBottom}
              isHorizontalScroll={isHorizontalScroll}
            />
          </S.TableContainer>
        </S.HorizontalScrollWrapper>
        {!!total && !disableFooter && !!onSetPage && !!curPage && (
          <Footer
            onSetPage={onSetPage}
            total={total || 0}
            curPage={curPage}
            pageSize={pageSize || 50}
            onPageSizeChange={onPageSizeChange}
          />
        )}
      </S.HorizontalTableWrapper>
    </>
  );
}

const NoneData = React.memo(({ msg }: { msg?: React.ReactNode }) => (
  <S.FunctionWrapper>
    <S.NoneDataContent style={{ color: "#000" }}>
      {msg || messageText.ThereIsNoDataYetText}
    </S.NoneDataContent>
  </S.FunctionWrapper>
));

const Loading = React.memo(() => (
  <S.FunctionWrapper>
    <LoadingOutlined style={{ fontSize: 30 }} />
  </S.FunctionWrapper>
));

interface TableBodyProps {
  columns: IColumns[];
  total?: number;
  pageSize?: number;
  ds: Array<any>;
  scrollHeight?: number;
  width: number | string;
  unitType?: UnitType | "";
  rowKey?: string | rowKeyFunc;
  itemHeight: number;
  bottomBorderLeft?: number | string;
  bottomBorderRight?: number | string;
  onItemClick?: (data: any) => void;
  listCallback?: (data: any) => void;
  isScrollRightBottom: boolean;
  isHorizontalScroll: boolean;
}

const TableBody = React.memo(
  ({
    columns,
    width,
    ds,
    unitType,
    rowKey,
    itemHeight,
    bottomBorderLeft,
    bottomBorderRight,
    onItemClick,
    listCallback,
    isScrollRightBottom,
    isHorizontalScroll,
  }: TableBodyProps) => {
    const [hoverIndex, setIndex] = useStateValue<number | null>(null);
    const onSetIndex = useCallback((v) => setIndex(v), [setIndex]);
    const onRowClick = useCallback(
      (data) => {
        if (onItemClick) onItemClick(data);
      },
      [onItemClick]
    );
    const rowStyle = useMemo(() => {
      const result: React.CSSProperties = {
        height: itemHeight,
        cursor: onItemClick ? "pointer" : "auto",
      };
      return result;
    }, [itemHeight, onItemClick]);
    return (
      <S.BodyContainer style={{ width }}>
        {ds.map((rowData, rowIndex) => {
          let key = "";
          if (!rowKey) key = rowData.id;
          if (typeof rowKey === "string") key = rowData[rowKey];
          if (rowKey && typeof rowKey !== "string") key = rowKey(rowData);
          return (
            <S.Row
              key={key}
              style={rowStyle}
              bottomBorderLeft={bottomBorderLeft}
              bottomBorderRight={bottomBorderRight}
              onMouseEnter={onSetIndex.bind(null, rowIndex)}
              onMouseLeave={onSetIndex.bind(null, null)}
              isSiblingHover={!!onItemClick && hoverIndex === rowIndex + 1}
              isHover={!!onItemClick && hoverIndex === rowIndex}
              onClick={onItemClick ? onRowClick.bind(null, rowData) : undefined}
            >
              {columns.map((v) => (
                <TableItem
                  key={v.key + key}
                  column={v}
                  rowData={rowData}
                  unitType={unitType}
                  isScrollRightBottom={isScrollRightBottom}
                  isHorizontalScroll={isHorizontalScroll}
                  listCallback={listCallback}
                  isHover={!!onItemClick && hoverIndex === rowIndex}
                />
              ))}
            </S.Row>
          );
        })}
      </S.BodyContainer>
    );
  }
);
