import React from "react";
import styled, { css } from "styled-components";
import { BodyRegular, CaptionText } from "styles/fonts";
import { GrayF0, GrayD9, Success, Link, Gray, None } from "styles/colors";
import { UnitType, InventoryTypeName, SortType } from "constants/products";
import { formatCount } from "utils/index";
import { TimeUnit } from "utils/date";
import intl from "langs/intl";

import TableHeaderSortItem from "components/Table/SortItem";
import Explanation from "components/Explanation";

import { tableText } from "langs/formatText";

const StatusText = styled(BodyRegular)<{ hasDot: boolean }>`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(p: { color?: string }) => p.color || GrayD9};
  border: 1px solid;
  padding: 2px 4px;
  border-radius: 2px;
  border-color: ${(p: { color?: string }) => p.color || GrayD9};
  ${({ hasDot }) =>
    hasDot &&
    css`
      &::before {
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-right: 6px;
        background-color: ${(p: { color?: string }) => p.color || GrayD9};
      }
    `}
`;

const LabelText = styled(CaptionText)`
  margin: 0;
  color: ${(p: { color?: string; bg?: string }) => p.color || GrayD9};
  background-color: ${(p: { bg?: string; color?: string }) => p.bg || GrayF0};
  font-size: 12px;
  box-sizing: border-box;
  padding: 2px 4px;
  border-radius: 2px;
  margin-right: 5px;
`;

const EmailText = styled.span`
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SummaryTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SummaryExplanation = React.memo(() => (
  <SummaryTitleWrapper>
    <p style={{ margin: "0 6px 0 0" }}>{tableText.SummaryText}</p>
    <Explanation
      text={tableText.OverallMatricsText}
      direction="top"
      leftOffset={4}
    />
  </SummaryTitleWrapper>
));

export function formatStatus(v: string, hasDot = true) {
  let color = GrayD9;
  let bg = None;
  let result = "";
  switch (v) {
    default:
      break;
    case "ACTIVATED":
      color = Success;
      result = tableText.ActiveText;
      return (
        <StatusText color={color} hasDot={hasDot}>
          {result}
        </StatusText>
      );
    case "INACTIVE":
    case "ARCHIVED":
      result = tableText.InactiveText;
      return (
        <StatusText color={color} hasDot={hasDot}>
          {result}
        </StatusText>
      );
    case "PENDING":
      result = tableText.PendingConfirmationText;
      return (
        <StatusText color={color} hasDot={hasDot}>
          {result}
        </StatusText>
      );
    case "VERIFIED":
      color = Link;
      bg = "#F0F6FF";
      result = tableText.VerifiedText;
      return (
        <LabelText color={color} bg={bg}>
          {result}
        </LabelText>
      );
    case "UNVERIFIED":
      color = Gray;
      bg = "#FAFAFA";
      result = tableText.UnverifiedText;
      return (
        <LabelText color={color} bg={bg}>
          {result}
        </LabelText>
      );
  }
  return null;
}

// minWidth: 286 + 100 * (length - 1) = 786
export const DashboardColumns: IColumns[] = [
  {
    dataIndex: "location",
    key: "location",
    summaryTitle: <SummaryExplanation />,
    title: tableText.LocationText,
    minWidth: 190,
    flex: 1.5,
  },
  {
    dataIndex: "totalCost",
    key: "totalCost",
    summaryKey: "cost",
    title: tableText.TotalCostText,
    align: "right",
    minWidth: 150,
    flex: 1,
    hasCurrency: true,
    format: (v) => {
      if (!v) return "0";
      return `${formatCount(parseFloat(v), 2)}`;
    },
  },
  {
    dataIndex: "oneTimeStayers",
    key: "oneTimeStayers",
    title: tableText.OneTimeStayerText,
    align: "right",
    minWidth: 150,
    flex: 1,
    render: (v) => (v ? `${formatCount(v as number, 0)}` : "0"),
  },
  {
    dataIndex: "repeatStayers",
    key: "repeatStayers",
    title: tableText.RepeatStayerText,
    align: "right",
    minWidth: 150,
    flex: 1,
    render: (v) => (v ? `${formatCount(v as number, 0)}` : "0"),
  },
  {
    dataIndex: "totalUsageMinutes",
    key: "totalUsageMinutes",
    title: tableText.TotalUsageText,
    align: "right",
    minWidth: 150,
    flex: 1,
    headerDes: "",
    hasUnit: true,
    format: (v: number, type) => {
      if (!v) return "0";
      if (type === UnitType.HOUR) return `${formatCount(v / 60, 2)}`;
      return `${formatCount(v, 2)}`;
    },
  },
  {
    dataIndex: "avgUsageMinutes",
    key: "avgUsageMinutes",
    title: tableText.AvgUsageStayText,
    align: "right",
    minWidth: 150,
    flex: 1,
    headerDes: "",
    hasUnit: true,
    format: (v: number, type) => {
      if (!v) return "0";
      if (type === UnitType.HOUR) return `${formatCount(v / 60, 2)}`;
      return `${formatCount(v, 2)}`;
    },
  },
];
export const UsageColumns: IColumns[] = [
  {
    dataIndex: "id",
    key: "id",
    title: tableText.OrderIDText,
    minWidth: 110,
    flex: 1.5,
  },
  {
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    title: tableText.PhoneNumberText,
    minWidth: 120,
    flex: 1.2,
    render: (v, data: TOrder) => {
      if (!data.user?.mobile) return "-";
      return `${data.user?.countryCode} ${data.user?.mobile}`;
    },
  },
  {
    dataIndex: "name",
    key: "name",
    title: tableText.NameText,
    minWidth: 80,
    flex: 2,
    render: (_, row: TOrder) => (row?.user?.name || "").trim() || "-",
  },
  // {
  //   dataIndex: "entity",
  //   key: "entity",
  //   title: "Entity",
  //   minWidth: 158,
  //   flex: 2,
  //   render: (_, data: TOrder) => data.user?.team?.name || "-",
  // },
  // {
  //   dataIndex: "businessUnit",
  //   key: "businessUnit",
  //   title: "Business Unit",
  //   minWidth: 158,
  //   flex: 2,
  //   render: (_, data: TOrder) => data.user?.team?.subTeam?.name || "-",
  // },
  // {
  //   dataIndex: "employeeID",
  //   key: "employeeID",
  //   title: "Employee ID",
  //   minWidth: 158,
  //   flex: 2,
  //   render: (_, data: TOrder) => data.user?.employeeId || "-",
  // },
  {
    dataIndex: "spaceType",
    key: "spaceType",
    title: tableText.InventoryTypeText,
    minWidth: 125,
    flex: 1.5,
    render: (v, data: TOrder) =>
      (InventoryTypeName as any)[(data.product?.type || "").toUpperCase()] ||
      data.product?.type ||
      "-",
  },
  {
    dataIndex: "location",
    key: "location",
    title: tableText.LocationText,
    minWidth: 190,
    flex: 2.5,
    render: (v, data: TOrder) => data.product?.location?.name || "-",
  },
  {
    dataIndex: "beginTime",
    key: "beginTime",
    title: tableText.CheckInText,
    minWidth: 180,
    flex: 2,
  },
  {
    dataIndex: "endTime",
    key: "endTime",
    title: tableText.CheckOutText,
    minWidth: 180,
    flex: 2,
  },
  {
    dataIndex: "totalUsage",
    key: "totalUsage",
    title: tableText.UsageText,
    minWidth: 90,
    flex: 1,
    align: "right",
    render: (v, data: TOrder) => {
      if (!data.beginTime || !data.endTime) return 0;
      const start = new Date(data.beginTime);
      const end = new Date(data.endTime);
      if (!start || !end) return 0;
      const diff = end.getTime() - start.getTime();
      return diff || 0;
    },
    headerDes: "",
    hasUnit: true,
    format: (v: number, type) => {
      if (type === UnitType.HOUR) {
        if (!v) return "0";
        const result = `${formatCount(v / TimeUnit.Hour, 2)}`;
        if (result === "0.00") return "0.01";
        return result;
      }
      if (!v) return "0";
      if (type === UnitType.MINUTE)
        return `${formatCount(v / TimeUnit.Minute, 2)}`;
      return "0";
    },
  },
  {
    dataIndex: "totalAmount",
    key: "totalAmount",
    title: intl.formatMessage({
      defaultMessage: "Space Usage Fee",
    }),
    minWidth: 130,
    flex: 1,
    align: "right",
    headerDes: "",
    hasCurrency: true,
    render: (v) =>
      parseFloat(v ? `${formatCount(parseFloat(`${v}`), 2)}` : "0"),
  },
  {
    dataIndex: "discountAmount",
    key: "discountAmount",
    title: intl.formatMessage({
      defaultMessage: "Discount",
    }),
    minWidth: 114,
    flex: 1,
    align: "right",
    headerDes: "",
    hasCurrency: true,
    render: (v) =>
      parseFloat(v ? `${formatCount(parseFloat(`${v}`), 2)}` : "0"),
  },
  {
    dataIndex: "actualAmount",
    key: "actualAmount",
    title: intl.formatMessage({
      defaultMessage: "Total Fee",
    }),
    minWidth: 114,
    flex: 1,
    align: "right",
    headerDes: "",
    hasCurrency: true,
    render: (v) =>
      parseFloat(v ? `${formatCount(parseFloat(`${v}`), 2)}` : "0"),
  },
];
export const UsersColumns: IColumns[] = [
  {
    dataIndex: "name",
    key: "name",
    title: tableText.UserText,
    flex: 2,
    minWidth: 180,
  },
  {
    dataIndex: "mobile",
    key: "mobile",
    title: tableText.PhoneNumberText,
    minWidth: 120,
    flex: 1.2,
    render: (v, rowData: TUserItem) => {
      if (!v) return "-";
      return (
        <>
          {rowData.countryCode || "-"} {v || "-"}
        </>
      );
    },
  },
  {
    dataIndex: "email",
    key: "email",
    title: tableText.EmailText,
    flex: 2.5,
    minWidth: 200,
    render: (v, rowData: TUserItem) => {
      if (!v) return "-";
      return (
        <>
          <EmailText>{v}</EmailText>
          {formatStatus(rowData.emailVerifyStatus)}
        </>
      );
    },
  },
  {
    dataIndex: "status",
    key: "status",
    title: tableText.StatusText,
    minWidth: 90,
    flex: 1,
    render: (v) => formatStatus(v as string),
  },
  {
    dataIndex: "joinedAt",
    key: "joinedAt",
    title: tableText.RegisterTimeText,
    flex: 1.6,
    minWidth: 180,
    titleComponent: TableHeaderSortItem,
    defaultSort: SortType.DESC,
  },
];
