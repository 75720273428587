import React, {
  useState,
  useLayoutEffect,
  useCallback,
  ReactNode,
  useMemo,
  useRef,
} from "react";
import Portal from "components/Sibling/Portal";
import * as S from "./styles.explanation";
export interface ExplanationProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: React.ReactNode;
  children?: ReactNode;
  topOffset?: number;
  leftOffset?: number;
  size?: number;
  direction?: "top" | "bottom";
  horizontalDirection?: "left" | "right";
  isBottomIconCenter?: boolean;
  defaultContentHeight?: number;
  iconStyles?: React.CSSProperties;
  hideTriangle?: boolean;
  isMobile?: boolean;
  tooltipSize?: "sm" | "md" | "lg";
  wrapperStyle?: React.CSSProperties;
  parentNodeId?: string;
}

const TriangleHeight = 8;
const DefaultContentHeight = 30 + TriangleHeight;

const Tooltip = ({
  text,
  children,
  topOffset = 0,
  leftOffset = 0,
  size,
  direction = "top",
  horizontalDirection,
  iconStyles,
  defaultContentHeight,
  isBottomIconCenter,
  hideTriangle = true,
  style,
  isMobile,
  tooltipSize = "md",
  wrapperStyle,
  parentNodeId,
  ...rest
}: ExplanationProps) => {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState<number>(0);
  const [iconLeft, setIconLeft] = useState<number>(0);
  const [show, setShow] = useState(false);
  const [isPositionRight, setPositionRight] = useState(false);
  const [, setPositionLeft] = useState(false);
  const [contentHeight, setContentHeight] = useState(
    defaultContentHeight || DefaultContentHeight
  );
  const [contentWidth, setContentWidth] = useState(0);
  const onUpdateContentSize = useCallback((w, h) => {
    setContentHeight(h);
    setContentWidth(w);
  }, []);
  const contentStyle = useMemo(() => {
    const opacity = contentWidth ? 1 : 0;
    if (style) return { ...style, top, left, opacity };
    return { top, left, opacity };
  }, [style, top, left, contentWidth]);
  const iconStyle = useMemo(() => {
    if (size)
      return iconStyles
        ? { ...iconStyles, width: size, height: size }
        : { width: size, height: size };
    return iconStyles;
  }, [size, iconStyles]);
  const ref = React.useRef<HTMLDivElement>(null);
  const updateRect = useCallback(
    (data: DOMRect) => {
      // default center
      const defaultLeft = data.left + data.width / 2 - contentWidth / 2;
      let shouldPositionRight = horizontalDirection === "right";
      let shouldPositionLeft = horizontalDirection === "left";
      if (
        data.left + contentWidth - leftOffset - window.innerWidth > 0 &&
        contentWidth > 0
      ) {
        shouldPositionRight = true;
      }
      if (defaultLeft < 0 && !shouldPositionRight) {
        shouldPositionLeft = true;
      }
      setPositionRight(shouldPositionRight);
      setPositionLeft(shouldPositionLeft);
      let toIconLeft = 0;
      // is position center (default)
      let toLeft = defaultLeft;
      // is position left
      if (shouldPositionLeft) toLeft = data.left;
      // is position right
      if (shouldPositionRight) toLeft = data.left + data.width - contentWidth;
      if (isMobile) toLeft = Math.max(defaultLeft, 1);
      // is after item
      let toTop = data.bottom + topOffset;
      // is before item
      if (direction === "top") toTop = data.top - contentHeight + topOffset;
      // triangle default position
      if (isBottomIconCenter && !hideTriangle) toIconLeft = contentWidth / 2;
      setTop(toTop);
      setLeft(toLeft);
      if (toIconLeft) setIconLeft(toIconLeft);
    },
    [
      contentWidth,
      leftOffset,
      isBottomIconCenter,
      hideTriangle,
      topOffset,
      direction,
      horizontalDirection,
      contentHeight,
      isMobile,
    ]
  );
  const onHover = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
    ) => {
      if (!e) return;
      if (rest.onMouseOver)
        rest.onMouseOver(e as React.MouseEvent<HTMLDivElement>);
      if (rest.onTouchCancel)
        rest.onTouchCancel(e as React.TouchEvent<HTMLDivElement>);
      if (ref.current) updateRect(ref.current.getBoundingClientRect());
      setShow(true);
    },
    [rest, updateRect]
  );
  const onMouseLeave = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
    ) => {
      if (!e) return;
      setShow(false);
      if (rest.onMouseLeave)
        rest.onMouseLeave(e as React.MouseEvent<HTMLDivElement>);
      if (rest.onTouchEnd)
        rest.onTouchEnd(e as React.TouchEvent<HTMLDivElement>);
    },
    [rest]
  );
  const onMouseOut = useCallback(() => setShow(false), []);
  useLayoutEffect(() => {
    if (ref.current) updateRect(ref.current.getBoundingClientRect());
  }, [ref, updateRect, contentWidth]);
  return (
    <>
      <S.Wrapper
        ref={ref}
        {...rest}
        style={wrapperStyle}
        onMouseOver={onHover}
        onTouchStart={onHover}
        onTouchCancel={onMouseLeave}
        onMouseLeave={onMouseLeave}
        onMouseOut={onMouseOut}
      >
        {!!children ? children : <S.IconExplain style={iconStyle} />}
      </S.Wrapper>
      {!!text && (
        <Portal id={parentNodeId}>
          {show && (
            <ExplanationContent
              text={text}
              style={contentStyle}
              direction={direction}
              updateSize={onUpdateContentSize}
              isPositionRight={isPositionRight}
              isIconCenter={isBottomIconCenter}
              hideTriangle={hideTriangle}
              isMobile={isMobile}
              iconLeft={iconLeft}
              size={tooltipSize}
            />
          )}
        </Portal>
      )}
    </>
  );
};

interface ContentProps {
  text?: React.ReactNode;
  style?: React.CSSProperties;
  direction?: "top" | "bottom";
  updateSize?: (width: number, height: number) => void;
  isPositionRight?: boolean;
  isIconCenter?: boolean;
  hideTriangle?: boolean;
  isMobile?: boolean;
  iconLeft?: number;
  size?: "sm" | "md" | "lg";
}

const ExplanationContent = React.memo(
  ({
    text,
    style,
    direction,
    updateSize,
    isPositionRight,
    isIconCenter,
    hideTriangle,
    isMobile,
    iconLeft,
    size,
  }: ContentProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [initd, setInitd] = useState(false);
    React.useEffect(() => {
      if (!ref.current) return;
      if (!updateSize) return;
      updateSize(
        ref.current.clientWidth,
        ref.current.clientHeight + TriangleHeight
      );
      setInitd(true);
    }, [direction, ref, updateSize]);
    return (
      <S.TextWrapper
        style={initd ? style : { opacity: 0 }}
        isTop={direction === "top"}
        isRight={!!isPositionRight}
        isIconCenter={isIconCenter}
        hideTriangle={hideTriangle}
        isMobile={isMobile}
        iconLeft={iconLeft}
        size={size}
      >
        <S.DesText ref={ref} style={{ width: style?.width || "unset" }}>
          {text}
        </S.DesText>
      </S.TextWrapper>
    );
  }
);

export default Tooltip;

export function renderExplanation({ text, ...rest }: ExplanationProps) {
  return (
    <Tooltip {...rest} text={text}>
      <S.TableText>{text}</S.TableText>
    </Tooltip>
  );
}

export function displayTooltip(
  v: string,
  length = 20,
  hideTriangle?: boolean,
  config?: ExplanationProps
) {
  if (v.length > length) {
    return renderExplanation({
      ...(config || {}),
      text: v,
      direction: "top",
      hideTriangle,
    });
  }
  return v;
}
