import intl from "langs/intl";

export const CreateBusinessAccountText = intl.formatMessage({
  defaultMessage: "Create a Business Account",
});
export const PaymentMethodText = intl.formatMessage({
  defaultMessage: "Payment Method",
});
export const BillingInfoText = intl.formatMessage({
  defaultMessage: "Billing Info",
});
export const BillingContactFirstNameText = intl.formatMessage({
  defaultMessage: "Billing Contact First Name",
});
export const BillingContactLastNameText = intl.formatMessage({
  defaultMessage: "Billing Contact Last Name",
});
export const BillingContactEmailText = intl.formatMessage({
  defaultMessage: "Billing Contact Email",
});
export const BillingContactPhoneNumberText = intl.formatMessage({
  defaultMessage: "Billing Contact Phone Number",
});
export const BillingAddressLineText = (number: number) =>
  intl.formatMessage(
    {
      defaultMessage: "Billing Address Line {number}",
    },
    { number }
  );
export const PleaseInputBillingAddressText = intl.formatMessage({
  defaultMessage: "Please input billing address line 1.",
});
export const BusinessRegistrationNumberText = intl.formatMessage({
  defaultMessage: "Business Registration Number",
});
export const NumberOfEmployeesText = intl.formatMessage({
  defaultMessage: "Number of Employees",
});
export const QuotaText = intl.formatMessage({
  defaultMessage: "Quota/Person/Month",
});
export const CreditCardNumberText = intl.formatMessage({
  defaultMessage: "Credit Card Number",
});
export const NewCreditCardNumberText = intl.formatMessage({
  defaultMessage: "New Credit Card Number",
});
export const CVCText = intl.formatMessage({
  defaultMessage: "CVC",
});
export const YearAndMonthText = intl.formatMessage({
  defaultMessage: "MM/YY",
});
export const ExpirationDateText = intl.formatMessage({
  defaultMessage: "Expiration Date",
});
export const ChangeCreditCardText = intl.formatMessage({
  defaultMessage: "Change to a New Credit Card",
});
export const TipCompanyDisplayNameText = intl.formatMessage({
  defaultMessage:
    "This will show up as the name of the profile in the Switch App.",
});
export const TipEditUsageText = intl.formatMessage({
  defaultMessage: "You can edit usage rules from Company Profile at any time.",
});
export const TipPaymentText = intl.formatMessage({
  defaultMessage:
    "This payment method will be shared across all users on your Switch for Business account. The people you add to this account won’t see your payment information details.",
});
export const TipBillingText = intl.formatMessage({
  defaultMessage:
    "You can edit billing info from the Payment Page at any time.",
});
export const IsTheBillingContactText = intl.formatMessage({
  defaultMessage: "I am the Billing Contact",
});
export const PleaseAddPaymentMethodText = intl.formatMessage({
  defaultMessage: "Please add payment method.",
});
export const PleaseFinishPaymentMethodText = intl.formatMessage({
  defaultMessage: "Please finish payment method.",
});
export const ExpiredTitleText = intl.formatMessage({
  defaultMessage: "Oops! This Link Has Expired",
});
export const AddEmployeesText = intl.formatMessage({
  defaultMessage: "Add Employees",
});
export const RequestReceivedText = intl.formatMessage({
  defaultMessage: "Request Received",
});
export const GetInTouchTipAText = intl.formatMessage({
  defaultMessage:
    "We've received your Switch for Business account request as well as the payment option of monthly bank transfer.",
});
export const GetInTouchTipBText = intl.formatMessage({
  defaultMessage: "Our consultant will contact you shortly.",
});
export const SuccessTitleText = (isMobile: boolean) =>
  intl.formatMessage(
    {
      description:
        "High-Five! You've set up Switch for Business. Now choose who can use it.",
      defaultMessage:
        "High-Five! {newLine}You've set up Switch for Business. {enter}Now choose who can use it.",
    },
    { newLine: isMobile ? <br /> : undefined, enter: <br /> }
  );
export const HereText = intl.formatMessage({
  defaultMessage: "here",
});
export const RedirectSubmitText = (here: React.ReactNode) =>
  intl.formatMessage(
    {
      defaultMessage:
        "Prefer monthly bank transfer? Click {here} to submit your request.",
    },
    {
      here,
    }
  );
export const ExpiredRedirectText = (here: React.ReactNode) =>
  intl.formatMessage(
    {
      defaultMessage: "Please click {here} to sign up again.",
    },
    { here }
  );

export const SuccessVerifyEmailText = (email: React.ReactNode) =>
  intl.formatMessage(
    {
      description: "Your email name@email.com is verified.",
      defaultMessage: "Your email {email} is verified.",
    },
    { email }
  );

export const SuccessToastText = (email: string) =>
  intl.formatMessage(
    {
      description:
        "We have sent the password of your Switch for Business Portal account to your email name@email.com. If you have not received the email after a few minutes, please check your spam folder. ",
      defaultMessage:
        "We have sent the password of your Switch for Business Portal account to your email {email}. If you have not received the email after a few minutes, please check your spam folder. ",
    },
    { email }
  );
