import styled from "styled-components";
import { Layout as RawLayout, Menu } from "antd";

import { ButtonEffect } from "components/Buttons/styles.button";

import * as C from "styles/colors";
import {
  Subtitle,
  BodyCSS,
  Body2CSS,
  Subtitle2CSS,
  Caption1CSS,
} from "styles/fonts";
import { FlexColumn, FlexRow, BoxShadow, FlexCenter } from "styles/common";
import { ReactComponent as IconArrow } from "images/icon-arrow-to-right.svg";
import { ReactComponent as IconBookSVG } from "images/icon-book.svg";
import { ReactComponent as IconCheckedSVG } from "images/icon-checked-outlined.svg";

export const HeaderHeight = 40;
export const MinWindowWidth = 1280;
export const LeftSiderWidth = 208;
export const MinTableWIdth = 1008;

const { Header: RawHeader, Content: RawContent, Sider } = RawLayout;

export const Layout = styled(RawLayout)`
  height: 100%;
`;

export const LogoWrapper = styled.div`
  height: 116px;
  flex: none;
  ${FlexColumn}
  align-items: flex-start;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-left: 37px;
  padding-bottom: 18px;
`;

export const Logo = styled.div`
  width: 90px;
  height: 32px;
  margin-bottom: 7.8px;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    fill: ${C.Primary};
  }
`;

export const LeftSider = styled(Sider)`
  overflow: hidden auto;
  height: 100vh;
  position: fixed;
  left: 0;
  user-select: none;
  background-color: ${C.Neutral10};
  z-index: 11;
`;

export const LeftWrapper = styled(Menu)`
  box-sizing: border-box;
  padding: 28px 0 0 0;
  background-color: transparent;
  border: none;
`;

export const LeftSliderItem = styled(Menu.Item)<{ issub?: string }>`
  display: flex;
  align-items: center;
  padding: ${({ issub }) => (issub ? "0 18px 0 28px" : "0 18px")} !important;
  margin-top: ${({ issub }) => (issub ? 8 : 0)}px !important;
  margin-bottom: ${({ issub }) => (issub ? 0 : 8)}px !important;
  border-radius: 2px;

  &:hover {
    background-color: ${C.Neutral9};

    svg {
      fill: #fff;
    }
  }

  &.ant-menu-item-selected {
    color: #fff !important;
    background-color: ${C.Neutral8} !important;
  }

  a {
    ${BodyCSS}
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &.ant-menu-item-selected a {
    color: #fff;
    ${Subtitle}
  }

  &::after {
    display: none;
  }
`;

interface SubMenuProps {
  hasbottomline?: string;
}

export const LeftSliderSubMenu = styled(Menu.SubMenu)<SubMenuProps>`
  margin-bottom: 8px;
  padding-bottom: 0 !important;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 16px;
    height: ${({ hasbottomline }) => (hasbottomline ? 1 : 0)}px;
    background-color: ${C.Neutral9};
  }

  .ant-menu-submenu-arrow {
    color: ${C.Neutral7};

    &:hover {
      color: #fff;
    }
  }

  .ant-menu-submenu-title {
    padding-left: 18px !important;
    ${BodyCSS}
    color: #fff;

    .ant-menu-submenu-arrow {
      color: ${C.Neutral7} !important;
    }

    &:hover {
      color: #fff;
      background-color: ${C.Neutral9};

      .ant-menu-submenu-arrow {
        color: #fff !important;
      }
    }
  }

  ul {
    background: transparent !important;
  }
`;

export const RightContent = styled(Layout)`
  margin-left: ${LeftSiderWidth}px;
  min-width: ${MinWindowWidth - LeftSiderWidth}px;
  min-height: 100vh;
`;

export const Content = styled(RawContent)`
  ${FlexColumn}
  flex: 1;
  margin: 0;
  box-sizing: border-box;
  padding: ${HeaderHeight}px 0 8px 0;
  overflow: auto;
  background-color: #fff;
`;

export const InnerContent = styled.div`
  margin: 0 auto;
  ${FlexColumn}
  flex: 1;
`;

export const Header = styled(RawHeader)`
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  user-select: none;
  background-color: #fff;
  height: ${HeaderHeight}px;
  line-height: ${HeaderHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
`;

export const HeaderSplit = styled.div`
  width: 1px;
  height: 10px;
  overflow: hidden;
  flex: 0 0 auto;
  background: ${C.GrayD9};
  margin: 0 12px;
`;

export const Blank = styled.div`
  flex: 1;
`;

export const LogoutButton = styled.div`
  ${FlexRow}
  align-items: flex-start;
  justify-content: center;
  padding: 12px 0 !important;
  ${BodyCSS}
  line-height: 16px !important;
  color: ${C.Neutral8};
  background-color: transparent !important;

  &:hover {
    cursor: pointer;
    color: ${C.Neutral};
  }
`;

export const IconArrow2Right = styled(IconArrow)`
  fill: ${C.Primary};
  width: 12px;
  height: 8px;
`;

export const IconBook = styled(IconBookSVG)`
  fill: ${(p: { isact: string }) => (p.isact ? "#fff" : C.Neutral7)};
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transition: fill 0.2s ease;
`;

export const CountryWrapper = styled.div<{ isShow?: boolean }>`
  position: fixed;
  z-index: 3;
  background-color: #fff;
  max-height: 275px;
  width: 360px;
  box-sizing: border-box;
  padding: 24px 0;
  ${BoxShadow}
  overflow: hidden;
  transition: transform 0.2s ease, opacity 0.2s;
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  transform: translateY(${({ isShow }) => (isShow ? 0 : "-20%")});
  pointer-events: ${({ isShow }) => (isShow ? "auto" : "none")};
  ${FlexColumn}
`;

export const CountryScrollContainer = styled.div<{ isShow?: boolean }>`
  flex: 1;
  overflow-y: auto;
  pointer-events: ${({ isShow }) => (isShow ? "auto" : "none")};
`;

export const CountryTitle = styled.p<{ isShow?: boolean }>`
  ${Subtitle}
  margin: 0 0 16px 16px;
  color: ${C.Neutral};
  flex: none;
  pointer-events: ${({ isShow }) => (isShow ? "auto" : "none")};
`;

interface OptionItemProps {
  isSub?: boolean;
  isShow?: boolean;
}

export const IconSelected = styled(IconCheckedSVG)`
  position: absolute;
  width: 16px;
  height: 16px;
  right: 24px;
  top: 50%;
  margin-top: -8px;
  pointer-events: none;
  fill: ${C.Primary} !important;
`;

export const OptionItem = styled.div<OptionItemProps>`
  ${({ isSub }) => (isSub ? Subtitle2CSS : Body2CSS)}
  ${FlexRow}
  ${ButtonEffect}
  padding: 0 48px 0 22px;
  box-sizing: border-box;
  height: 32px;
  align-items: center;
  background-color: ${({ isSub }) => (isSub ? C.Neutral4 : C.None)};
  pointer-events: ${(p) => (p.isSub || !p.isShow ? "none" : "auto")};
  color: ${C.Neutral8};
  position: relative;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const BrandLabel = styled.p`
  ${Caption1CSS};
  margin: 0;
  color: ${C.Neutral7};
  box-sizing: border-box;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  ${FlexRow}
  ${FlexCenter}
`;

export const CountBadge = styled.span`
  margin: 0;
  ${Caption1CSS}
  font-weight: normal;
  line-height: 16px;
  color: #fff;
  position: relative;
  background-color: ${C.Primary};
  box-sizing: border-box;
  padding: 0 5px;
  border-radius: 8px;
  margin-left: 11px;
`;

export const HelpContainer = styled.div`
  ${Body2CSS}
  color: ${C.Neutral8};
  margin-left: 6px;
  margin-right: 14px;
  &:hover {
    color: ${C.Neutral};
  }
`;

export const HelpWrapper = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
