import React, { useMemo } from "react";

import * as S from "./styles.table";
import { Neutral2 } from "styles/colors";

interface TableItemProps {
  column: IColumns;
  rowData: any;
  unitType?: UnitType | "";
  isScrollRightBottom: boolean;
  isHorizontalScroll: boolean;
  listCallback?: (data: any) => void;
  isHover?: boolean;
}

export function TableItem({
  column,
  rowData,
  unitType,
  isScrollRightBottom,
  listCallback,
  isHorizontalScroll,
  isHover,
}: TableItemProps) {
  const content = useMemo(() => {
    let result = (rowData as any)[column.key] || "";
    if (column.render) {
      result =
        column.render((rowData as any)[column.key], rowData, listCallback) ||
        "";
    }
    if (column.format && unitType) {
      result = column.format(result, unitType) || "";
    }
    return typeof result === "string" ? (
      <S.TableText>{result || "-"}</S.TableText>
    ) : (
      result || "-"
    );
  }, [column, rowData, unitType, listCallback]);
  const showStickShadow = useMemo(
    () =>
      !isScrollRightBottom &&
      column.style &&
      isHorizontalScroll &&
      column.style.position === "sticky",
    [column.style, isHorizontalScroll, isScrollRightBottom]
  );
  const backgroundColor = useMemo(() => {
    if (column.style?.position === "sticky") {
      if (isHover) return Neutral2;
      return "#fff";
    }
    return undefined;
  }, [column.style?.position, isHover]);
  return (
    <S.TableItemWrapper
      key={column.key + rowData.id}
      style={{
        backgroundColor,
        ...(column.style || {}),
        width: column.width || "auto",
        minWidth: column.minWidth || "initial",
        position: isHorizontalScroll ? column.style?.position : "relative",
      }}
      flex={column.flex || (column.width ? "none" : 1)}
      align={column.align || "left"}
      showStickShadow={showStickShadow}
    >
      {content}
    </S.TableItemWrapper>
  );
}
