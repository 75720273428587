import styled, { css } from "styled-components";
import { Neutral9 } from "styles/colors";
import { CaptionCSS } from "styles/fonts";
import { ReactComponent as IconExplainSVG } from "images/icon-explanation.svg";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
  box-sizing: border-box;

  &:hover {
    > div {
      opacity: 1;
    }
  }
`;

export const IconExplain = styled(IconExplainSVG)`
  width: 12px;
  height: 12px;
  fill: ${Neutral9};
  position: relative;
  border: 1px solid transparent;
`;

interface TextWrapperProps {
  isTop?: boolean;
  isRight?: boolean;
  isIconCenter?: boolean;
  hideTriangle?: boolean;
  isMobile?: boolean;
  iconLeft?: number;
  size?: "sm" | "md" | "lg";
}

export const DesText = styled.div`
  ${CaptionCSS}
  color: #fff;
  background-color: ${Neutral9};
  box-sizing: border-box;
  padding: 8px;
  border-radius: 2px;
  letter-spacing: 0.01em;
  position: relative;
  text-align: left;
  overflow: visible;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.05),
    6px 10px 10px rgba(0, 0, 0, 0.03);
`;

function getMaxWidth(props: TextWrapperProps) {
  if (props.isMobile) return "90vw";
  if (props.size === "lg") return "328px";
  if (props.size === "md") return "280px";
  return "328px";
}

export const TextWrapper = styled.div<TextWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  max-width: ${(p) => getMaxWidth(p)};
  word-wrap: break-word;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: flex-start;
  ${({ isMobile }) =>
    isMobile
      ? css`
          align-items: flex-start;
        `
      : undefined}
  justify-content: flex-start;
  opacity: 1;
  pointer-events: none;
  z-index: 12;

  ${DesText} {
    &::after {
      content: "";
      display: ${({ hideTriangle }) => (hideTriangle ? "none" : "block")};
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      ${({ isRight }) =>
        isRight
          ? css`
              right: 5px;
            `
          : css`
              left: 5px;
            `}
      ${({ iconLeft }) =>
        iconLeft
          ? css`
              right: unset;
              left: ${iconLeft}px;
            `
          : undefined}
      ${({ isTop }) =>
        isTop
          ? css`
              bottom: -7px;
              border-width: 7.79px 5.25px 0px 5.25px;
              border-color: ${Neutral9} transparent transparent;
            `
          : css`
              top: -7.79px;
              border-width: 0 5.25px 7.79px 5.25px;
              border-color: transparent transparent ${Neutral9};
            `}
    }
  }
`;

export const TableText = styled.p`
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
