import useSWR from "swr";

import { useAccessToken } from "store/accessToken";

import { getDashboard } from "service/v1/dashboard";
import { TimeUnit } from "utils/date";

export function useDetailLoader(
  page: number,
  size: number,
  productTypes: TInventoryType | "",
  start: string,
  end: string,
  requestKey?: string
) {
  const { curCountry } = useAccessToken();
  const { data, mutate, isValidating } = useSWR(
    [
      `${requestKey}dashboard`,
      page,
      size,
      productTypes,
      start,
      end,
      curCountry?.code,
    ],
    getDashboard,
    { refreshInterval: TimeUnit.Minute * 5, errorRetryCount: 10 }
  );
  let ds: TDashboardItem[] = [];
  let counts = 0;
  let currency = "";
  if (data) {
    const { items, total } = data;
    ds = items || [];
    counts = total || 0;
    for (const i of items) {
      if (currency) break;
      currency = i.currency;
    }
  }
  return {
    data: ds.slice(0, size),
    mutate,
    isValidating,
    total: counts,
    currency,
  };
}
