import React from "react";
import ReactDom from "react-dom";

import { attachProps } from "./Modal";

interface MessageProps {
  content: string | React.ReactNode;
  animDuration?: number;
}

// todo(noah) refactor with context;
export function messageUtil({ content, animDuration = 400 }: MessageProps) {
  if (!content) return;
  let initd = false;
  if (initd) return;
  const isString = typeof content === "string";
  let showed = false;
  let entering = false;
  let leaving = false;
  initd = true;
  const rootEl = document.getElementById("modals");
  const containerEl = document.createElement("div");
  if (rootEl) rootEl.appendChild(containerEl);
  // close
  const close = () => {
    if (leaving || !rootEl || !entering) return;
    leaving = true;
    entering = false;
    setTimeout(() => {
      if (rootEl) rootEl.removeChild(containerEl);
      leaving = false;
    }, animDuration);
  };
  // show
  const init = () => {
    entering = true;
    if (isString) {
      // todo(noah)
      //   ReactDom.createPortal(content, containerEl);
    }
    if (!isString) {
      return ReactDom.createPortal(
        attachProps(content as any, { close }),
        containerEl
      );
    }
  };
  return { close, init, leaving, entering, showed };
}
