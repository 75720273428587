import request from "../request";
import { switchServiceUrl } from "service/request/baseUrl";
import type { TGetPortalRegionResp } from "@REInventVenturesPteLtd/sui/lib/components/Dropdown/RegionDropdown";

export interface ILoginRequestParam {
  username: string;
  password: string;
  fingerPrintId: string;
}

export const login = async (data: ILoginRequestParam) => {
  const formBody = Object.keys(data)
    .map(
      (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent((data as any)[key])
    )
    .join("&");
  return await request<IAccessToken>({
    method: "POST",
    path: "/enterprise/v1/login",
    data: formBody,
    noToken: true,
    contentType: "application/x-www-form-urlencoded",
    includeCredentials: true,
  });
};

export const logout = async () => {
  return await request({
    method: "DELETE",
    path: "/enterprise/v1/logout",
    refreshOnUnauthorized: true,
    includeCredentials: true,
  });
};

export const token = async () => {
  return await request<IAccessToken>({
    method: "POST",
    path: "/enterprise/v1/refresh-token",
    includeCredentials: true,
    noToken: true,
  });
};

export const getUserCountries = async () => {
  return await request<GetUserCountriesResp>({
    method: "GET",
    path: "/enterprise/v1/country/codes",
    refreshOnUnauthorized: true,
  });
};
export const getPortalRegion = async () => {
  return await request<TGetPortalRegionResp>({
    method: "GET",
    path: "/enterprise/v1/country/codes",
    refreshOnUnauthorized: true,
  });
};

export const getCountries = () =>
  request<ICountry[]>({
    method: "GET",
    path: "/enterprise/v1/public/countries",
    refreshOnUnauthorized: true,
  });

export const getNoHKCountries = () =>
  request<ICountry[]>({
    method: "GET",
    path: "/enterprise/v2/public/countries",
    refreshOnUnauthorized: true,
  });

export const getCountryCodes = async () => {
  const list = await request<ICountryCode[]>({
    method: "GET",
    path: `/v1/country-code`,
    refreshOnUnauthorized: true,
    replaceBaseUrl: `${switchServiceUrl()}/api`,
  });
  list.sort((a, b) => (a.countryIndex < b.countryIndex ? -1 : 1));
  return list;
};
