import intl from "langs/intl";

export const UnpaidText = intl.formatMessage({
  defaultMessage:
    "Your company account has an unpaid visit. Please note, you need to pay for the previous visit and then your employees can start a new visit.",
});

export const PayNowText = intl.formatMessage({
  defaultMessage: "Pay Now",
});

export const ChangeCreditCardText = intl.formatMessage({
  defaultMessage: "Change Credit Card",
});
