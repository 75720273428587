import useSWR from "swr";
import request, {
  ListRequestMaxLength,
  requestListDataInBatches,
} from "../request";
import type { IRequestParams, IRequestParamsInBatches } from "../request";
import { TimeUnit } from "utils/date";
import { getTokenValue } from "../request/accessToken";
import { productText } from "langs/formatText";

export enum BookingUsageStatus {
  UPCOMING = "UPCOMING",
  ONGOING = "ONGOING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export const BookingUsageStatusText = {
  [BookingUsageStatus.UPCOMING]: productText.UpcomingText,
  [BookingUsageStatus.ONGOING]: productText.OngoingText,
  [BookingUsageStatus.COMPLETED]: productText.CompletedText,
  [BookingUsageStatus.CANCELLED]: productText.CancelledText,
};

export enum BookingOrderStatus {
  ONGOING = "ONGOING",
  UNPAID = "UNPAID",
  PAYMENT_PROCESSING = "PAYMENT_PROCESSING",
  PAID = "PAID",
  REFUNDED = "REFUNDED",
  PARTIAL_REFUNDED = "PARTIAL_REFUNDED",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
  UNKNOWN = "UNKNOWN",
  CANCELLED = "CANCELLED",
}

export const BookingOrderStatusText = {
  [BookingOrderStatus.ONGOING]: productText.OngoingText,
  [BookingOrderStatus.UNPAID]: productText.AwaitingPaymentText,
  [BookingOrderStatus.COMPLETED]: productText.CompletedText,
  [BookingOrderStatus.CANCELLED]: productText.CancelledText,
  [BookingOrderStatus.PAYMENT_PROCESSING]: productText.ProcessingPaymentText,
  [BookingOrderStatus.REFUNDED]: productText.RefundedText,
  [BookingOrderStatus.PARTIAL_REFUNDED]: productText.PartiallyRefundedText,
  [BookingOrderStatus.PAID]: productText.BookedText,
  [BookingOrderStatus.EXPIRED]: productText.ExpiredText,
};

export const BookingOrderStatusTextForUsage = {
  ...BookingOrderStatusText,
  [BookingOrderStatus.PAID]: productText.PaidText,
};

export const getBookingUsageCounts = async (
  _url: string,
  city?: string,
  spaceId?: string
) =>
  request<{ upcoming: number; ongoing: number }>({
    method: "POST",
    path: `/enterprise/v1/bookings/count`,
    refreshOnUnauthorized: true,
    data: { city, spaceId },
  });

export function useBookingCountLoader(city?: string, spaceId?: string) {
  const accessToken = getTokenValue();
  const { data, mutate, isValidating } = useSWR(
    accessToken ? [`${accessToken}/bookings/count`, city, spaceId] : null,
    accessToken ? getBookingUsageCounts : null,
    {
      refreshInterval: TimeUnit.Minute * 5,
      shouldRetryOnError: !!accessToken,
      errorRetryCount: 10,
    }
  );
  return { data, mutate, isValidating };
}

export const getBookingUsage = async (
  _url: string,
  page: number,
  size: number,
  status: BookingUsageStatus,
  startTime?: string,
  endTime?: string,
  city?: string,
  inventoryType?: string, // InventoryType str
  spaceId?: string,
  capacities?: Array<{ gte: number; lte: number }>
) => {
  const params: IRequestParams = {
    method: "POST",
    path: `/enterprise/v1/bookings/list`,
    refreshOnUnauthorized: true,
    data: {
      page,
      size,
      inventoryTypes: inventoryType ? inventoryType.split(",") : undefined,
      startTime,
      endTime,
      city,
      capacities,
      spaceId,
      status,
    },
  };
  if (size > ListRequestMaxLength && page === 1) {
    // from download request
    return requestListDataInBatches<TBookingListResp>(
      params as IRequestParamsInBatches,
      size
    );
  }
  return request<TBookingListResp>(params);
};

export function useBookingUsage(
  page: number,
  size: number,
  status: BookingUsageStatus,
  start?: string,
  end?: string,
  city?: string,
  inventoryType?: string, // InventoryType str
  spaceId?: string,
  capacities?: Array<{ gte: number; lte: number }>
) {
  const accessToken = getTokenValue();
  const { data, mutate, isValidating } = useSWR(
    [
      `${accessToken}/bookings/list`,
      page,
      size,
      status,
      start,
      end,
      city,
      inventoryType,
      spaceId,
      capacities,
    ],
    getBookingUsage,
    { refreshInterval: TimeUnit.Minute * 5, errorRetryCount: 10 }
  );
  const total = data?.total || 0;
  let result: TBookingDetail[] = [];
  let currency = "";
  if (data && data.items) {
    result = [...data.items];
  }
  return {
    result,
    mutate,
    isValidating,
    total,
    currency,
  };
}
