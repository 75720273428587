import styled from "styled-components";
import { BoxShadow, FlexCenter, FlexRow } from "styles/common";
import { Body2CSS } from "styles/fonts";
import { ButtonEffect } from "../styles.button";
import { ReactComponent as IconArrowDown } from "images/icon-arrow-pagination-down.svg";

export const OptionsPadding = 8;
export const OptionsMargin = 4;
export const OptionHeight = 36;
export const OptionWidth = 126;

export const OptionsWrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  padding: ${`${OptionsPadding}px 0`};
  border-radius: 2px;
  width: 100%;
  background-color: #fff;
  ${BoxShadow}
  z-index: 3;
`;

export const OptionItem = styled.div`
  ${ButtonEffect}
  ${Body2CSS}
  ${FlexRow}
  ${FlexCenter}
  padding: 0 16px;
  height: ${OptionHeight}px;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: ${OptionsMargin}px;
  }
`;

export const IconArrow = styled(IconArrowDown)<{ $active?: boolean }>`
  color: #000;
  margin-left: 4px;
  flex: none;
  transform: rotate(${({ $active }) => ($active ? -180 : 0)}deg);
  transition: transform 0.2s ease;
  position: relative;
  top: 1px;
`;
