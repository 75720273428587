import useSWR from "swr";

import { useAccessToken } from "store/accessToken";

import { getSummary } from "service/v1/dashboard";
import { TimeUnit } from "utils/date";

export function useSummaryLoader(
  start: string,
  end: string,
  productTypes?: TInventoryType | "",
  requestKey?: string
) {
  const { curCountry } = useAccessToken();
  const { data, mutate, isValidating } = useSWR(
    [
      `${requestKey}${productTypes}summary`,
      start,
      end,
      productTypes,
      curCountry?.code,
    ],
    getSummary,
    { refreshInterval: TimeUnit.Minute * 5, errorRetryCount: 10 }
  );
  return {
    data,
    mutate,
    isValidating,
  };
}
