import styled, { css } from "styled-components";
import { FlexCenter, FlexRow } from "styles/common";
import {
  Secondary,
  Neutral6,
  Neutral5,
  Neutral8,
  Neutral3,
  None,
  Neutral4,
  Neutral,
  Primary,
  Neutral2,
} from "styles/colors";
import { Body2CSS } from "styles/fonts";

interface LinkButtonProps {
  disabled?: boolean;
}

export const LinkButton = styled.div<LinkButtonProps>`
  flex: none;
  ${FlexRow}
  ${FlexCenter}
  ${Body2CSS}
  color: ${({ disabled }) => (disabled ? Neutral6 : Secondary)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin-bottom: 32px;
  user-select: none;
`;

export interface ButtonProps {
  disabled?: boolean;
  pressed?: string;
  active?: boolean;
  defaultBgColor?: string;
  isActivePrimary?: boolean;
  isTable?: boolean;
}

function getBGColor(props: ButtonProps, hover?: boolean) {
  if (props.disabled) return props.defaultBgColor || None;
  if (props.pressed || props.active) return Neutral2;
  if (hover) return Neutral3;
  return props.defaultBgColor || None;
}

function getFontColor(props: ButtonProps, hover?: boolean) {
  if (props.disabled) return Neutral5;
  if (props.active && props.isActivePrimary) return Primary;
  if (hover) return Neutral;
  return Neutral8;
}

export function getBorder(props: ButtonProps) {
  if (props.active && props.isActivePrimary) return `1px solid ${Primary}`;
  return `1px solid ${Neutral4}`;
}

export const ButtonEffect = css<ButtonProps>`
  box-sizing: border-box;
  border-radius: 2px;
  user-select: none;
  outline: none;
  flex: none;
  color: ${(p) => getFontColor(p)};
  background-color: ${(p) => getBGColor(p)};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  transition: all 0.2s ease;

  svg {
    fill: ${(p) => getFontColor(p)};
  }

  &:hover {
    color: ${(p) => getFontColor(p)};
    background-color: ${(p) => getBGColor(p, true)};
  }
`;
