import intl from "langs/intl";

export const NoDataText = intl.formatMessage({
  defaultMessage: "There is no data yet.",
});

export const NewBusinessText = intl.formatMessage({
  defaultMessage: "New Business Unit",
});

export const BusinessUnitText = intl.formatMessage({
  defaultMessage: "Business Unit",
});

export const DescriptionText = intl.formatMessage({
  defaultMessage: "Description",
});

export const LegalEntityText = intl.formatMessage({
  defaultMessage: "Legal Entity",
});

export const NewBusinessUnitText = intl.formatMessage({
  defaultMessage: "New Business Unit",
});

export const DeleteBusinessUnit = intl.formatMessage({
  defaultMessage: "Delete business unit?",
});

export const GotItText = intl.formatMessage({
  defaultMessage: "Got It",
});

export const ViewEmployeeText = intl.formatMessage({
  defaultMessage: "View Employees",
});

export const CantDeleteTitle = intl.formatMessage({
  defaultMessage: "You can’t delete this business unit",
});

export const CantDeleteContent = intl.formatMessage({
  defaultMessage:
    "This business unit contains active or pending employees. You must deactivate them first.",
});

export const DeleteText = intl.formatMessage({
  defaultMessage: "Delete",
});
