import request from "../request";

export const getAccountInventoryType = async () =>
  request<TAccountInventoryTypeResp>({
    path: "/enterprise/v1/dashboard/types",
    method: "GET",
    refreshOnUnauthorized: true,
  });

export const getSpaceInventoryType = async (spaceCode: string) =>
  request<TAccountInventoryTypeResp>({
    path: "/enterprise/v1/spaces/available-inventory-types",
    method: "GET",
    refreshOnUnauthorized: true,
    data: { spaceCode },
  });

export const getContactInventoryList = async (
  spaceId: string,
  inventoryType: TInventoryType
) =>
  request<TContactInventoryListResp>({
    path: `/enterprise/v1/inventories/getOpenInventoryList?spaceId=${spaceId}&inventoryType=${inventoryType}`,
    method: "POST",
    refreshOnUnauthorized: true,
    data: {
      spaceId,
      inventoryType,
    },
  });
