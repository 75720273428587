import { useState, useEffect } from "react";
import { message, Modal } from "antd";
import Notification from "components/Notification";

import GeneralError from "service/request/GeneralError";
import { messageText } from "langs/formatText";

const defaultErr = "request data error";

export default function useError(error: any, defaultMsg = defaultErr) {
  const [errMesg, setErr] = useState("");
  useEffect(() => {
    if (!error) return;
    if (error instanceof GeneralError) {
      let msg = `${error.message || messageText.UnknownErrorText}`.replace(
        /_/gi,
        " "
      );
      if (msg === errMesg) return;
      Modal.error({ content: msg });
      setErr(msg);
      return;
    }
    if (errMesg !== defaultMsg) {
      message.error(defaultMsg);
      setErr(defaultMsg);
    }
  }, [error, errMesg, setErr, defaultMsg]);
}

export function formatError(error: any, defaultMsg = defaultErr) {
  if (error instanceof GeneralError) {
    let msg = `${error.message || messageText.UnknownErrorText}`.replace(
      /_/gi,
      " "
    );
    Modal.error({ content: msg });
    return;
  }
  if (defaultMsg) message.error(defaultMsg);
}

export function notificationErr(
  e: any,
  defaultMsg?: string,
  duration?: number,
  belong?: string
) {
  if (e instanceof GeneralError) {
    let message =
      e.message || e.error || defaultMsg || messageText.UnknownErrorText;
    if (belong) message = `${message} (${belong})`;
    Notification.error({
      message,
      duration,
    });
  }
}
