import React, { useMemo, useState, useCallback } from "react";
import { LoadingOutlined } from "@ant-design/icons";

import Button from "./Button";

import * as S from "./styles.selected";
import { BodyRegular } from "styles/fonts";

import {
  useTrigger,
  useDetectDropdownPosition,
} from "hooks/useDetectOutComponent";

const DefaultFunc = () => null;

interface SelectProps<T> {
  options: T[];
  disabled?: boolean;
  loading?: boolean;
  defaultSelected?: T;
  callback?: (v: T) => void;
  style?: React.CSSProperties;
  optionStyle?: React.CSSProperties;
}

function SelectButton<T extends { text: string; key: string }>({
  defaultSelected,
  options,
  callback,
  disabled,
  loading,
  style,
  optionStyle,
}: SelectProps<T>) {
  const { open, onIn, onOut, onHide, toggleSelections } = useTrigger(false);
  const [selected, setSelected] = useState(defaultSelected);
  const { top, left, width, updateRect, ref } =
    useDetectDropdownPosition<HTMLButtonElement>(
      S.OptionHeight * options.length +
        S.OptionsPadding * 2 +
        S.OptionsMargin * options.length,
      (style?.height as number) || 24
    );
  const onHover = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onIn();
      if (!(e && e.target)) return;
      updateRect((e.target as HTMLButtonElement).getBoundingClientRect());
    },
    [onIn, updateRect]
  );
  const text = useMemo(() => selected?.text, [selected]);
  const onOptionClick = useCallback(
    (v: T) => {
      if (callback) callback(v);
      setSelected(v);
      onHide();
    },
    [callback, onHide]
  );
  return (
    <>
      <Button
        disabled={disabled || loading}
        setRef={ref}
        onClick={!disabled ? toggleSelections : DefaultFunc}
        onMouseEnter={onHover}
        onMouseLeave={onOut}
        active={open}
        style={style}
      >
        <BodyRegular>{text}</BodyRegular>
        {loading && (
          <LoadingOutlined style={{ width: 12, height: 12, marginLeft: 5 }} />
        )}
        {!loading && <S.IconArrow $active={disabled ? false : open} />}
      </Button>
      {open && (
        <S.OptionsWrapper
          style={{ top, left, width }}
          onMouseEnter={onIn}
          onMouseLeave={onOut}
        >
          {options.map((v) => (
            <S.OptionItem
              key={v.key}
              onClick={onOptionClick.bind(null, v)}
              style={optionStyle}
            >
              {v.text}
            </S.OptionItem>
          ))}
        </S.OptionsWrapper>
      )}
    </>
  );
}

export default SelectButton;
