import React, { useCallback, useMemo } from "react";

import * as S from "./styles.table";
import { CaptionSpan, BodyRegular } from "styles/fonts";
import { Neutral8 } from "styles/colors";
import { toObject } from "utils/index";

import SelectButton from "components/Buttons/SelectButton/SelectButton";
import { tableText } from "langs/formatText";

type TPageSizeOption = {
  text: string;
  value: number;
  key: string;
};

const PageSizeOption = [
  { text: tableText.PageSizeText(10), value: 10, key: "10 / page" },
  { text: tableText.PageSizeText(20), value: 20, key: "20 / page" },
  { text: tableText.PageSizeText(50), value: 50, key: "50 / page" },
  { text: tableText.PageSizeText(100), value: 100, key: "100 / page" },
];

const OptionsObj = toObject(PageSizeOption, (v) => `${v.value}`);

interface FooterProps {
  total: number;
  curPage: number;
  pageSize: number;
  onSetPage: (v: number) => void;
  onPageSizeChange?: (size: number) => void;
  style?: React.CSSProperties;
}

export const FooterComponent = ({
  total,
  curPage,
  pageSize,
  onSetPage,
  style,
  onPageSizeChange,
}: FooterProps) => {
  const totalPage = useMemo(
    () => Math.ceil(total / pageSize),
    [pageSize, total]
  );
  const buttonWidth = useMemo(() => {
    if (totalPage >= 10000) return 42;
    if (totalPage >= 1000) return 36;
    if (totalPage >= 100) return 30;
    return 24;
  }, [totalPage]);
  const pageButtonsArr = useMemo(() => {
    let result = [];
    if (totalPage <= 6) {
      for (let i = 1; i <= totalPage; i++) {
        result.push({ value: i, isDot: false, text: i });
      }
    }
    if (totalPage > 6) {
      if (curPage <= 5) {
        for (let i = 1; i <= 5; i++) {
          result.push({ value: i, isDot: false, text: i });
        }
        result.push({
          value: Math.min(totalPage, curPage + 5),
          isDot: true,
          text: "•••",
        });
        result.push({ value: totalPage, isDot: false, text: totalPage });
      }
      if (curPage >= 6 && curPage < totalPage - 5) {
        result.push({ value: 1, isDot: false, text: 1 });
        result.push({
          value: Math.max(1, curPage - 5),
          isDot: true,
          text: "•••",
        });
        for (let i = curPage - 2; i <= curPage + 2; i++) {
          result.push({ value: i, isDot: false, text: i });
        }
        result.push({
          value: Math.min(totalPage, curPage + 5),
          isDot: true,
          text: "•••",
        });
        result.push({ value: totalPage, isDot: false, text: totalPage });
      }
      if (curPage > 5 && curPage >= totalPage - 5) {
        result.push({ value: 1, isDot: false, text: 1 });
        result.push({
          value: Math.max(1, curPage - 5),
          isDot: true,
          text: "•••",
        });
        for (let i = totalPage - 5; i <= totalPage; i++) {
          result.push({ value: i, isDot: false, text: i });
        }
      }
    }
    return result;
  }, [totalPage, curPage]);
  const hasPrePage = useMemo(() => curPage > 1, [curPage]);
  const hasNextPage = useMemo(
    () => curPage * pageSize < total,
    [curPage, pageSize, total]
  );
  const onClickPrePage = useCallback(() => {
    if (!hasPrePage) return;
    onSetPage(curPage - 1);
  }, [curPage, hasPrePage, onSetPage]);
  const onClickNextPage = useCallback(() => {
    if (!hasNextPage) return;
    onSetPage(curPage + 1);
  }, [curPage, hasNextPage, onSetPage]);
  const onClickPage = useCallback(
    (v) => {
      if (curPage !== v) onSetPage(v);
    },
    [curPage, onSetPage]
  );
  const onSizeChange = useCallback(
    (v: TPageSizeOption) => {
      onSetPage(1);
      if (onPageSizeChange) onPageSizeChange(v.value);
    },
    [onPageSizeChange, onSetPage]
  );
  return (
    <S.Footer style={style}>
      <BodyRegular style={{ color: Neutral8, marginRight: 8 }}>
        {tableText.PageTotalText(total)}
      </BodyRegular>
      <S.SwitchPageButton disabled={!hasPrePage} onClick={onClickPrePage}>
        <CaptionSpan>&lt;</CaptionSpan>
      </S.SwitchPageButton>
      {pageButtonsArr.map((v) => (
        <S.SwitchPageButton
          key={`${v.value}${v.text}`}
          onClick={onClickPage.bind(null, v.value)}
          defaultNoBorder
          active={curPage === v.value && !v.isDot}
          style={{ width: buttonWidth, marginLeft: 4 }}
        >
          {v.text}
        </S.SwitchPageButton>
      ))}
      <S.SwitchPageButton
        disabled={!hasNextPage}
        onClick={onClickNextPage}
        style={{ marginRight: 16, marginLeft: 4 }}
      >
        <CaptionSpan>&gt;</CaptionSpan>
      </S.SwitchPageButton>
      <SelectButton
        options={PageSizeOption}
        defaultSelected={OptionsObj[`${pageSize}`]}
        style={{ height: 24, padding: "0 8px" }}
        callback={onSizeChange}
        optionStyle={{ padding: 0 }}
      />
    </S.Footer>
  );
};

export const Footer = React.memo(FooterComponent);
