import React, { useMemo, memo, useCallback, useState } from "react";
import { Link, useRouteMatch, matchPath } from "react-router-dom";
import { useUpdateEffect } from "react-use";

import { LoadingIcon } from "components/Loading";
import SelectLanguage from "components/SelectLanguage";

import { websiteUrl } from "service/request/baseUrl";
import * as S from "./style.PortalLayout";
import { ReactComponent as Logo } from "images/logo-text.svg";
import { CaptionText } from "styles/fonts";
import { PrimaryCream } from "styles/colors";
import { IconHelp } from "styles/icons";
import { useAccessToken } from "store/accessToken";
import { isSubPath } from "utils/url";
import { LeftSiderConfig, SiteNav } from "constants/navigators";
import { Theme } from "constants/theme";
import { SignOutButton } from "./SignOutButton";
import { useBookingCountLoader } from "service/v1/booking";
import {
  useTrigger,
  useDetectDropdownPosition,
} from "hooks/useDetectOutComponent";
import { productText, tableText, formText } from "langs/formatText";

function PortalLayout({
  children,
  contentWidth,
  minContentWidth,
  isViewHeight,
  contentStyle,
  minHeight,
}: {
  children: React.ReactNode;
  contentWidth?: number | string;
  minContentWidth?: number;
  isViewHeight?: boolean;
  contentStyle?: React.CSSProperties;
  minHeight?: number;
}) {
  return (
    <S.Layout>
      <LeftSlider />
      <S.RightContent
        style={{ minHeight, overflowY: minHeight ? "hidden" : "auto" }}
      >
        <Header />
        <S.Content style={contentStyle}>
          {contentWidth ? (
            <S.InnerContent
              style={{
                width: contentWidth,
                minWidth: minContentWidth || "unset",
                overflowX: "hidden",
                overflowY: isViewHeight ? "hidden" : "unset",
              }}
            >
              {children}
            </S.InnerContent>
          ) : (
            children
          )}
        </S.Content>
      </S.RightContent>
    </S.Layout>
  );
}

const LeftSlider = memo(() => {
  const { testAuthSome, testAuthAll, curCountry, accessToken } =
    useAccessToken();
  const match = useRouteMatch();
  // can access menus
  const navList = useMemo(() => {
    let list = LeftSiderConfig.filter((menu) => {
      if (!testAuthSome(menu.needSome || [])) return false;
      if (!testAuthAll(menu.need || [])) return false;
      return true;
    });
    list = list.map((menu) => {
      const res = { ...menu };
      // RS-4634 change for sg invoices
      const isSG = curCountry?.countryCode === "SG";
      if (res.key === SiteNav.INVOICE && isSG) res.title = "Debit Notes";
      // RS-4634 end
      if (res.subItems) {
        res.subItems = res.subItems.filter((sub) => {
          if (!testAuthSome(sub.needSome || [])) return false;
          if (!testAuthAll(sub.need || [])) return false;
          return true;
        });
      }
      return res;
    });
    return list;
  }, [testAuthAll, testAuthSome, curCountry]);
  // selected 规则：match path 优先，match url 次之
  const selectedKeys = useMemo<SiteNav[]>(() => {
    let keys: SiteNav[] = [];
    let find = false;
    navList.forEach((menu) => {
      if (find) return;
      if (!menu.subItems) {
        if (
          (menu.path &&
            matchPath(match.url, { path: menu.path, exact: true })) ||
          (!menu.path && menu.to && isSubPath(match.url, menu.to))
        ) {
          find = true;
          keys = [menu.key];
        }
        return;
      }
      menu.subItems.forEach((sub) => {
        if (find) return;
        if (
          (sub.path && matchPath(match.url, { path: sub.path, exact: true })) ||
          (!sub.path && sub.to && isSubPath(match.url, sub.to))
        ) {
          find = true;
          keys = [menu.key, sub.key];
        }
      });
    });
    return keys;
  }, [match.url, navList]);
  const [openKeys, setOpenKeys] = useState<SiteNav[]>(() => {
    if (!selectedKeys.length) return [];
    return [selectedKeys[0]];
  });
  const showCountry = useMemo(
    () => openKeys.findIndex((v) => v === SiteNav.COUNTRY) > -1,
    [openKeys]
  );
  const closeCountry = useCallback(() => {
    setOpenKeys((v) => v.filter((key) => key !== SiteNav.COUNTRY));
  }, []);
  const { open, onIn, onOut, toggleSelections } = useTrigger(
    false,
    closeCountry
  );
  useUpdateEffect(() => {
    toggleSelections();
  }, [showCountry, toggleSelections]);
  const { top, left, updateRect } = useDetectDropdownPosition();
  const onCountryMouseEnter = useCallback(
    (e) => {
      if (!e?.domEvent) return;
      onIn();
    },
    [onIn]
  );
  const onCountryMouseLeave = useCallback(
    (e) => {
      if (!e?.domEvent) return;
      onOut();
    },
    [onOut]
  );
  const onTitleClick = useCallback(
    // update openKeys
    (e) => {
      if (!e) return;
      if (e.key === SiteNav.COUNTRY && !curCountry?.code) return;
      const index = openKeys.findIndex((v) => v === e.key);
      if (index > -1) {
        const newV = [...openKeys];
        newV.splice(index, 1);
        setOpenKeys(newV);
        return;
      }
      if (e.key === SiteNav.COUNTRY) {
        updateRect(
          (e.domEvent.target as HTMLDivElement).getBoundingClientRect()
        );
      }
      setOpenKeys((v: string[]) => [...v, e.key]);
    },
    [curCountry, openKeys, updateRect]
  );
  const onClickRegionClose = useCallback(() => {
    const index = openKeys.findIndex((v) => v === SiteNav.COUNTRY);
    const newV = [...openKeys];
    newV.splice(index, 1);
    setOpenKeys(newV);
  }, [openKeys]);

  return (
    <S.LeftSider
      theme={Theme}
      className="admin-left-slider"
      width={S.LeftSiderWidth}
    >
      <S.LogoWrapper>
        <S.Logo>
          <Logo title={productText.WorkAnywhereText} />
        </S.Logo>
        <CaptionText style={{ fontSize: 10, color: "#8C8C8C" }}>
          {productText.EnterprisePortalText}
        </CaptionText>
      </S.LogoWrapper>
      <S.LeftWrapper
        theme={Theme}
        mode="inline"
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
      >
        <S.LeftSliderSubMenu
          title={curCountry?.name || <LoadingIcon />}
          onTitleClick={onTitleClick}
          key={SiteNav.COUNTRY}
          onTitleMouseEnter={onCountryMouseEnter}
          onTitleMouseLeave={onCountryMouseLeave}
          hasbottomline="true"
        />
        {navList.map((c) => {
          if (c.subItems) {
            return (
              <S.LeftSliderSubMenu
                title={c.title}
                key={c.key}
                onTitleClick={onTitleClick}
              >
                {c.subItems.map((subC) => {
                  return (
                    <SideMenuItem
                      key={subC.key}
                      to={subC.to}
                      title={subC.title}
                      issub="true"
                    />
                  );
                })}
              </S.LeftSliderSubMenu>
            );
          }
          return <SideMenuItem key={c.key} to={c.to} title={c.title} />;
        })}
      </S.LeftWrapper>
      <S.Blank />
      {!!accessToken?.brandInPortal && (
        <S.BrandLabel>{accessToken.brandInPortal}</S.BrandLabel>
      )}
      <CountryPopup
        open={open}
        style={{ top, left: left }}
        onWrapperEnter={onIn}
        onWrapperLeave={onOut}
        onClose={onClickRegionClose}
      />
    </S.LeftSider>
  );
});

interface CountryPopupProps {
  open: boolean;
  style?: React.CSSProperties;
  onWrapperEnter: () => void;
  onWrapperLeave: () => void;
  onClose: () => void;
}

const CountryPopup = memo(
  ({
    open,
    style,
    onWrapperEnter,
    onWrapperLeave,
    onClose,
  }: CountryPopupProps) => {
    const { curCountry, userCountries, setCurCountry } = useAccessToken();
    const onItemClick = useCallback(
      (cur: IUserCountry) => {
        setCurCountry(cur);
        onClose();
      },
      [onClose, setCurCountry]
    );
    return (
      <S.CountryWrapper
        isShow={open}
        style={style}
        onMouseEnter={onWrapperEnter}
        onMouseLeave={onWrapperLeave}
      >
        <S.CountryTitle isShow={open}>
          {tableText.SelectCityText}
        </S.CountryTitle>
        <S.CountryScrollContainer isShow={open}>
          {userCountries.map((country) => (
            <React.Fragment key={country.countryCode + "wrapper"}>
              <S.OptionItem
                key={country.countryCode}
                isSub={true}
                isShow={open}
              >
                {country.name}
              </S.OptionItem>
              {country.subdivisions.map((region) => (
                <S.OptionItem
                  key={region.code}
                  isShow={open}
                  onClick={onItemClick.bind(null, region)}
                >
                  {region.name}
                  {curCountry?.code === region.code && <S.IconSelected />}
                </S.OptionItem>
              ))}
            </React.Fragment>
          ))}
        </S.CountryScrollContainer>
      </S.CountryWrapper>
    );
  }
);

interface SideMenuItemProps {
  to?: string;
  title: string;
  issub?: string;
}

const SideMenuItem = memo(({ to, title, ...antdProps }: SideMenuItemProps) => {
  const { curCountry } = useAccessToken();
  const { data: countData } = useBookingCountLoader(curCountry?.code);
  const counts = useMemo(() => {
    if (to === `/${SiteNav.BOOKINGS_USAGE}`) {
      return (countData?.ongoing || 0) + (countData?.upcoming || 0);
    }
    return undefined;
  }, [countData, to]);
  return (
    <S.LeftSliderItem {...antdProps}>
      {/* // const Icon = (S as any)[c.icon || "IconBook"]; */}
      {/* <Icon isact={path === c.to ? "true" : ""} /> */}
      {to ? (
        <Link to={to} draggable={false}>
          {title}
          {!!counts && <S.CountBadge>{counts}</S.CountBadge>}
        </Link>
      ) : (
        title
      )}
    </S.LeftSliderItem>
  );
});

const Header = React.memo(() => {
  const jumpToHelp = useCallback(() => {
    window.open(`${websiteUrl()}/help`, "_blank");
  }, []);
  return (
    <S.Header style={{ backgroundColor: PrimaryCream }}>
      <S.HelpWrapper onClick={jumpToHelp}>
        <IconHelp />
        <S.HelpContainer>{formText.HelpText}</S.HelpContainer>
      </S.HelpWrapper>
      <SelectLanguage />
      <S.HeaderSplit />
      <SignOutButton />
    </S.Header>
  );
});

export default PortalLayout;
