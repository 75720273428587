import styled from "styled-components";
import { Neutral8, Primary, Neutral3, Neutral4, None } from "styles/colors";
import { ReactComponent as ArrowUpSVG } from "images/icon-arrow-up.svg";
import { ReactComponent as ArrowDownSVG } from "images/icon-arrow-down.svg";

interface BtnProps {
  isact?: boolean;
  pressed?: string;
}

function getBGColor(p: BtnProps) {
  if (p.pressed) return Neutral4;
  return None;
}

export const SortItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  padding: 0 4px;
  background-color: ${(p: BtnProps) => getBGColor(p)};
  mix-blend-mode: multiply;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${(p: BtnProps) => (p.pressed ? Neutral4 : Neutral3)};
  }
`;

export const SortActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
`;

export const IconSortUp = styled(ArrowUpSVG)`
  stroke: ${(p: { isact?: string }) => (p.isact ? Primary : Neutral8)};
  margin-bottom: 2px;
`;
export const IconSortDOWN = styled(ArrowDownSVG)`
  stroke: ${(p: { isact?: string }) => (p.isact ? Primary : Neutral8)};
`;
