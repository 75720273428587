import { useState, useEffect, useMemo } from "react";

export type ControlProps<T = string | number> = {
  value?: T;
  defaultValue?: T;
  onChange?: (value: T) => void;
};
// 受控 value 辅助
export default function useControl<T = string | number>(
  props: ControlProps<T>
): [T, (v: T) => void] {
  const { value, defaultValue } = props;

  if (value === undefined && defaultValue === undefined) {
    throw new Error(`value 和 defaultValue 至少传一个！`);
  }

  const [val, setVal] = useState<T>(() => {
    return (value === undefined ? defaultValue : value) as T;
  });

  const outValue = useMemo(() => {
    if (value === undefined) return val;
    return value;
  }, [val, value]);

  useEffect(() => {
    if (value === undefined) return;
    setVal(value);
  }, [value]);

  const updateValue = (v: T) => {
    setVal(v);
    props.onChange && props.onChange(v);
  };

  return [outValue, updateValue];
}
