import { messageText } from "langs/formatText";
import { useState, useCallback } from "react";
import useCurrent from "./useCurrent";

export class LoadingError extends Error {
  constructor() {
    super(messageText.LoadingText);
  }
}

const useFetcher = <T>(fetcher: (...args: any[]) => Promise<T>) => {
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useCurrent(isLoading);

  const doFetch = useCallback(
    async (...args: any[]) => {
      if (isLoadingRef.current) {
        throw new LoadingError();
      }
      setIsLoading(true);
      try {
        return await fetcher(...args);
      } finally {
        setIsLoading(false);
      }
    },
    [fetcher, isLoadingRef, setIsLoading]
  );

  return {
    isLoading,
    doFetch,
  };
};

export default useFetcher;
