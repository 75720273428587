import { useLanguage } from "langs/intl";
import { useCallback } from "react";

const baseUrl = (): string => {
  let url = process.env.REACT_APP_API_BASE_URL;
  // 中国域名访问 switchspace.cn
  if (/switchspace.cn/.test(document.domain)) {
    url = url.replace(/switch.site/, "switchspace.cn");
  }
  return url;
};
const switchServiceUrl = () => baseUrl().replace(/\/enterprise$/, "");

const websiteUrl = (): string => {
  let url = process.env.REACT_APP_WEB_SITE_BASE_URL;
  // 中国域名访问 switchspace.cn
  if (/switchspace.cn/.test(document.domain)) {
    url = url.replace(/switch.site/, "switchspace.cn");
  }
  return url;
};

const useLinkToSameLanguage = () => {
  const [lang] = useLanguage();

  const getLink = useCallback(
    (link: string) => {
      const search = link.includes("?")
        ? `&language=${lang}`
        : `?language=${lang}`;
      return link.includes("#")
        ? `${link.split("#")[0]}${search}#${link.split("#")[1]}`
        : `${link}${search}`;
    },
    [lang]
  );

  return getLink;
};
export { switchServiceUrl, websiteUrl, useLinkToSameLanguage };
export default baseUrl;
