import React, { useMemo, useState, useCallback, useEffect } from "react";
import * as S from "./styles.types";

import { InventoryType } from "constants/products";
import { FlexRowWrapper } from "styles/common";

const DefaultOptions: InventoryType[] = [
  InventoryType.BOOTH,
  InventoryType.DESK,
];

export function useTypeSwitch<T>(v: T) {
  const [type, setType] = React.useState<T>(v);
  const onUpdateType = React.useCallback((v) => setType(v), []);
  return {
    type,
    onUpdateType,
  };
}

interface TypeSwitchProps {
  options?: string[];
  names?: { [key: string]: string };
  callback?: (v: string) => void;
  defaultValue: string;
  value?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  height?: number;
  badgeCounts?: { [key: string]: number };
}

const TypeSwitch = React.memo(
  ({
    options,
    callback,
    defaultValue,
    disabled,
    names,
    style,
    buttonStyle,
    height,
    badgeCounts,
    value,
  }: TypeSwitchProps) => {
    const ds = useMemo(() => options || DefaultOptions, [options]);
    const [active, setActive] = useState(defaultValue);
    const onSetCur = useCallback(
      (v) => {
        if (disabled) return;
        if (callback) callback(v);
        setActive(v);
      },
      [callback, disabled]
    );
    const wrapperStyle = useMemo(() => {
      if (style && height) return { height, ...style };
      if (height) return { height };
      return style;
    }, [style, height]);
    const _buttonStyle = useMemo(() => {
      if (buttonStyle && height) return { height, ...buttonStyle };
      if (height) return { height };
      return buttonStyle;
    }, [buttonStyle, height]);
    useEffect(() => {
      if (value) setActive(value);
    }, [value]);
    return (
      <S.Wrapper style={wrapperStyle}>
        {ds.map((v) => {
          const counts = badgeCounts ? badgeCounts[v] : 0;
          return (
            <React.Fragment key={v}>
              <FlexRowWrapper style={{ marginRight: 24 }}>
                <S.Button
                  isCur={active === v}
                  onClick={onSetCur.bind(null, v)}
                  disabled={disabled}
                  style={_buttonStyle}
                >
                  {(!!names && names[v]) || v}
                </S.Button>
                {!!counts && <S.CountBadge>{counts}</S.CountBadge>}
              </FlexRowWrapper>
            </React.Fragment>
          );
        })}
      </S.Wrapper>
    );
  }
);

export default TypeSwitch;
