import styled from "styled-components";
import {
  Neutral8,
  Neutral10,
  Success,
  Error,
  Neutral7,
  Neutral13,
} from "styles/colors";

import { ReactComponent as PlusSVG } from "images/icon-plus.svg";
import { ReactComponent as DeleteSVG } from "images/icon-delete.svg";
import { ReactComponent as UploadSVG } from "images/icon-upload.svg";
import { ReactComponent as UploadFailedSVG } from "images/icon-upload-failed.svg";
import { ReactComponent as TurnLeftSVG } from "images/icon-turn-left.svg";
import { ReactComponent as TurnRightSVG } from "images/icon-turn-right.svg";
import { ReactComponent as ArrowDownSVG } from "images/icon-arrow-down-btn.svg";
import { ReactComponent as ArrowDown2SVG } from "images/icon-arrow-down-2.svg";
import { ReactComponent as MoreSVG } from "images/icon-more.svg";
import { ReactComponent as ArrowUpSVG } from "images/icon-arrow-up.svg";
import { ReactComponent as SearchSVG } from "images/icon-search.svg";
import { ReactComponent as ArchiveSVG } from "images/icon-archive.svg";
import { ReactComponent as CloseSVG } from "images/icon-close.svg";
import { ReactComponent as FileDownloadSVG } from "images/icon-file-download.svg";
import { ReactComponent as TableSVG } from "images/icon-table.svg";
import { ReactComponent as CheckCircleSVG } from "images/icon-check-circle.svg";
import { ReactComponent as CheckSVG } from "images/icon-check.svg";
import { ReactComponent as WarnSVG } from "images/icon-warn.svg";
import { ReactComponent as HelpSVG } from "images/icon-help.svg";
import { ReactComponent as LogoSVG } from "images/logo-text.svg";
import { ReactComponent as IconClearAllSVG } from "images/icon-clear-all.svg";

export const IconPlus = styled(PlusSVG)``;
export const IconDelete = styled(DeleteSVG)`
  fill: ${Neutral8};
  cursor: pointer;
`;
export const IconUpload = styled(UploadSVG)``;
export const IconUploadFailed = styled(UploadFailedSVG)``;
export const IconTurnLeft = styled(TurnLeftSVG)``;
export const IconTurnRight = styled(TurnRightSVG)``;
export const IconArrowDown = styled(ArrowDownSVG)``;
export const IconArrowDown2 = styled(ArrowDown2SVG)`
  fill: #fff;
`;
export const IconArrowUp = styled(ArrowUpSVG)``;
export const IconMore = styled(MoreSVG)`
  fill: #000;
  cursor: pointer;
`;
export const IconSearch = styled(SearchSVG)``;
export const IconArchive = styled(ArchiveSVG)`
  fill: ${Neutral10};
`;
export const IconClose = styled(CloseSVG)`
  fill: ${Neutral7};
`;
export const IconFileDownload = styled(FileDownloadSVG)`
  fill: ${Neutral10};
`;
export const IconTable = styled(TableSVG)`
  fill: ${Neutral8};
  flex: none;
`;
export const IconCheckCircle = styled(CheckCircleSVG)`
  fill: ${Success};
  flex: none;
`;
export const IconError = styled(WarnSVG)`
  fill: ${Error};
  flex: none;
`;

export const IconHelp = styled(HelpSVG)`
  width: 16px;
  height: 16px;
  color: ${Neutral8};
`;
export const LogoText = styled(LogoSVG)`
  fill: ${Neutral7};
`;
export const IconCheck = styled(CheckSVG)`
  fill: #fff;
`;
export const IconClearAll = styled(IconClearAllSVG)`
  color: ${Neutral8};
  &:hover {
    color: ${Neutral13};
  }
`;
