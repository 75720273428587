import styled, { css } from "styled-components";
import { Neutral4, Neutral5, PrimaryCream } from "styles/colors";

export const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = css`
  display: flex;
  flex-direction: row;
`;

export const FlexCenter = css`
  align-items: center;
  justify-content: center;
`;

export const FlexBlank = styled.div`
  flex: 1;
`;

interface FlexRowWrapperProps {
  align?: string;
  horizontal?: string;
}

export const FlexRowWrapper = styled.div<FlexRowWrapperProps>`
  ${FlexRow}
  justify-content: ${({ align }) => align || "flex-start"};
  align-items: ${({ horizontal }) => horizontal || "flex-start"};
`;

export const AbsoluteFill = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const AlignConfig = {
  center: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  right: {
    alignItems: "center",
    justifyContent: "flex-end",
    textAlign: "right",
  },
  left: {
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "left",
  },
};

export const ToolBar = styled.div<{ isSticky?: boolean; align?: string }>`
  display: flex;
  flex-direction: row;
  align-items: ${({ align }) => align || "center"};
  position: ${({ isSticky }) => (isSticky ? "sticky" : "relative")};
  top: ${({ isSticky }) => (isSticky ? "0" : "unset")};
  flex: none;
  padding: 8px 32px 0 32px;
  box-sizing: border-box;
  background-color: ${PrimaryCream};
`;

export const Ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FlexContent = styled.div`
  ${FlexColumn}
  ${FlexCenter}
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Row = styled.div`
  ${FlexRow}
`;

export const Triangle = css`
  width: 0;
  height: 0;
  border-width: 0 4px 5px 4px;
  border-top: 0 solid transparent;
  border-style: solid;
  border-color: transparent transparent #000;
`;

export const Shadow =
  "0 2px 3px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.05), 6px 10px 10px rgba(0, 0, 0, 0.03)";

export const BoxShadow = css`
  border: 1px solid ${Neutral4};
  box-shadow: ${Shadow};
`;

export const DividerLine = styled.div`
  height: 1px;
  margin: 4px 0;
  background-color: ${Neutral5};
`;
