import { useState, useCallback } from "react";

export default function useStateValue<T>(
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState(defaultValue);
  const onSetValue: React.Dispatch<React.SetStateAction<T>> = useCallback(
    (v) => setValue(v),
    []
  );
  return [value, onSetValue];
}
