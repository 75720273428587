import { useState, useEffect, useCallback } from "react";

export default function useListStore(requestKey: string) {
  const [curPage, setPage] = useState(1);
  const [counts, setCounts] = useState(0);
  const onSetPage = useCallback((v: number) => setPage(v), []);
  useEffect(() => {
    setPage(1);
    setCounts(0);
  }, [requestKey]);
  return {
    onSetPage,
    curPage,
    counts,
    setCounts,
  };
}
